import React from 'react';
import { fromJS } from 'immutable';
import styled from 'styled-components';
import { FeedGroup } from './Jobs/Feed';
import { colors } from './theme';
import { PrimaryButton } from './Button';

export const EMPTY_OBJECT = fromJS({});
export const EMPTY_ROW = {};
export const EMPTY_FEED_ELEMENT = fromJS({
  date: '',
  count: 1,
  actions: [
    {
      task: {
        _id: 0,
      },
    },
  ],
});
export const EMPTY_JOB_FEED = fromJS([EMPTY_FEED_ELEMENT, EMPTY_FEED_ELEMENT]);

export const renderCell = fn => ({ rowData, ...rest }) => {
  if (rowData === EMPTY_ROW) return <span />;

  if (!fn) return <>{rest.cellData}</>;

  return fn({ rowData, ...rest });
};

export const TextPlaceholder = styled.span`
  ${props => `
    display: inline-block;
    background-color: ${colors.gray500};
    border-radius: 5px;
    height: 12px;
    width: ${props.width || '100%'};
    margin-bottom: 12px;
  `}
`;

const ActionPlaceholder = styled.li`
  display: flex;
  flex-direction: row;
  height: 80px;
  background-color: #f9f9fa;
`;

const SectionPlaceholder = styled.div`
  ${props => `
    flex: ${props.grow || 1} 1 auto;
  `}
`;

const Dot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${colors.gray500};
  display: inline-block;
`;

export function JobFeedPlaceholder() {
  const Action = () => (
    <ActionPlaceholder>
      <SectionPlaceholder grow="0" className="d-flex justify-content-end pl-3">
        <Dot />
      </SectionPlaceholder>
      <SectionPlaceholder grow="15" className="d-flex flex-column pl-3">
        <TextPlaceholder width="45%" />
        <div className="d-flex flex-row">
          <TextPlaceholder width="19%" />
          <TextPlaceholder width="25%" className="ml-2" />
          <TextPlaceholder width="19%" className="ml-2" />
        </div>
      </SectionPlaceholder>
      <SectionPlaceholder grow="3">
        <TextPlaceholder width="100%" />
      </SectionPlaceholder>
    </ActionPlaceholder>
  );
  return (
    <li className="d-flex flex-column">
      <div>
        <TextPlaceholder width="123px" className="mr-1" />
        <TextPlaceholder width="65px" />
      </div>
      <FeedGroup>
        {[...Array(3)].map((e, i) => (
          <Action key={i} />
        ))}
      </FeedGroup>
    </li>
  );
}

export const PlaceHolder = styled.span`
  height: 12px;
  background-color: #e0e2e7;
  border-radius: 5px;
  display: inline-block;
`;

export const JobHeaderItemLoading = () => (
  <div className="d-flex flex-column" style={{ marginRight: '90px' }}>
    <PlaceHolder className="mb-2" style={{ width: 87 }} />
    <PlaceHolder className="" style={{ width: 99 }} />
  </div>
);

export const JobHeaderLoading = isTrade => (
  <div className="mb-4 d-flex">
    <div>
      <div>
        <PlaceHolder className="mx-4 mt-4" style={{ width: 303 }} />
        <PlaceHolder className="mx-4 mt-4" style={{ width: 150 }} />
      </div>
      <div className="d-flex mx-4 mt-4">
        {Array.from({ length: 4 }, (_, index) => (
          <JobHeaderItemLoading key={index} />
        ))}
      </div>
    </div>
    {!isTrade && (
      <PrimaryButton variant="primary" className="rounded-pill ml-auto mr-4">
        Release to Construction
      </PrimaryButton>
    )}
  </div>
);

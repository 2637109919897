import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { TradeTrax, Logo } from '../Logo';
import {
  iPhoneX,
  iPhoneX1,
  androidX1,
  iPhoneX1Installer,
  androidX1Installer,
  downloadIOS,
  downloadAndroid,
} from './images';
import { PrimaryButtonLink } from '../Button';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownloadImgWrapper = styled.a`
  padding: 0.25rem 0.5rem;
  background-color: black;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  color: white;

  .device-image-separator {
    width: 85%;
    border: 1px solid #6c6e7a;
  }
`;

const desktopDevice = { isIOS: false, isAndroid: false };

export const LandingPage = ({ children, device = desktopDevice, isInstaller = false }) => {
  const { isIOS, isAndroid } = device;
  const isMobile = isIOS || isAndroid;
  const deviceImage = getDeviceImage(isIOS, isAndroid, isInstaller);
  return (
    <Wrapper className="bg-tile">
      <div className="d-flex flex-row justify-content-center">
        <img src={iPhoneX} className="d-none d-lg-block" width={250} />
        <ContentWrapper
          className={cn(
            'd-flex flex-column ml-lg-3 px0 px-lg-4 py-lg-5 mt-lg-4 justify-content-center justify-content-lg-start align-items-center align-items-lg-start text-center text-lg-left',
            { 'w-50': !isMobile, 'w-75': isMobile }
          )}
        >
          <div className="d-flex flex-row justify-content-center justify-content-lg-start align-items-center w-75 px-4 px-lg-0 mb-lg-3">
            <Logo className="mr-3" />
            <TradeTrax />
          </div>
          <h5 className="font-weight-bold mt-3 mb-3">Welcome to TradeTrax</h5>
          <h2 className="font-weight-bold d-none d-lg-block">Get the mobile app</h2>
          <p>{`Download the app for your mobile device on ${
            isAndroid ? 'Google Play' : isIOS ? 'Apple App Store' : 'Google Play or Apple App Store'
          }.`}</p>
          <img src={deviceImage} className="d-block d-lg-none mt-5 w-75" />
          <span className="d-inline d-lg-none mb-2 device-image-separator" />
          <div className="d-flex flex-row mt-3">
            {' '}
            <DownloadImgWrapper
              className="d-none d-lg-flex mr-4"
              href="https://apps.apple.com/us/app/tradetrax/id1536712594"
            >
              <img src={downloadIOS} width={160} />
            </DownloadImgWrapper>
            <DownloadImgWrapper
              className="d-none d-lg-flex"
              href="https://play.google.com/store/apps/details?id=com.tradetrax.app"
            >
              <img src={downloadAndroid} width={160} />
            </DownloadImgWrapper>
            <PrimaryButtonLink
              className="btn btn-primary d-lg-none"
              href={
                isIOS
                  ? 'https://apps.apple.com/us/app/tradetrax/id1536712594'
                  : isAndroid
                  ? 'https://play.google.com/store/apps/details?id=com.tradetrax.app'
                  : ''
              }
            >
              Get the mobile app
            </PrimaryButtonLink>
          </div>
          {children}
        </ContentWrapper>
      </div>
    </Wrapper>
  );
};

const getDeviceImage = (isIOS, isAndroid, isInstaller) => {
  if (isInstaller) {
    return isIOS ? iPhoneX1Installer : isAndroid ? androidX1Installer : '';
  }
  return isIOS ? iPhoneX1 : isAndroid ? androidX1 : '';
};

import { fromJS } from 'immutable';
import { filterCommonActions } from './Filter.common.actions';
import { OpenClosedAdapter } from '../FilterSets/Adapters/OpenCompleted.adapter';
import { UpcomingAdapter } from '../FilterSets/Adapters/Upcoming.adapter';
import { ToDoAdapter } from '../FilterSets/Adapters/ToDo.adapter';
import { JobDetailsAdapter } from '../FilterSets/Adapters/JobDetails.adapter';
import { UROverdueAdapter } from '../FilterSets/Adapters/UROverdue.adapter';
import { markAsSideEffect } from '../../useController';
import { EMPTY_FILTER } from './Filter.empty';
import { FILTER_NAME } from './filter.name';

export const ADAPTERS = {
  JOB: JobDetailsAdapter,
  OPEN_TASKS: OpenClosedAdapter,
  TO_DO: ToDoAdapter,
  UPCOMING: UpcomingAdapter,
  UR_OVERDUE: UROverdueAdapter,
};

export const filtersInitalSate = fromJS(
  Object.entries(FILTER_NAME).reduce(
    (state, [key, filterName]) => {
      state[filterName] = EMPTY_FILTER[key];
      return state;
    },
    {
      taskList: [], // filterSetType `taskList`
      jobSchedule: [], // filterSetType `jobSchedule`
    }
  )
);

const makeActions = (key, filterName) => filterCommonActions(filterName, EMPTY_FILTER[key], ADAPTERS[key]);

export const filterActions = Object.entries(FILTER_NAME).reduce(
  (actions, [key, filterName]) => {
    actions[filterName] = makeActions(key, filterName);
    return actions;
  },
  { setFiltersService }
);

markAsSideEffect(setFiltersService);
function setFiltersService(service) {
  Object.values(FILTER_NAME).forEach(filterName => {
    this.controller[filterName].setFiltersService(service);
  });
}

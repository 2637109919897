import React, { useState, useEffect } from 'react';
import { history } from './history';
import queryString from 'query-string';

export const getSearch = () => {
  const { location } = history;
  const qs = queryString.parse(location.search);
  const search = qs.q || '';
  return search.trim().toLowerCase();
};

export const SearchControl = () => {
  const { location } = history;
  const qs = queryString.parse(location.search);
  const { q = '' } = qs;
  const [search, setSearch] = useState(q); //?q=search text
  const onChange = e => setSearch(e.target.value);
  const onSubmit = e => {
    e.preventDefault();
    qs.q = search;
    history.push(`${location.pathname}?${queryString.stringify(qs)}`);
  };

  useEffect(() => setSearch(q), [q]);

  return (
    <form
      onSubmit={onSubmit}
      className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
    >
      <div className="input-group">
        <input
          type="text"
          className="form-control bg-light border-0 small"
          placeholder="Search for..."
          aria-label="Search"
          aria-describedby="basic-addon2"
          value={search}
          onChange={onChange}
        />
        <div className="input-group-append">
          <button className="btn btn-primary" type="submit">
            <i className="fas fa-search fa-sm"></i>
          </button>
        </div>
      </div>
    </form>
  );
};

import React from 'react';

export class UpcomingAdapter {
  filterType = 'task';
  #filtersService = null;

  constructor(filtersService) {
    this.#filtersService = filtersService;
  }

  set filtersService(service) {
    this.#filtersService = service;
  }

  get filtersService() {
    return this.#filtersService;
  }

  readFilters() {
    return this.filtersService.readJobFilters({}, { query: { type: 'taskList' } });
  }

  createFilter(body) {
    return this.filtersService.createJobFilter({ type: 'taskList', ...body });
  }

  updateFilter(body, options) {
    return this.filtersService.updateJobFilter(body, options, { query: { type: 'taskList' } });
  }

  batchUpdateFilters(body) {
    return this.filtersService.batchUpdateJobFilters(body, { query: { type: 'taskList' } });
  }

  shareFilter(body, options) {
    return this.filtersService.shareJobFilter(body, options, { query: { type: 'taskList' } });
  }

  unshareFilter(body, options) {
    return this.filtersService.unshareJobFilter(body, options, { query: { type: 'taskList' } });
  }

  readFilterUsersAndRoles(body, options) {
    return this.filtersService.readJobFilterUsersAndRoles(body, options, { query: { type: 'taskList' } });
  }

  listRoles(body, options) {
    return this.filtersService.listRoles(body, options, { query: { type: 'taskList' } });
  }
}

export const useUpcomingAdapter = filtersService =>
  React.useMemo(() => new UpcomingAdapter(filtersService), [filtersService]);

import { Map } from 'immutable';
import { markAsSideEffect, markAsSync } from '../useController';

markAsSideEffect(open);
export function open(Component, props = {}, context) {
  return new Promise((resolve, reject) => {
    this.controller.dispatch([
      state =>
        state.push(
          Map({
            show: true,
            Component,
            resolve,
            reject,
            props,
          })
        ),
    ]);
  });
}

markAsSync(reset, accept);
export function reset(state, { isCancel = true, isAccept = false, error = null, ...rest } = {}) {
  const current = state.peek();
  const resolve = current.get('resolve');
  const reject = current.get('reject');

  error ? reject(error) : resolve({ isCancel, isAccept, ...rest });
  return state.pop();
}

export const close = reset;
export const cancel = reset;

export function accept(state, params) {
  return reset(state, { isCancel: false, isAccept: true, ...params });
}

import queryString from 'query-string';

export const serviceUrl = name => {
  const { hostname, protocol } = location;

  if (hostname === 'localhost') {
    return `http://${name}.local.tradetrax.com`;
  }

  const result = hostname.replace(
    /^(dev-)?([^\.]+)((?:.*)?.tradetrax\.com)$/,
    (m, prefix = '', sub, host) => `${protocol}//${prefix}${name}${host}`
  );

  // console.log(`service url (${name}) => ${result}`);
  return result;
};

export const redirectToLogin = (addContinueURL = true) => {
  const continueURL = window.location;
  const url = `${serviceUrl('my')}/login`;
  const redirect = queryString.stringifyUrl({ url, query: { continueURL } });
  global.location.replace(addContinueURL ? redirect : url);
};

export const redirectToHomeWebsite = () => {
  const { hostname, protocol } = location;
  const regexp = /^([^\.]+.)((?:.*.)?tradetrax\.com)$/;
  const url = hostname.replace(regexp, (m, subdomain, domain) => `${protocol}//${domain}`);
  global.location.replace(url);
};

export const getPublicWebsite = () => {
  const { hostname, protocol } = location;
  const regexp = /^([^\.]+\.)((?:.*)?tradetrax\.com)$/;
  const result = hostname.replace(regexp, (m, subdomain, domain) => `${protocol}//${domain}`);

  return result;
};

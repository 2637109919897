import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { colors } from '../../theme';
import { validateIfFilterNameExist } from './NewFilterSetNameModal';
import { useLocation } from 'react-router-dom';

export const FilterNameEditor = props => {
  const location = useLocation();
  const currentPath = location.pathname;
  const tasksListRoutes = ['/tasks', '/upcoming', '/to-do', '/task/open'];
  const isTasksListRoute = tasksListRoutes.some(route => currentPath.includes(route));

  const inputRef = React.useRef();
  const cancelRef = React.useRef();
  const acceptRef = React.useRef();
  const { setIsEditing, value, field, type, schema, update, filterSets, dragHandleProps } = props;
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [field]: value,
    },
  });
  const isValid = !errors[field] && formState.isValid && formState.isDirty;

  const onSubmit = form => {
    const isValid =
      inputRef.current.validity.valid &&
      validateIfFilterNameExist(form[field], setError, fromJS(filterSets), field, isTasksListRoute);
    if (!isValid) return;

    update(field, form[field])
      .then(() => {
        setIsEditing(false);
      })
      .catch(err => {
        const message = err.detail || err.message;
        setError(field, { message });
      });
  };

  const onCancel = e => {
    e.preventDefault();
    setIsEditing(false);
  };

  React.useEffect(() => {
    const input = inputRef.current;
    const accept = acceptRef.current;
    const cancel = cancelRef.current;
    const onKeyPress = e => {
      if (e.key === 'Escape') {
        setIsEditing(false);
      }
    };
    const onMouseDown = e => {
      const { path } = e;
      const inPath = element => path.indexOf(element) >= 0;

      if (path && !inPath(input) && !inPath(accept) && !inPath(cancel)) {
        e.preventDefault();
        setIsEditing(false);
      }
    };

    input.focus();
    input.addEventListener('keydown', onKeyPress);
    global.document.body.addEventListener('mousedown', onMouseDown);

    return () => {
      input.removeEventListener('keydown', onKeyPress);
      global.document.body.removeEventListener('mousedown', onMouseDown);
    };
  }, [inputRef, setIsEditing]);

  return (
    <Form
      noValidate
      validated={formState.isValid && formState.submitCount > 0}
      onSubmit={handleSubmit(onSubmit)}
      className="d-inline-block w-100"
    >
      <Form.Row className="align-items-center">
        <span className="item-drag" {...dragHandleProps}>
          <FontAwesomeIcon icon="grip-lines-vertical" className="mr-2" />
        </span>
        <span className="item-number">{props.index + 1}</span>
        <Form.Control
          ref={e => {
            register(e);
            inputRef.current = e;
          }}
          type={type || 'text'}
          name={field}
          isInvalid={!!errors[field]}
          isValid={isValid}
          autoComplete="off"
          size="sm"
          className="ml-2"
          style={{ flexGrow: 1, width: 'auto' }}
          onBlur={e => {
            if (e.relatedTarget !== acceptRef.current) onCancel(e);
          }}
        />
        <IconButton disabled={!formState.isValid} type="submit" ref={acceptRef} className="ml-2">
          <span>
            <FontAwesomeIcon icon="check" />
          </span>
        </IconButton>
        <IconButton onClick={onCancel} ref={cancelRef} className="mx-2">
          <span>
            <FontAwesomeIcon icon="xmark" />
          </span>
        </IconButton>
        <div className="d-block w-100 ml-5">
          <Form.Control.Feedback className={classNames({ 'd-block': isValid })} type="valid">
            <FontAwesomeIcon icon="circle-check" className="mx-2" />
            {'Filter Set is valid'}
          </Form.Control.Feedback>
          <Form.Control.Feedback className={classNames({ 'd-block': !!errors[field] })} type="invalid">
            <FontAwesomeIcon icon="circle-exclamation" className="mr-1 ml-2" />
            {errors[field]?.message}
          </Form.Control.Feedback>
        </div>
      </Form.Row>
    </Form>
  );
};

const IconButton = styled.button`
  border-style: none;
  background-color: transparent;
  color: ${colors.gray400};
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 4px;

  &:disabled {
    color: ${colors.blue_30};
  }

  > span {
    width: 25px;
    height: 25px;
    display: inline-block;
    background-color: ${colors.gray};
    border-radius: 30px;
    border: solid 1px ${colors.white};
    box-shadow: 0px 1px 2px ${colors.gray700};
  }
`;

import React from 'react';
import { mongoToHistory, isUserInactive } from '../utils';
import { UserNameLabel } from '../Tags';
import { getAssigneeBadgeClassName, getUserDomain } from '../utils';

const eventsMap = {
  'check-in': 'Check-In',
  'check-out': 'Check-Out',
};

export const useGeoLocationProps = (item, appContext) => {
  const { account, appState, modal } = appContext;
  const user = appState.get('user');
  const { isBuilder } = getUserDomain(user);
  const assigneeAccountId = item.getIn(['assigneeAccount', 'companyId']);
  const accountId = account.get('_id');
  const isAccountAssigned = accountId === assigneeAccountId;
  const accountUsers = account.get('assignees');
  const isLocationEnabled = isBuilder
    ? account
        .get('tradesLocationSettings')
        .find(setting => setting.get('subAccountId') === assigneeAccountId)
        ?.get('locationTrackingEnabled')
    : isAccountAssigned && account.get('locationTrackingEnabled');

  return React.useMemo(() => {
    const coordinates = item.getIn(['location', 'locationGeoJSON', 'coordinates']);
    if (!coordinates) return null;
    if (!isLocationEnabled) return null;

    const userId = user.get('_id');
    const assigneeId = item.get('createdByUserId');
    const isInactive = isUserInactive(item.get('createdById'), accountUsers);
    const [lng, lat] = coordinates.toJS();
    const geoLocation = { lat, lng };
    const title = item.getIn(['task', 'name']);
    const subTitle = (
      <>
        <span className="text-secondary">{eventsMap[item.get('action')]} by </span>
        <UserNameLabel
          className={`not-permission not-badge ${getAssigneeBadgeClassName(
            assigneeId,
            accountId,
            userId,
            assigneeAccountId
          )}`}
          isInactive={isInactive}
        >
          {isAccountAssigned && item.get('createdByUserName')}
          {!isAccountAssigned && item.getIn(['assigneeAccount', 'companyName'])}
        </UserNameLabel>
        <span className="trx-time text-secondary"> {mongoToHistory(item.get('updatedAt'))}</span>
      </>
    );

    return { geoLocation, modal, title, subTitle };
  }, [item, user, account, accountUsers, modal]);
};

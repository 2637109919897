import React from 'react';
import { Modal } from 'react-bootstrap';
import { Logo, TradeTrax, colors, PrimaryButton } from '@tradetrax/web-common';

export const EulaModal = ({ onHide, close }) => {
  const onClose = onHide || close;
  return (
    <Modal show={true} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex flex-row align-items-center mb-4">
            <Logo className="mr-3" leftColor={colors.black} width={29} height={29} />
            <TradeTrax width="100px" hight="30px" color={colors.black} />
          </div>
          END-USER LICENSE AGREEMENT
          <p className="mb-0 font-size-16 font-weight-normal">Last modified: August 2023</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable="true" style={{ maxHeight: 'calc(100vh - 310px)', overflowY: 'auto' }}>
        <p className="mb-0">
          This End User License Agreement (“<b>EULA</b>” or <b>“Agreement</b>”) is a binding agreement between you (“End{' '}
          <b>User</b>”, <b>“you</b>”, or <b>“your</b>”) and TradeTrax, Inc. (<b>“TradeTrax</b>,” <b>“we</b>” or{' '}
          <b>“us</b>”). This EULA governs your access and use of the TradeTrax App (<b>“TradeTrax</b>”).
        </p>
        <p className="mt-0">
          TradeTrax provides a software platform for home builders and trades to manage the home building process.
        </p>
        <p>
          TRADETRAX IS WILLING TO PROVIDE ACCESS AND USE OF TRADETRAX TO YOU ONLY ON THE CONDITION THAT YOU ACCEPT ALL
          THE TERMS IN THIS EULA. BY ACCESSING AND/OR USING TRADETRAX, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
          UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C)
          ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT MEET ANY OF THE
          FOREGOING REQUIREMENTS AND/OR DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE TRADETRAX.
        </p>
        <ol className="pl-3">
          <li>
            <u>Grant.</u> Subject to the terms of this EULA, TradeTrax grants you a limited, revocable, non- exclusive,
            and non-transferable right to access and use TradeTrax strictly in accordance with the then-current
            TradeTrax user documentation, as may be modified from time to time on any web browser that you own or
            control and as permitted by the usage rules set forth in the Apple App Store and Google Play Store (“Usage
            Rules”).{' '}
          </li>
          <li>
            <u>User Registration.</u> In order to access and use certain areas or features of TradeTrax, you may need to
            agree to additional terms and conditions, provide certain information; and/or answer certain questions. Each
            registration is for a single user only. You agree to (a) provide accurate, current and complete information,
            (b) maintain and promptly update, as necessary, your information, (c) be responsible for the acts or
            omissions of any third party who has authority to access or use TradeTrax on your behalf, and (d)
            immediately notify us if you discover or otherwise suspect any security breaches related to TradeTrax. If
            you provide information that is untrue, inaccurate, not current or incomplete, we may suspend and/or
            terminate your current or future use of TradeTrax. By providing information and/or answering questions, you
            also consent to receive electronic communications from TradeTrax (e.g., via email or by posting notices to
            TradeTrax). You should maintain copies of electronic communications from us by printing a paper copy or
            saving an electronic copy.{' '}
          </li>
          <li>
            <u>Restrictions.</u> End User shall not:
            <ol type="a">
              <li>Use TradeTrax in any way that fails to comply with applicable law or this EULA;</li>
              <li>
                Impersonate another person, misrepresent your affiliation with another person or entity, or make any
                representation to any third party under false pretenses;
              </li>
              <li>
                Copy, modify, translate, adapt, or otherwise create derivative works or improvements of TradeTrax;
              </li>
              <li>
                Reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the
                source code of TradeTrax or any part thereof;
              </li>
              <li>
                Remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other
                intellectual property or proprietary rights notices from TradeTrax, including any copy thereof;
              </li>
              <li>
                Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available
                TradeTrax, or any features or functionality of TradeTrax, to any third party for any reason;
              </li>
              <li>
                Use TradeTrax to stalk, threaten, or otherwise violate the rights of others, including without
                limitation others’ privacy rights or rights of publicity;
              </li>
              <li>
                Remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection,
                rights management, or security features in or protecting TradeTrax;
              </li>
              <li>
                Use TradeTrax in any situation where such use could lead to death or serious bodily injury of any
                person, or to severe physical, environmental, or financial damage;
              </li>
              <li>use TradeTrax on any device that you do not own or control; or</li>
              <li>distribute or make TradeTrax available over a network in violation of the Usage Rules.</li>
            </ol>
          </li>
          <li>
            <u>Intellectual Property.</u> You acknowledge and agree that the right to access and use TradeTrax is
            provided as a services offering, and not sold, to you. You do not acquire any ownership interest in
            TradeTrax under this EULA, or any other rights thereto other than to use TradeTrax in accordance with the
            rights granted, and subject to all terms, conditions, and restrictions, under this EULA. TradeTrax and its
            licensors, vendors, and service providers reserve and retain their entire right, title, and interest in and
            to TradeTrax, including all copyrights, trademarks, and all other intellectual property rights therein or
            relating thereto. TradeTrax and its entire contents, features, and functionalities, including without
            limitation, all information, software, text, displays, images, video, and audio, and the design, selection
            and arrangement thereof, are owned by TradeTrax and its licensors. TradeTrax and its content are
            copyrighted, and any unauthorized use of TradeTrax may violate copyright, trademark, and other laws, in
            addition to constituting a material breach of this EULA. There are several proprietary logos, service marks,
            trademarks, slogans, and product designations found on TradeTrax (collectively, “<b>Marks</b>”). By making
            these Marks available on TradeTrax, TradeTrax is not granting you any rights to use the Marks in any
            fashion. Access to and use of TradeTrax does not confer upon you any rights under any of TradeTrax ’s or any
            third party’s intellectual property rights, including, without limitation, to the Marks. TradeTrax ’s Marks
            may be used publicly only with prior written permission from TradeTrax .{' '}
          </li>
          <li>
            <u>Collection and Use of Your Information.</u> You acknowledge that when you access or use TradeTrax,
            TradeTrax may use various means to collect information about you and your use of TradeTrax. You also may be
            required to provide certain information about yourself as a condition to accessing or using TradeTrax or
            certain of its features or functionality. All information we collect through or in connection with this
            TradeTrax is subject to our Privacy Policy, available at{' '}
            <a href="https://tradetrax.com/privacy-policy/" target="_blank">
              https://tradetrax.com/privacy-policy/
            </a>
            . By using and providing information to or through this TradeTrax, you consent to all actions taken by us
            with respect to your information in compliance with the Privacy Policy.{' '}
          </li>
          <li>
            <u>Consent to Use Data.</u> You expressly acknowledge and agree that TradeTrax, including, without
            limitation, the content and services provided in connection therewith, are provided for your use on behalf
            of TradeTrax. You hereby agree that TradeTrax may collect and use technical information gathered in
            connection with your use of TradeTrax and any support services that may be provided to you, if any, to
            improve TradeTrax ’s products, including TradeTrax, or to provide customized services or technologies to
            you. You hereby agree that all general, anonymized or otherwise aggregated information from your use of
            TradeTrax are and will remain the property of TradeTrax and that TradeTrax may use and disclose such
            anonymized or otherwise aggregated information in a manner permitted under applicable law.{' '}
          </li>
          <li>
            <u>User Content.</u> You are solely responsible and liable for all data, information and other materials
            (“User Content”) that you submit, upload, generate, create, post, e-mail or otherwise transmit (“Transmit”)
            in connection with TradeTrax. You hereby assign, transfer, and otherwise convey to the home builder or
            trades which your TradeTrax account is currently associated, irrevocably and in perpetuity, throughout the
            universe, all of your right, title, and interest, if any, in and to all such User Content, including all
            copyright and other intellectual property rights, and irrevocably waive any and all claims that you may now
            or hereafter have in any jurisdiction to so-called “moral rights” or rights of droit moral in and to such
            User Content. In addition, we have no control over, and shall have no liability for, any damages resulting
            from the use (including without limitation, republication) or misuse by any third party of information made
            public through TradeTrax. IF YOU CHOOSE TO SUBMIT TO US, OR OTHERWISE MAKE ANY USER CONTENT PUBLICLY
            AVAILABLE, YOU DO SO AT YOUR OWN RISK AND WE SHALL HAVE NO LIABILITY THEREFOR. You agree that you will not,
            and will not permit anyone else to, directly or indirectly: (a) Transmit any User Content that is unlawful,
            harmful, threatening, abusive, hateful, obscene, harassing, tortious, defamatory, libelous, slanderous,
            pornographic, profane, vulgar, offensive, lewd, invasive of another’s privacy or racially, ethnically or
            otherwise objectionable; (b) Transmit any User Content: (i) that you do not have the right to Transmit,
            under any law or contractual or fiduciary relationships, including, without limitation, any inside
            information or proprietary or confidential information; (ii) that infringes any patent, copyright, trademark
            or other intellectual property right or misappropriates any trade secret or right of privacy of any
            third-party; (iii) that constitutes unsolicited or unauthorized advertising or promotional materials,
            “spam,” “chain letters,” or pyramid schemes; or (iv) that contains any software routine, code, instruction
            or virus that is designed to disable, delete, modify, damage or erase software, hardware or data; or (c)
            forge headers or otherwise manipulate identifiers in order to disguise any User Content Transmitted through
            TradeTrax. Although we have no obligation to screen, edit or monitor User Content, we reserve the right, and
            have absolute discretion, to remove, screen or edit User Content posted or stored on TradeTrax at any time
            and for any reason, and you are solely responsible for creating backup copies of and replacing any User
            Content you post or store on TradeTrax at your sole cost and expense.{' '}
          </li>
          <li>
            <u>Feedback.</u> Separate and apart from User Content, you can submit questions, comments, suggestions,
            ideas, original or creative materials or other information about TradeTrax, or TradeTrax (collectively, “
            <b>Feedback</b>”). Feedback shall become the sole property of TradeTrax. TradeTrax shall own exclusive
            rights, including, without limitation, all intellectual property rights, in and to Feedback and shall be
            entitled to the unrestricted use and dissemination of Feedback for any purpose, commercial or otherwise,
            without acknowledgment or compensation to you.{' '}
          </li>
          <li>
            <u> Requirements.</u> TradeTrax is provided for access and use only by persons who are of legal age to enter
            into a binding agreement in their jurisdiction of residence. If you are located outside of the United
            States, you acknowledge that you may not be able to access all of TradeTrax, and that access thereto may not
            be legal by certain persons or in certain jurisdictions. If you access TradeTrax from outside the United
            States, you are responsible for compliance with any laws applicable to you and/or your use of TradeTrax.{' '}
          </li>
          <li>
            <u> Updates.</u> TradeTrax may from time to time in its sole discretion develop and provide TradeTrax
            updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features
            (collectively, including related documentation, “Updates”). Updates may also modify or delete in their
            entirety certain features and functionality. You agree that TradeTrax has no obligation to provide any
            Updates or to continue to provide or enable any particular features, functionality, or accessibility of
            TradeTrax. If you wish to use TradeTrax after TradeTrax releases one or more Updates, you agree that all
            Updates will be deemed part of TradeTrax, and your continued access and use of TradeTrax indicates your
            express consent to all terms and conditions of this EULA.{' '}
          </li>
          <li>
            <u> Third-Party Materials and Products.</u> TradeTrax may display, include, or make available third-party
            content (including data, information, apps, and other products, services, and/or materials) or provide links
            to third-party websites or services (“<b>Third-Party Materials</b>”). You acknowledge and agree that
            TradeTrax is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness,
            validity, copyright compliance, legality, decency, quality, or any other aspect thereof. TradeTrax does not
            assume and will not have any liability or responsibility to you or any other person or entity for any
            Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you,
            and you access and use them entirely at your own risk and subject to such third parties’ terms and
            conditions.{' '}
          </li>
          <li>
            <u> Term and Termination.</u> The term of this EULA commences when you first access and/or use TradeTrax and
            will continue in effect until terminated by you or TradeTrax as set forth herein. You may terminate this
            EULA by terminating all access and use of TradeTrax. TradeTrax may terminate this EULA and your access and
            use of TradeTrax at any time, without notice, and in its sole discretion. In addition, this EULA will
            terminate immediately and automatically without any notice if you violate any of the terms and conditions of
            this EULA. Upon termination:{' '}
            <ol type="a">
              <li>All rights granted to you under this EULA will also terminate; and</li>
              <li>
                You will cease all use of TradeTrax and delete all copies of TradeTrax from your electronic devices.
                Termination will not limit any of TradeTrax’ rights or remedies at law or in equity. Any section of this
                EULA which should reasonably survive will survive after termination.
              </li>
            </ol>
          </li>
          <li>
            <u> Disclaimer of Warranties.</u> YOUR USE OF TRADETRAX IS ENTIRELY AT YOUR OWN RISK. TRADETRAX, INCLUDING,
            WITHOUT LIMITATION, THE CONTENT AND SERVICES PROVIDED IN CONNECTION THEREWITH, ARE PROVIDED “<b>AS IS</b>”
            AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
            APPLICABLE LAW, TRADETRAX, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
            LICENSORS, SUCCESSORS AND ASSIGNS EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
            OR OTHERWISE, WITH RESPECT TO TRADETRAX, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING,
            COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, TRADETRAX PROVIDES NO
            WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT TRADETRAX WILL MEET YOUR REQUIREMENTS,
            ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPS, SYSTEMS, OR SERVICES,
            OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, BE ERROR-FREE, THAT ANY ERRORS
            OR DEFECTS CAN OR WILL BE CORRECTED, OR THAT THE OPERATION OF TRADETRAX WILL NOT AFFECT OR HARM YOUR DEVICE
            OR OTHER SOFTWARE, SYSTEMS, OR SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
            IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF
            THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.{' '}
          </li>
          <li>
            <u>Limitation of Liability. </u> TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL
            TRADETRAX OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS,
            AGENTS, LICENSORS, SUCCESSORS, OR ASSIGNS (COLLECTIVELY, THE “RELEASEES”) HAVE ANY LIABILITY ARISING FROM OR
            RELATED TO THIS EULA OR YOUR USE OF OR INABILITY TO USE TRADETRAX FOR:{' '}
            <ol type="a">
              <li>
                PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS
                OF GOODWILL, BUSINESS INTERRUPTION, COMMERCIAL DAMAGES OR LOSSES, COMPUTER FAILURE OR MALFUNCTION, OR
                ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, MULTIPLE, OR PUNITIVE DAMAGES; AND
              </li>
              <li>
                DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR TRADETRAX
                (COLLECTIVELY, “DAMAGES”).
              </li>
            </ol>
            THE FOREGOING LIMITATIONS OF LIABILITY WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR TRADETRAX
            WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF
            LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
          </li>
          <li>
            <u>WAIVER AND RELEASE.</u> YOU ACKNOWLEDGE THAT YOU ARE VOLUNTARILY ACCESSING AND/OR USING TRADETRAX,
            INCLUDING, WITHOUT LIMITATION, THE CONTENT AND SERVICES PROVIDED IN CONNECTION THEREWITH, AND AGREE TO:{' '}
            <ol type="a">
              <li>
                ACCEPT AND ASSUME ANY AND ALL RISKS OF DAMAGES, WHETHER CAUSED BY THE NEGLIGENCE OF TRADETRAX OR
                OTHERWISE; AND
              </li>
              <li>
                EXPRESSLY WAIVE AND RELEASE ANY AND ALL CLAIMS, NOW KNOWN OR HEREAFTER KNOWN, AGAINST TRADETRAX AND ALL
                OTHER RELEASEES ARISING OUT OF OR ATTRIBUTABLE TO THE ACCESS AND/OR USE OF TRADETRAX. YOU COVENANT NOT
                TO MAKE OR BRING ANY SUCH CLAIM AGAINST TRADETRAX OR ANY OTHER RELEASEE, AND FOREVER RELEASE AND
                DISCHARGE TRADETRAX AND ALL OTHER RELEASEES FROM LIABILITY UNDER SUCH CLAIMS.
              </li>
            </ol>
          </li>
          <li>
            <u>Indemnification.</u> You agree to indemnify, defend, and hold harmless TradeTrax and all other Releasees
            from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments,
            settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys’
            fees and expenses, arising from or relating to your use or misuse of TradeTrax or your breach of this EULA,
            including, without limitation, the content you submit or make available through this TradeTrax.{' '}
          </li>
          <li>
            <u>Export Regulation.</u> TradeTrax may be subject to U.S. export control laws, including the U.S. Export
            Administration Act and its associated regulations. You shall not, directly or indirectly, export, re-export,
            or release TradeTrax to, or make TradeTrax accessible from, any jurisdiction or country to which export, re-
            export, or release is prohibited by law, rule, or regulation. You shall comply with all applicable laws,
            regulations, and rules, and complete all required undertakings (including obtaining any necessary export
            license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making
            TradeTrax available outside the US.{' '}
          </li>
          <li>
            <u>Modifications to this EULA.</u> TradeTrax reserves the right to modify this EULA and to add new or
            additional terms or conditions on your use of TradeTrax from time to time in its sole discretion. Such
            modifications and additional terms and conditions will be effective immediately and incorporated into this
            EULA. Your continued use of TradeTrax will be deemed acceptance of any new or additional terms or conditions
            on your use of TradeTrax.{' '}
          </li>
          <li>
            <u>Copyright Infringement</u> If you believe in good faith that any of the content on TradeTrax infringes
            your copyright, please provide our copyright agent the following written information: (a) an electronic or
            physical signature of the person authorized to act on behalf of the owner of the copyright interest; (b) a
            description of the copyrighted work that you claim has been infringed; (c) a description of where the
            material that you claim is infringing is located on TradeTrax; (d) your address, telephone number and email
            address; (e) a statement by you that you have a good faith belief that the disputed use is not authorized by
            the copyright owner, its agent or the law; and (f) a statement by you, made under penalty of perjury, that
            the information in the notice is accurate and that you are the copyright owner, or are authorized to act on
            behalf of the owner, of an exclusive right that is allegedly infringed. TradeTrax’ copyright agent for
            notice of claims of copyright infringement can be reached as follows:
            <p className="mt-2">
              <p className="mb-0 ml-3">TradeTrax, Inc.</p>
              <p className="mb-0 ml-3">4150 N Drinkwater Blvd Ste 150</p>
              <p className="mb-0 ml-3">Scottsdale, AZ 85251 </p>
              <p className=" ml-3">contactus@tradetrax.com</p>
            </p>
          </li>
          <li>
            <u>Force Majeure.</u> You acknowledge that TradeTrax relies on third-party providers in the delivery of
            TradeTrax and the content and services provided in connection therewith, including, without limitation,
            wireless data network providers, and that Internet service is subject to transmissions limitations and
            dropped or interrupted transmissions. Internet may be temporarily refused, limited, interrupted, or
            curtailed because of government regulations or orders, atmospheric and/or topographical conditions, and
            Internet system modifications, repairs, and upgrades. You agree that TradeTrax shall not be liable for, and
            to hold TradeTrax harmless for any losses, damages, or interruptions sustained as a result of interruptions
            caused by Internet providers or any other third-party data network provider.{' '}
          </li>
          <li>
            <u>Severability.</u> If any provision of this EULA is illegal or unenforceable under applicable law, the
            remainder of the provision(s) will be amended to achieve as closely as possible the effect of the original
            term and all other provisions of this EULA will continue in full force and effect.{' '}
          </li>
          <li>
            <u>Governing Law.</u> This EULA is governed by and construed in accordance with the internal laws of the
            State of Delaware without giving effect to any choice or conflict of law provision or rule. Except as
            otherwise provided herein, the exclusive forum for the resolution of any dispute relating to this EULA shall
            be in the state courts located in Maricopa County, Arizona, and each of the parties agrees to personal
            jurisdiction of such courts with regard to any dispute relating to the EULA, and you agree to service of
            process on you by e-mail to the address you have submitted on TradeTrax, if any, and by any other means
            permitted by law.{' '}
          </li>
          <li>
            <u>Arbitration and Class Action Waiver.</u> PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES
            YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH TRADETRAX AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
            RELIEF FROM US. YOU AND TRADETRAX AGREE THAT ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING IN
            ANY WAY TO THIS EULA OR TRADETRAX SHALL BE FINALLY DECIDED BY BINDING ARBITRATION UNDER THE RULES OF THE
            AMERICAN ARBITRATION ASSOCIATION GOVERNING CONSUMER DISPUTES. Arbitration uses a single, neutral arbitrator
            to decide a dispute (instead of a judge or jury); arbitration allows for more limited discovery than in a
            court case; and the arbitration process and result is subject to very limited review by courts. In an
            arbitration you have the right, at your expense, to be represented by an attorney of your choosing.
            Arbitrators can award the same damages and relief under the EULA that a court can award under the EULA. You
            and TradeTrax agree that any in-person arbitral hearing would occur in the Maricopa County, Arizona.
            TradeTrax further agrees that your filing fee for an arbitration will be capped at the amount set by the
            American Arbitration Association. You agree that, by agreeing to the EULA, the U.S. Federal Arbitration Act
            governs the interpretation and enforcement of this provision, and that you and TradeTrax are each waiving
            the right to a trial by jury or to participate in a class action. This arbitration provision shall survive
            termination of the EULA and the termination of your use of the services. REGARDLESS OF THE FORUM, YOU AND
            TRADETRAX AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND
            NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both
            you and TradeTrax agree otherwise in writing, the arbitrator may not join or consolidate more than one
            person’s claims with your claims, and may not otherwise preside over any form of a representative or class
            proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration
            provision shall be null and void. The arbitrator may award declaratory or injunctive relief only in favor of
            the individual party seeking relief and only to the extent necessary to provide relief warranted by that
            party’s individual claim.{' '}
          </li>
          <li>
            <u>Limitation of Time to File Claims.</u> ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
            RELATING TO THIS EULA OR TRADETRAX MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES
            OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.{' '}
          </li>
          <li>
            <u>Remedies.</u> Except as provided in this EULA, the parties’ rights and remedies under this EULA are
            cumulative. You acknowledge that TradeTrax contains valuable trade secrets and proprietary information of
            TradeTrax , that any actual or threatened breach of this EULA will constitute immediate, irreparable harm to
            TradeTrax for which monetary damages would be an inadequate remedy, and that injunctive relief is an
            appropriate remedy for such breach. In any action or other proceeding brought under this EULA, the
            prevailing party shall be entitled to recover from the non-prevailing party, and the non-prevailing party
            shall pay the prevailing party’s reasonable attorneys’ fees, costs, and expenses, in each of the foregoing
            cases, that are incurred in connection with such action, arbitration, or proceeding.{' '}
          </li>
          <li>
            <u>Entire Agreement.</u> This EULA, our Privacy Policy, and any other agreement(s), documentation, and/or
            disclaimers made available in connection with your access and/or use of TradeTrax are hereby incorporated by
            reference, and constitute the entire agreement between you and TradeTrax with respect to TradeTrax, and
            supersede all prior understandings whether written or oral, with respect to TradeTrax.{' '}
          </li>
          <li>
            <u>Waiver.</u> No failure to exercise, and no delay in exercising, on the part of either party, any right or
            any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right
            or power hereunder preclude further exercise of that or any other right hereunder. In the event of a
            conflict between this EULA and any applicable purchase or other terms, the terms of this EULA shall govern.{' '}
          </li>
          <li>
            <u>App Marketplaces.</u> TradeTrax shall be deployed via the Apple AppStore and Android Market on Google
            Play Store. Use of TradeTrax is subject to terms and conditions imposed by the host environment, Apple Inc.,
            the Usage Rules, and/or Google LLC, respectively. End User shall reasonably cooperate with TradeTrax’
            efforts to comply with such terms. This EULA conforms to Apple’s App Store Terms of Service and the Usage
            Rules as of the effective date. This EULA is concluded between TradeTrax and End User only, and not with
            Apple. Apple, and Apple’s subsidiaries, are third party beneficiaries of the EULA, and upon the End User’s
            acceptance of the terms and conditions of this EULA, Apple will have the right (and will be deemed to have
            accepted the right) to enforce the EULA against the End User as a third party beneficiary thereof.
            TradeTrax, not Apple, is solely responsible for TradeTrax and the content thereof, and TradeTrax shall be
            responsible for addressing any claims of the End User or any third party relating to TradeTrax or End User’s
            possession and/or use of TradeTrax, including, but not limited to: (i) product liability claims; (ii) any
            claim that TradeTrax fails to conform to any applicable legal or regulatory requirement; and (iii) claims
            arising under consumer protection, privacy, or similar legislation. In the event of any third party claim
            that TradeTrax or End User’s access and/or use of TradeTrax infringes that third party’s intellectual
            property rights, TradeTrax, not Apple, will be solely responsible for the investigation, defense, settlement
            and discharge of any such intellectual property infringement claim. End User represents and warrants that:
            (a) it is not located in a country that is subject to a U.S. Government embargo, or that has been designated
            by the U.S. Government as a “terrorist supporting” country; and (b) it is not listed on any U.S. Government
            list of prohibited or restricted parties. Apple has no obligation whatsoever to furnish any maintenance and
            support services with respect to TradeTrax. In the event of any failure of TradeTrax to conform to any
            Applicable warranty, End User may notify Apple, and Apple will refund the purchase price for TradeTrax to
            End User; and, to the maximum extent permitted by Applicable law, Apple will have no other warranty
            obligation whatsoever with respect to TradeTrax, and any other claims, losses, liabilities, damages, costs
            or expenses attributable to any failure to conform to any warranty will be TradeTrax’ sole responsibility.
            If you are an individual acting in your personal capacity, you may download and use TradeTrax for personal,
            non-commercial use on any Apple-branded computer running MacOS that you own or control and as permitted by
            the Usage Rules set forth in the Apple Media Services Terms and Conditions, except that TradeTrax may be
            accessed and used by other accounts associated with the purchaser via Family Sharing or volume purchasing.{' '}
          </li>
        </ol>
        <p className="mt-2">
          Contact. If you have any questions regarding this EULA or TradeTrax, please contact: contactus@tradetrax.com.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton onClick={() => onClose()}>Okay</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Logo, TradeTrax, CircleLogo } from '../Logo';

const UnstyledBrand = ({ expanded, className }) => (
  <div className={className}>
    {!expanded && <CollapsedLogo />}
    {expanded && <Logo width={30} height={30} className="mr-1" />}
    {expanded && <TradeTrax width={84} height={13.5} />}
  </div>
);

const CollapsedLogo = styled(CircleLogo)`
  width: 2rem;
  height: 2rem;
  display: inline-block;
`;

export const Brand = styled(UnstyledBrand)`
  ${props => `
    transition: width 600ms ease;
    width: ${props.expanded ? '12.5rem' : '4rem'};
    height: 3.75rem;
    background: ${colors.gray800} !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    align-items: center;
    display: flex;
    justify-content: ${props.expanded ? 'left' : 'center'};
    padding-left: ${props.expanded ? '20px' : '0'};
  `}
`;

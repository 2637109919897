import React from 'react';
import { SystemNotification } from '../SystemNotification';

//ex: There is a schedule update on Concrete Task for Job
const reg = /^(.*update\son\s)(.*)(?:\sfor\s)(.*)$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, null, message, null, null];
};

export default ({ notification, controller, jobUrl }) => {
  const [, message, taskName, jobName] = parseNotification(notification.get('message'));

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={jobUrl({
        jobId: notification.getIn(['data', 'jobId']),
      })}
      linkHash="#feed"
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {message}
      {taskName ? <span className="text-dark">{taskName}</span> : null}
      {jobName ? (
        <>
          {' for '}
          <span className="text-dark">{jobName}</span>
        </>
      ) : null}
    </SystemNotification>
  );
};

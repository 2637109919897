import React, { useCallback, useMemo, useState } from 'react';
import { convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import mentionsStyles from './mentions.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';

function replaceMultipleStringPortions(string, replacementData) {
  let modifiedString = string;

  replacementData.map((data, index) => {
    const { offset, length } = data;
    const extraCharactersBySpan = index * 33;
    const newOffset = offset - index + extraCharactersBySpan;
    const tag = string.substr(offset, length);
    const newString = tag;
    modifiedString =
      modifiedString.substring(0, newOffset) +
      `<span class='taggedNote'>${newString}</span>` +
      modifiedString.substring(newOffset + length);
  });

  return modifiedString;
}

const Tagged = text => {
  const contentState = text;

  const commentWithTags = contentState.blocks
    .map(block => {
      const lineText = block.text;
      const mentionsArray = block.entityRanges;

      if (!mentionsArray.length) {
        return `${lineText} <br>`;
      }

      const modifiedString = replaceMultipleStringPortions(lineText, mentionsArray);

      return `<div class='textWithTag'> ${modifiedString} <br> </div>`;
    })
    .join('');

  return commentWithTags;
};

export const TextInputMention = React.forwardRef(
  ({ usersToTag, onChangeText, onFocus, className, editorState, setEditorState, user }, ref) => {
    const [open, setOpen] = useState(false);
    const userToTagArray = usersToTag.map(item => item.toObject());
    const [suggestions, setSuggestions] = useState(userToTagArray);

    const { MentionSuggestions, plugins } = useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        theme: mentionsStyles,
        mentionPrefix: '@',
        supportWhitespace: true,
      });
      const { MentionSuggestions } = mentionPlugin;
      const plugins = [mentionPlugin];
      return { plugins, MentionSuggestions };
    }, []);

    const onChange = useCallback(
      editorState => {
        const contentState = editorState.getCurrentContent();

        const commentWithTags = Tagged(convertToRaw(contentState));
        onChangeText(commentWithTags, convertToRaw(contentState));
        setEditorState(editorState);
      },
      [editorState, setEditorState]
    );

    const onOpenChange = useCallback(
      open => {
        setOpen(open);
      },
      [setOpen]
    );

    const customSuggestionsFilter = (searchValue, suggestions) => {
      const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

      const value = searchValue.toLowerCase();
      const filteredSuggestions = suggestions.filter(
        suggestion =>
          !value ||
          get(suggestion, 'name')
            .toLowerCase()
            .indexOf(value) > -1
      );
      return filteredSuggestions;
    };

    const onSearchChange = useCallback(
      ({ value }) => {
        setSuggestions(customSuggestionsFilter(value, userToTagArray));
      },
      [setSuggestions, userToTagArray]
    );

    return (
      <div className={`${mentionsStyles.editor} ${className}`}>
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          onFocus={() => onFocus()}
          placeholder="Add a Note"
          ref={ref}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          entryComponent={props => Entry(props, user)}
        />
      </div>
    );
  }
);

const getLabel = (mention, user) => {
  const { domain, type, accountName } = mention;
  const userDomain = user.get('domain');

  if (userDomain === 'sub') {
    if (domain === 'builder') return type === 'account' ? 'Company' : accountName;
  }
  if (userDomain === 'builder') {
    if (domain === 'sub') return type === 'account' ? 'Company' : accountName;
    if (domain === 'installer') return 'Installer';
  }

  return '';
};

const Entry = (props, user) => {
  const { mention, theme, ...parentProps } = props;
  const rightLabelOption = getLabel(mention, user);

  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
          <div className={theme?.mentionSuggestionsEntryTitle}>{rightLabelOption}</div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Navbar, Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../theme';
import { Initials } from '../Tags';
import { ChipStatusMenu } from '../ChipStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopHeader = styled(Navbar)`
  ${props => `
    margin-left: ${props.expanded ? '12.5rem' : '4rem'};
  `}
  transition: margin-left 600ms ease;
  background: ${colors.pearl};
  height: 3.75rem;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
  box-shadow: 0px 1px 2px ${colors.gray100};

  .btn-link {
    color: ${colors.gray400} !important;
  }

  .btn-link:hover,
  .btn-link:focus,
  .btn-link:active {
    color: ${colors.blue300} !important;
  }

  .user-name {
    border-left: solid 1px #dfe0eb;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 50px;
    color: ${colors.gray800};
    max-width: 200px;
    @media (min-width: 1200px) {
      max-width: 400px;
    }
  }

  .badge-danger {
    font-size: 10px;
    top: -8px;
    left: -8px;
  }
`;

export const InitialsToggle = React.forwardRef(({ name, initials, onClick }, ref) => (
  <Initials innerRef={ref} onClick={onClick} className="ml-3 cursor-pointer" size={42} name={initials || name} />
));

export const UserInfoMenu = ({ name, initials, children, onSelect }) => {
  return (
    <Navbar.Text className="user-name">
      <span className="text-nowrap text-truncate">{name}</span>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle as={InitialsToggle} name={initials || name}></Dropdown.Toggle>
        <ChipStatusMenu alignRight textalign="left">
          {children}
          <Dropdown.Item eventKey="logout" className="text-danger">
            <FontAwesomeIcon icon="right-from-bracket" /> Log out
          </Dropdown.Item>
        </ChipStatusMenu>
      </Dropdown>
    </Navbar.Text>
  );
};

const UserNameContainer = styled.span`
  width: 150px;
  @media (min-width: 1200px) {
    min-width: 150px;
    width: 250px;
  }
`;

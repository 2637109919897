import React from 'react';
import { ToggleButtons, ToggleButton } from '../ToggleButtons';
import { Notes } from '../Notes/Notes';
import { Attachments } from '../Photos/Attachments/Attachments';
import { SubTasksList } from './SubTasksList';
import { TaskFooterContext } from './TaskFooterContext';
import { getUserDomain } from '../utils';

export const TaskFooter = ({ task, service, controller: jobController, appContext, className = '', location }) => {
  const { state, controller, containerRef } = TaskFooterContext(appContext, service);
  const { attachmentsCount, notesCount } = state.toObject();
  const { setAttachmentsCount, setNotesCount } = controller;
  const props = { task, service, appContext, setAttachmentsCount, setNotesCount };
  const loggedUser = appContext.appState.get('user');
  const { isBuilder } = getUserDomain(loggedUser);

  const loggedAccountId = loggedUser.get('accountId');
  const taskAssigneeId = task.getIn(['assigneeAccount', 'companyId']);
  const showAddAttachment = isBuilder || loggedAccountId == taskAssigneeId;

  const defaultTab = location?.hash ? location.hash.replace('#', '') : state.get('tab');

  const [tab, setTab] = React.useState(defaultTab);

  return (
    <>
      <div ref={containerRef} className={className} style={{ height: '715px' }}>
        <ToggleButtons type="radio" name="section" value={tab} onChange={setTab} className="mb-3">
          <ToggleButton value="attachments">Attachments ({attachmentsCount})</ToggleButton>
          <ToggleButton value="notes">Notes ({notesCount})</ToggleButton>
          <ToggleButton value="subTasks">Sub-Tasks ({task.get('children').size})</ToggleButton>
        </ToggleButtons>
        <Attachments {...props} visible={tab === 'attachments'} showAddAttachment={showAddAttachment} />
        <Notes {...props} visible={tab === 'notes'} />
        <SubTasksList task={task} controller={jobController} appContext={appContext} visible={tab === 'subTasks'} />
      </div>
    </>
  );
};

import React from 'react';
import { SystemNotification } from '../SystemNotification';

const pluralReg = /^(.*)(?:\son\s)(\d+)(?:\stasks)(.*)$/;
const singularReg = /^(.*)(?:\sfor\s)(.*)(?:\son\s)(.*)$/;
const parsePluralNotification = message => {
  const result = (message || '').match(pluralReg);
  return result || [null, message, null, null];
};
const parseSingularNotification = message => {
  const result = (message || '').match(singularReg);
  return result || [null, message, null, null];
};

export default ({ notification, controller, taskUrl, openTasksUrl }) => {
  const isPlural = notification.get('message').includes('crews');
  return (
    <>
      {isPlural && (
        <PluralCheckIn notification={notification} controller={controller} openTasksUrl={openTasksUrl}></PluralCheckIn>
      )}
      {!isPlural && (
        <SingularCheckIn notification={notification} controller={controller} taskUrl={taskUrl}></SingularCheckIn>
      )}
    </>
  );
};

function PluralCheckIn({ notification, controller, openTasksUrl }) {
  const [, message, numberOfTasks] = parsePluralNotification(notification.get('message'));
  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={openTasksUrl()}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {message}
      {numberOfTasks && <span className="text-dark"> on {numberOfTasks}</span>} tasks!
    </SystemNotification>
  );
}

function SingularCheckIn({ notification, controller, taskUrl }) {
  const [, message, taskName, jobName] = parseSingularNotification(notification.get('message'));
  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={taskUrl({
        jobId: notification.getIn(['data', 'jobId']),
        taskId: notification.getIn(['data', 'taskId']),
      })}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {message}
      {taskName && <span className="text-dark"> for {taskName}</span>}
      {jobName && <span className="text-dark"> on {jobName}</span>}
    </SystemNotification>
  );
}

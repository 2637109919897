import React from 'react';
import styled from 'styled-components';
export { TradeTrax } from './TradeTrax';
export { CircleLogo } from './CircleLogo';
// import PropTypes from 'prop-types';

export const defaultProps = {
  backgroundColor: 'none',
  leftColor: 'white',
  bottomColor: '#FC5C4E',
  rightColor: '#07D99D',
  width: 60,
  height: 60,
};

// export const propTypes = {
//     backgroundColor: string,
// }

const Svg = styled.svg`
  ${props => `
    @media (max-width: 767.98px) {
      width: ${props.width - 8}px;
      height: ${props.height - 8}px;
    }
  `}
`;

export const Logo = ({ className = '', width, height, backgroundColor, leftColor, rightColor, bottomColor }) => (
  <Svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 60 60`}
    fill={backgroundColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6761 22.7216L49.4125 0H9.44215C4.22727 0 0 4.22727 0 9.44215V39.0456H15.7264L15.6761 22.7216Z"
      fill={leftColor}
    />
    <path d="M0 42.1154V50.3497C0 55.5646 4.22727 59.7919 9.44215 59.7919H41.2976V42.1154H0Z" fill={bottomColor} />
    <path
      d="M59.7919 50.3498V9.44216C59.7919 5.44136 57.3008 2.03187 53.7907 0.660522L32.9312 14.8646L44.3737 22.5643V59.7919H50.3435C55.5646 59.7919 59.7919 55.5646 59.7919 50.3498Z"
      fill={rightColor}
    />
  </Svg>
);

Logo.defaultProps = defaultProps;

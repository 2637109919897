import React from 'react';
import { SystemNotification } from '../SystemNotification';

export default ({ notification, controller, taskUrl }) => {
  const reg = /^(.*)(?:\sneeds\s)(.*)(?:\son\s)(.*)$/;
  const parseNotification = message => {
    const result = (message || '').match(reg);
    return result || [message, message, message];
  };
  const [, taskName, message, jobName] = parseNotification(notification.get('message'));

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={taskUrl({
        jobId: notification.getIn(['data', 'jobId']),
        taskId: notification.getIn(['data', 'taskId']),
      })}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {taskName && <span className="text-dark"> {taskName}</span>}
      {` needs ${message} on `}
      {jobName && <span className="text-dark"> {jobName}</span>}
    </SystemNotification>
  );
};

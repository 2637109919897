import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';
import { formatTrx } from '../utils';

export const UnstyledWithIcon = ({ className, isInvalid, innerRef, ...props }) => (
  <div className={className}>
    <input className="form-control" {...props} ref={innerRef} />
    <FontAwesomeIcon icon="calendar-days" />
  </div>
);

export const StyledWithIcon = styled(UnstyledWithIcon)`
  ${props => `
  svg {
    position: absolute;
    top: 0.65rem;
    right: 0.30rem;
    color: ${props.isInvalid ? colors.red : colors.gray400};
    pointer-events: none;
  }

  input:read-only {
    background-color: white;
  }

  .form-control {
    ${
      props.isInvalid
        ? `border-color: ${colors.red};`
        : `
      &:focus + svg {
        color: ${colors.blue400};
      }
      &:hover {
        border-color: ${colors.blue400};
        & + svg {
          color: ${colors.blue400};
        }
      }      
      `
    }
  }
  `}
`;

const Wrapper = React.forwardRef(({ ...props }, ref) => <StyledWithIcon innerRef={ref} {...props} />);

export default function Datepicker({ isInvalid = false, isReadOnly = false, datePickerRef, ...props }) {
  return (
    <>
      {!isReadOnly && (
        <DatePicker
          customInput={<Wrapper isInvalid={isInvalid} />}
          {...props}
          autoComplete="off"
          ref={datePickerRef}
          strictParsing
        />
      )}
      {isReadOnly && <span className="text-gray-200">{props.selected ? formatTrx(props.selected) : 'Not Set'}</span>}
    </>
  );
}

export function CalendarDatepicker({ onChange, name, state, datePickerRef }) {
  const { calendarView, date } = state.toObject();
  const isMonthView = calendarView === 'dayGridMonth';
  return (
    <MonthlyWrapper>
      <Datepicker
        name={name}
        selected={date}
        placeholderText=""
        dateFormat="MM/yyyy"
        datePickerRef={datePickerRef}
        onChange={date => onChange(date)}
        renderCustomHeader={props => <DatepickerHeader {...props} isMonthView={isMonthView} />}
        showMonthYearPicker={isMonthView}
      />
    </MonthlyWrapper>
  );
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DatepickerHeader = ({ isMonthView, decreaseYear, increaseYear, decreaseMonth, increaseMonth, date }) => {
  const prev = isMonthView ? decreaseYear : decreaseMonth;
  const next = isMonthView ? increaseYear : increaseMonth;
  const text = isMonthView ? date.getFullYear() : `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-around bg-pearl mx-3 rounded blue-bar">
        <span onClick={prev} className="px-3 py-1 text-secondary cursor-pointer fc-button">
          <FontAwesomeIcon icon="chevron-left" />
        </span>
        <span className="font-size-14 mt-1">{text}</span>
        <span onClick={next} className="px-3 py-1 text-secondary cursor-pointer fc-button">
          <FontAwesomeIcon icon="chevron-right" />
        </span>
      </div>
    </div>
  );
};

const MonthlyWrapper = styled.div`
  // DATEPICKER
  width: 0px;
  height: 0px;
  .react-datepicker-wrapper {
    visibility: hidden;
  }
  .react-datepicker__header.react-datepicker__header--custom {
    border-radius: 10px;
    border-top: solid 10px #017cee;

    .fc-button {
      &:hover {
        color: ${colors.blue300} !important;
      }
      &:active {
        color: ${colors.blue400} !important;
      }
    }
  }
  .react-datepicker__month-text {
    width: 3.5rem;
    height: 22px;
    padding-top: 0.2rem;
    &:hover {
      border-radius: 10px;
      background-color: ${colors.blue100};
    }
    &.react-datepicker__month--selected:hover {
      border-radius: 10px;
      background-color: ${colors.blue400};
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverStickOnHover } from '../Popover';
import { colors } from '../theme';

export function PopoverSMS() {
  return (
    <PopoverStickOnHover
      placement="bottom-start"
      popover={popover()}
      className="pt-0"
      popoverId="popover-trx"
      width={280}
    >
      <span className="btn btn-link">
        <FontAwesomeIcon icon="square-info" className="mr-1 text-primary" />
      </span>
    </PopoverStickOnHover>
  );
}

const popover = () => {
  return (
    <div className="px-3 pb-3 pt-3">
      Receive text messages to recover access to the platform. Message and data rates may apply.
      <br />
      <div className="mt-2">
        Review{' '}
        <ButtonLink href="https://tradetrax.com/privacy-policy" target="_blank">
          Privacy Policy
        </ButtonLink>
        ,{' '}
        <ButtonLink href="https://tradetrax.com/terms-of-use" target="_blank">
          Terms of Use
        </ButtonLink>{' '}
        and{' '}
        <ButtonLink href="https://tradetrax.com/end-user-license-agreement" target="_blank">
          End-User License Agreement.
        </ButtonLink>
      </div>
    </div>
  );
};

const ButtonLink = styled.a`
  cursor: pointer;
  color: ${colors.blue300};

  &:hover {
    text-decoration: underline;
  }
`;

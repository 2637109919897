import React from 'react';

export class JobDetailsAdapter {
  #filtersService = null;

  constructor(filtersService) {
    this.#filtersService = filtersService;
  }

  set filtersService(service) {
    this.#filtersService = service;
  }

  get filtersService() {
    return this.#filtersService;
  }

  readFilters() {
    return this.filtersService.readJobFilters({});
  }

  createFilter(body) {
    return this.filtersService.createJobFilter({ type: 'jobSchedule', ...body });
  }

  updateFilter(body, options) {
    return this.filtersService.updateJobFilter(body, options);
  }

  batchUpdateFilters(body) {
    return this.filtersService.batchUpdateJobFilters(body);
  }

  // method(body, options) {
  //   return this.filtersService.method(body, options);
  // }

  shareFilter(body, options) {
    return this.filtersService.shareJobFilter(body, options);
  }

  unshareFilter(body, options) {
    return this.filtersService.unshareJobFilter(body, options);
  }

  readFilterUsersAndRoles(body, options) {
    return this.filtersService.readJobFilterUsersAndRoles(body, options);
  }

  listRoles(body, options) {
    return this.filtersService.listRoles(body, options);
  }
}

export const useJobDetailsAdapter = filtersService =>
  React.useMemo(() => new JobDetailsAdapter(filtersService), [filtersService]);

import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

// TODO: make this method safe (more robust).
export const initials = name => {
  const tokens = (name || '').toUpperCase().split(/\s/);
  if (tokens.length === 0) return '';
  const a = tokens[0].charAt(0);
  const b = tokens.length > 1 ? tokens[1].charAt(0) : tokens[0].charAt(1);
  return a + b;
};

const BORDER_FACTOR = 0.0625;
const FONT_FACTOR = 2.5;

const UnstyledInitials = ({ name = '', className, innerRef, ...rest }) => (
  <span className={className} {...rest} ref={innerRef}>
    {name?.length === 2 ? name.toUpperCase() : initials(name)}
  </span>
);

export const Initials = styled(UnstyledInitials)`
  ${props => `
    min-width: ${props.size}px;
    width: ${props.size}px;
    height: ${props.size}px;
    min-height: ${props.size}px;
    border: solid ${props.size * BORDER_FACTOR}px ${colors.white};
    font-size: ${props.size / FONT_FACTOR}px;
    color: ${props.disabled ? colors.gray200 : colors.gray400};
    background: ${props.disabled ? colors.blue100 : colors.blue_30};
  `}
  border-radius: 50%;
  font-weight: bold;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin-right: 0.625rem;
`;

Initials.defaultProps = {
  size: 60,
  name: 'Trade Trax',
};

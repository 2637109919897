import React from 'react';
import { Pagination } from 'react-bootstrap';
import queryString from 'query-string';
import { history } from './history';

const PAGE_SIZE = 10;

export const pager = tasks => {
  const { location } = history;
  const qs = queryString.parse(location.search);
  const lastPage = Math.ceil(tasks.count() / PAGE_SIZE);
  const active = Math.min(parseInt(qs.page || '1', 10), lastPage);
  const startIndex = (active - 1) * PAGE_SIZE;
  const items = tasks.slice(startIndex, Math.min(startIndex + PAGE_SIZE, tasks.count()));

  let i = Math.max(1, active - 5);
  let end = Math.min(i + 9, lastPage);
  const pageNav = page => {
    qs.page = page;
    const search = queryString.stringify(qs);
    const hash = location.hash ? `#${location.hash}` : '';
    const href = `${location.pathname}?${search}${hash}`;
    const onClick = e => {
      e.preventDefault();
      history.push(href);
    };
    return { href, onClick };
  };

  const pages = [<Pagination.First key={0} {...pageNav(1)} />];
  for (; i <= end; i++) {
    pages.push(
      <Pagination.Item key={i} active={i === active} {...pageNav(i)}>
        {i}
      </Pagination.Item>
    );
  }

  pages.push(<Pagination.Last {...pageNav(lastPage)} key={end + 1} />);

  return { pages, items };
};

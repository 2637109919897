import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../theme';

export const SubLabel = styled.span`
  position: absolute;
  left: 0px;
  top: 16px;
  font-size: 12px;
  letter-spacing: 0.1px;
`;

export const EmptyAssignment = styled.span`
  display: inline-block;
  padding: 0.5em;
  padding-right: 1.5rem;
  padding-left: 0.625rem;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 6px;
  background-color: ${colors.activeState};
`;

export const StyledTable = styled(Table)`
  th,
  td {
    border-top: none;
  }
  thead tr {
    th {
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      color: ${colors.gray400};
      border-bottom: none;
      padding: 0.75rem 0;
      &:first-child {
        width: 2rem;
      }
    }
  }

  tbody {
    tr {
      min-height: 80px;
      &.edit-row {
        background: ${colors.tableHover};
      }

      &.loading {
        td {
          background: ${colors.pearl};
          span {
            display: inline-block;
            width: 80%;
            background: ${colors.gray200};
            height: 12px;
            border-radius: 5px;
            opacity: 0.5;
          }
        }
      }

      td {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.75rem 0;
        border-top: solid 1px ${colors.gray100};
        border-bottom: solid 1px ${colors.gray100};

        &:first-child {
          width: 2rem;
          color: red;

          &::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            min-height: 60px;
          }
        }

        .main-link {
          font-weight: bold;
          color: ${colors.gray800};
        }

        &.drag-icon {
          width: 3rem;
          svg {
            margin-right: 0.5rem;
            color: ${colors.gray400};
            &:hover {
              color: ${colors.blue400};
              ${'' /* cursor: move;
              cursor: grab; */}
            }
          }
        }
      }
    }
  }
`;

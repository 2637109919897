import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';
import { IconButtonWrap } from '../Button';

const DropdownButton = styled.button`
  ${props => `
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    padding: 2px 0;
    border-radius: 8px;
    svg {
      color: ${props.disabled ? colors.gray200 : colors.gray400};
    }
    
    &:focus,
    &:active,
    &:hover {
      background: ${colors.pearl};
      svg {
        color: ${props.disabled ? colors.gray200 : colors.blue400};
      }
    }
  `}
`;

const EllipsisV = React.forwardRef(({ children, onClick, disabled }, ref) => (
  <DropdownButton
    ref={ref}
    disabled={disabled}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon="ellipsis-vertical" />
    {children}
  </DropdownButton>
));

export const EllipsisVButton = React.forwardRef(({ onClick }, ref) => (
  <IconButtonWrap
    icon="ellipsis-vertical"
    className="btn"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon="ellipsis-vertical" />
  </IconButtonWrap>
));

export default ({ children, onSelect }) => (
  <Dropdown onSelect={onSelect}>
    <Dropdown.Toggle as={EllipsisV}></Dropdown.Toggle>
    <DropdownMenu>{children}</DropdownMenu>
  </Dropdown>
);

export const IconItem = ({ icon, eventKey, label, className = '' }) => (
  <Dropdown.Item eventKey={eventKey} className={className}>
    <FontAwesomeIcon icon={icon} className="mr-2" />
    {label}
  </Dropdown.Item>
);
export const TableRowDropDown = ({
  toggle = EllipsisV,
  onSelect,
  children,
  remove = false,
  rename = false,
  duplicate = false,
  deactivate = false,
  reactivate = false,
  disabled = false,
  menuStyles = null,
  ...options
}) => (
  <Dropdown onSelect={onSelect}>
    <Dropdown.Toggle as={toggle} disabled={disabled}></Dropdown.Toggle>
    <DropdownMenu alignRight>
      {children}
      {options.download && <IconItem icon="arrow-down-to-line" eventKey="download" label="Download" />}
      {rename && <DropDownOption option="rename" icon="pen" />}
      {remove && <DropDownOption option="remove" icon="trash" />}
      {duplicate && <DropDownOption option="duplicate" icon="copy" />}
      {deactivate && <IconItem icon="ban" eventKey="deactivate" label="Deactivate" className="text-danger" />}
      {reactivate && <IconItem icon="circle-plus" eventKey="reactivate" label="Reactivate" className="text-success" />}
      {/* TODO: use this idea for the top-right dropdown menu on task page. */}
      {options.addTask && <IconItem icon="wrench" eventKey="add-task" label="Add Task" />}
      {options.renameTask && <IconItem icon="pen-to-square" eventKey="rename-task" label="Rename Task" />}
      {options.duplicateTask && <IconItem icon="copy" eventKey="duplicate-task" label="Duplicate Task" />}
      {options.removeTask && <IconItem icon="trash" eventKey="remove-task" label="Remove Task" />}
      {options.removePhoto && <IconItem icon="trash" eventKey="remove-photo" label="Remove Photo" />}
      {options.removeRule && <IconItem icon="trash" eventKey="remove-rule" label="Remove Rule" />}
      {options.addStage && <IconItem icon="bars-staggered" eventKey="add-stage" label="Add Stage" />}
      {options.removeStage && <IconItem icon="trash" eventKey="remove-stage" label="Remove Stage" />}
    </DropdownMenu>
  </Dropdown>
);

const DropDownOption = ({ option, icon }) => (
  <Dropdown.Item eventKey={option} className="text-capitalize">
    <FontAwesomeIcon icon={icon} className="mr-2" />
    {option}
  </Dropdown.Item>
);

TableRowDropDown.IconItem = IconItem;

export const DropdownMenu = styled(Dropdown.Menu)`
  border-radius: 0.5rem;
  .dropdown-item {
    color: ${colors.gray400};

    &:hover,
    &:active,
    &.active,
    &:focus {
      color: ${colors.blue300};
      background: ${colors.blue100};
    }
  }
`;

import React from 'react';
import cn from 'classnames';
import { Initials } from '../Tags';
import { PrimaryButton } from '@tradetrax/web-common';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShowMoreUsersModal = ({ supers, schedulers, title, close, community }) => {
  return (
    <Modal show={true} onHide={close} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          Assignees
          <h6 className="font-weight-bold">{title}</h6>
          <h6 className="font-weight-bold">{community}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="font-size-14">Super Assigned</span>
        <div className="mt-2 mb-3 pl-3">
          {!supers.size && <span className="text-gray-200">Not Set</span>}
          {supers.map((user, index) => {
            const isInactiveUser = user.status === 'inactive';
            return (
              <div key={index} className="w-50 d-inline-block">
                <span className="d-flex flex-row align-items-center">
                  <Initials name={user.initials || user.name} size={30} />
                  {isInactiveUser && <FontAwesomeIcon icon="ban" className="mr-2 text-danger muted" />}
                  <span className={cn('text-truncate w-9rem', { 'text-gray-200': isInactiveUser })}>{user.name}</span>
                </span>
              </div>
            );
          })}
        </div>
        <span className="font-size-14">Scheduler Assigned</span>
        <div className="mt-2 mb-3 pl-3">
          {!schedulers.size && <span className="text-gray-200">Not Set</span>}
          {schedulers.map((user, index) => {
            const isInactiveUser = user.status === 'inactive';

            return (
              <div key={index} className="w-50 d-inline-block">
                <span className="d-flex flex-row align-items-center">
                  <Initials name={user.initials || user.name} size={30} />
                  {isInactiveUser && <FontAwesomeIcon icon="ban" className="mr-2 text-danger muted" />}
                  <span className={cn('text-truncate w-9rem', { 'text-gray-200': isInactiveUser })}>{user.name}</span>
                </span>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton type="submit" onClick={close}>
          Okay
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

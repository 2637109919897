import React from 'react';
import { EventEmitter } from 'events';

const emitter = new EventEmitter();

export const EventsContext = React.createContext(emitter);

export function useEvents() {
  const emitter = React.useContext(EventsContext);

  return emitter;
}

export function useEvent(name, handler) {
  const emitter = React.useContext(EventsContext);

  React.useEffect(() => {
    emitter.on(name, handler);
    return () => {
      emitter.off(name, handler);
    };
  }, [emitter, name, handler]);
}

// this is not a hook it will use the global `emitter`
// if there is a defined `EventContext` with a different `emitter` it wont be used.
export function emitEvent(name, ...args) {
  emitter.emit(name, ...args);
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import { Logo, TradeTrax, colors, PrimaryButton } from '@tradetrax/web-common';

export const TermsModal = ({ onHide, close }) => {
  const onClose = onHide || close;
  return (
    <Modal show={true} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex flex-row align-items-center mb-4">
            <Logo className="mr-3" leftColor={colors.black} width={29} height={29} />
            <TradeTrax width="100px" hight="30px" color={colors.black} />
          </div>
          TERMS OF USE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable="true" style={{ maxHeight: 'calc(100vh - 310px)', overflowY: 'auto' }}>
        <p>
          These Terms and Conditions of Use (these “<b>Terms</b>”) set forth the terms and conditions under which
          TradeTrax, Inc (“<b>TradeTrax</b>”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”) makes the TradeTrax Platform
          (the “<b>Platform</b>”) available to you (“<b>you</b>” or “<b>your</b>”) as a registered Platform user. By
          completing and maintaining your registration on the Platform, you confirm your agreement to be bound by these
          Terms. Initially capitalized terms used but not defined in these Terms have the meanings given to them in the
          Service Agreements (“Services Agreements”) available to authorized parties of these agreements.
        </p>
        <ol>
          <li>
            <u>Use of Platform.</u> You will only use the Platform for the purposes described in the Services
            Agreements.{' '}
          </li>
          <li>
            <u>Protection of Login Credentials.</u> You agree to keep and protect your Platform login credentials
            confidential, not share them with any person or entity, and otherwise protect them from unauthorized use.
            Failure on your part to adequately protect your login credentials or your providing any person or entity
            with use of your login credentials are grounds for immediate termination of your Platform account.
          </li>
          <li>
            <u>Authorization to Disclose Information.</u> In connection with the registration process, by disclosing to
            TradeTrax and/or uploading to the Platform information regarding yourself and/or any other person or entity
            to whom or to which such information relates, you represent and warrant to TradeTrax that you are authorized
            to disclose and/or upload such information, and that TradeTrax is authorized to retain, use and disclose
            such information as it deems necessary or appropriate in connection with the conduct of its business.
          </li>
          <li>
            <u>Platform Access.</u>You are responsible for obtaining and maintaining, at your own cost, all equipment
            and data network access necessary to use access and use the Platform. Your mobile network's data and
            messaging rates and fees may apply if you access or use the Platform from your mobile device(s). You are
            responsible for acquiring and updating compatible hardware or devices necessary to access and use the
            Platform, including as a result of any updates thereto. We do not guarantee that the Platform, or any
            portion thereof, will function on any particular hardware or device. Further, access to and use of the
            Platform may be subject to malfunctions and delays inherent in the use of the Internet and electronic
            communications. We disclaim any liability arising from any such malfunction or delay
          </li>
          <li>
            <u>Prohibited Conduct.</u> In connection with your use of the Platform, you agree that you will not do any
            of the following:
            <ol type="a">
              <li>knowingly provide or submit false or misleading information;</li>
              <li>
                use the Platform in connection with the distribution of unsolicited commercial, political or other
                messages (i.e., spam) unrelated to the receipt or performance of services available on the Platform;
              </li>
              <li>
                discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender
                identity, physical or mental disability, medical condition, marital status, age or sexual orientation,
                or otherwise engage in any violent, harmful, abusive or disruptive behavior;
              </li>
              <li>
                use, display or disseminate (including by inclusion in your own website or other printed materials)
                TradeTrax’s name, trademark(s), Platform content or other proprietary copyrights of TradeTrax without
                TradeTrax’s express written consent;
              </li>
              <li>
                dilute, tarnish or otherwise harm the TradeTrax brand in any way, including through registering and/or
                using internet domain names, trade names, trademarks or other source identifiers that are confusingly
                similar to TradeTrax domain names, trademarks, trade names or other copyrighted material;
              </li>
              <li>
                use any technology (including robots, spiders, crawlers, scrapers, viruses, trojan horses) or other
                automated means or processes to (i) access or collect data or other content from the Platform for any
                purpose, or (ii) avoid, bypass, remove, deactivate, impair, descramble,
              </li>
              <li>
                or otherwise attempt to circumvent any technological measure implemented by TradeTrax to secure and
                protect the Platform; or
              </li>
              <li>
                take any other action that damages or adversely affects, or could damage or adversely affect the
                performance or proper functioning of the Platform.
              </li>
            </ol>
          </li>
          <li>
            <u>User-Supplied Content.</u>
            <ol>
              <li>
                We may, in our sole discretion, permit you from time to time to submit, upload, publish or otherwise
                make available to us through the Platform textual, audio, and/or visual content and information,
                including commentary and feedback related to the provision or receipt of services available on the
                Platform ("User-Supplied Content"). Any User-Supplied Content provided by you remains your property.
              </li>
              <li>
                You represent and warrant that: (i) you either are the sole and exclusive owner of all User-Supplied
                Content or you have all rights, licenses, consents and releases necessary to grant to us the license to
                the User-Supplied Content as set forth above; (ii) each person identified, depicted, or shown in in your
                User-Supplied Content, if any, has provided consent to the use of such User-Supplied Content consistent
                with these Terms of Use; and (iii) neither the User-Supplied Content, nor your submission, uploading,
                publishing or otherwise making available of such User-Supplied Content, nor our use of any User-Supplied
                Content as permitted herein will infringe, misappropriate or violate a third party's intellectual
                property or proprietary rights, or rights of publicity or privacy, or result in the violation of any
                applicable law or regulation. We reserve the right but are not obligated, to review, monitor, or remove
                User-Supplied Content, in our sole discretion and at any time and for any reason, without notice to you.
              </li>
              <li>
                We do not guarantee the accuracy, integrity, quality or appropriateness of any User-Supplied Content
                appearing on or accessed through the Platform. Any such User-Supplied Content is the sole responsibility
                of the person or entity from whom or which such User-Supplied Content originated. By using the Platform,
                you understand that you may be exposed to User-Supplied Content that is incorrect, inaccurate or
                misleading. We have no obligation to screen, preview, monitor or approve any User-Supplied Content.
                However, we reserve the right to review and delete any User-Supplied Content that, in our sole
                discretion, violates any Services Agreements between TradeTrax and any User, these Terms or any other
                policies of TradeTrax. You agree that You must evaluate and make your own judgment, and bear all risks
                associated with, your use of, or any decision that you make with respect to, any User-Supplied Content.
                Under no circumstances will we be liable in any way for any User-Supplied Content that contains any
                errors, omissions, defamatory statements, or confidential or private information, or for any loss or
                damage of any kind incurred as a result of the use of any User-Supplied Content submitted, accessed,
                transmitted or otherwise conveyed through the Platform. You waive the right to bring or assert any claim
                against us or any of our affiliates relating to User-Supplied Content, and release us and our affiliates
                from any and all liability for or relating to any User-Supplied Content.
              </li>
            </ol>
          </li>
          <li>
            <u>Amendment</u> We may amend these Terms from time to time in our sole discretion. Amendments will be
            effective upon our posting of such updated Terms at this location and available at contracts@tradetax.com.
            Your continued access to and use of the Platform after such posting confirms your consent to be bound by
            these Terms, as so amended
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton onClick={() => onClose()}>Okay</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

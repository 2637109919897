import React from 'react';
import { useController } from '../useController';
import { Map } from 'immutable';
import * as actions from './NotesActions';
import { useChannel, CHANNELS } from '../useRealTime';

export const MAX_CHARS = 1000;

const emptyState = Map({
  notes: Map({ isLoading: true }),
  isUploadNote: false,
  usersToTag: [],
  charLeft: MAX_CHARS,
  isEditing: false,
  textValue: '',
  inputData: '',
});

export const NotesController = (task, notesService, appContext) => {
  const [state, controller] = useController(actions, emptyState, { notesService, task, info, ...appContext });

  React.useEffect(() => {
    controller.readNotes();
    controller.getUsersToTagInNotes();
  }, [controller, task]);

  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);

  const refreshNotes = React.useMemo(() => ({ action }) => action === 'update-notes' && controller.readNotes(), [
    controller,
    jobId,
    taskId,
  ]);

  useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, refreshNotes);

  return { state, controller };
};

function info() {
  const taskId = this.task.get('id');
  const jobId = this.task.getIn(['job', 'id']);
  const user = this.appState.get('user');
  return { user, taskId, jobId };
}

import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from '../theme';

export const PrimaryButton = styled(Button)`
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2rem;
  border-radius: 30px;
  font-weight: bold;

  &.btn-primary:not(.disabled):hover,
  &.btn-primary:not(:disabled):hover {
    background: ${colors.blue400};
    border-color: ${colors.blue400};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  }

  &.btn-secondary:not(.disabled):hover,
  &.btn-secondary:not(:disabled):hover {
    background: ${colors.gray800};
    border-color: ${colors.gray800};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  }

  &.btn-primary,
  &.btn-secondary {
    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      background: ${colors.gray200};
      border-color: ${colors.gray200};
      box-shadow: inset 0px 1px 2px ${colors.deactivated};
    }
  }
`;

export const SecondaryButton = styled(Button)`
  height: 2.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2.5rem;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border: solid 1px ${colors.blue300};

  &.btn-primary:not(.disabled):hover,
  &.btn-primary:not(:disabled):hover {
    background: ${colors.blue400};
    border-color: ${colors.blue400};
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  }

  &.btn-primary {
    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      background: ${colors.gray200};
      border-color: ${colors.gray200};
      box-shadow: inset 0px 1px 2px ${colors.deactivated};
    }
  }
`;

export const BannerButton = styled(Button)`
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
  border-radius: 30px;
  font-weight: bold;

  border: 1px solid ${colors.blue300};
  background-color: white;
  color: ${colors.blue300};

  &:hover {
    background-color: ${colors.blue300};
  }
`;

export const IconButton = ({ className = '', icon, children, small = false, ...props }) => (
  <IconButtonWrap className={`btn ${className}`} small={small} {...props}>
    <FontAwesomeIcon icon={icon} className={small ? 'font-size-12' : ''} />
    {children}
  </IconButtonWrap>
);

export const IconButtonWrap = styled.button`
  ${props => `
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
    border-radius: 50%;
    ${
      props.small
        ? `
      min-width: 30px;
      min-height: 30px;
      width: 30px;
      height: 30px;
    `
        : `
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
    `
    }
    ${props.large &&
      `
      min-width: 70px;
      min-height: 40px;
      width: 70px;
      height: 40px;
      border-radius: 70px;
    `}
    line-height: 1rem;
    font-size: 18px;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    background: ${props.primary ? colors.blue200 : colors.gray100};
    border-color: ${props.primary ? colors.blue200 : colors.gray100};
    color: ${props.primary ? colors.white : colors.gray400};

    &.filter_white{
      background: ${colors.white};
    }
    &.filterSet:not(:disabled){
      background: ${colors.blue300};
      color: ${colors.white};
      &:hover{
      background: ${colors.blue400};
        color: ${colors.gray200};
      }
    }
    &.active{
      background: ${colors.blue400};
      color: ${colors.white};
      &:hover{
        color: ${props.activeWhiteTxtColor ? colors.white : colors.gray200};
      }
    }
    &.active-on-hover:hover {
      background: ${colors.blue400};
      color: ${colors.white};
    }

    &.disabled, &:disabled {
      cursor: auto;
      box-shadow: none;
      pointer-events: none;
      &:hover{
        background: ${colors.gray100};
        color: ${colors.blue300};
      }
    }

    &.inactive {
      cursor: auto !important;
      box-shadow: none;
      background: ${colors.gray100};
      color: ${colors.blue300};
      &:hover{
        background: ${colors.gray100};
        color: ${colors.blue300};
      }
    }
  `}
`;

export const PrimaryButtonLink = styled.a`
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2.5rem;
  border-radius: 30px;
  font-weight: bold;
`;

export const TransparentButton = styled.button`
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2.5rem;
  border-radius: 30px;
  font-weight: bold;
  background: none;
  border: none;
  color: ${colors.gray400};

  &:disabled {
    color: ${colors.gray200};
  }
`;

import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CHILDREN_STATUS_MAP, ChipStatusToggle } from '../ChipStatus';
import { EmptyState, EmptyStateCenterContainer } from '../EmptyState';
import { getUserDomain, hasPermissionForAssignedTask } from '../utils';

export const SubTasksList = ({ visible, task, controller, appContext }) => {
  const { hasPermission, appState } = appContext;
  const user = appState.get('user');
  const subTasks = task.get('children');
  const isEmpty = subTasks.size === 0;
  const { isBuilder } = getUserDomain(user);

  const canUpdateTask = React.useMemo(() => {
    // user cannot update status
    if (hasPermission('task_update_status', 'no')) return false;

    // all builder users can update the task status
    if (isBuilder) return true;

    // trade users can update only if the task is assigned to their account
    if (task.getIn(['assigneeAccount', 'companyId']) !== user.get('accountId')) return false;

    // and they have permission
    const userId = appState.getIn(['user', '_id']);
    return hasPermissionForAssignedTask('task_update_status', hasPermission, task, userId);
  }, [user, hasPermission]);

  const [customSubTasks, regularSubTasks] = React.useMemo(() => {
    const result = subTasks.groupBy(subTask => (subTask.get('accountId') ? 'isCustom' : 'isRegular'));
    return [result.get('isCustom'), result.get('isRegular')];
  }, [subTasks]);

  if (!visible) return null;
  if (isEmpty)
    return (
      <div className="position-relative">
        <EmptyStateCenterContainer top="230px">
          <EmptyState
            icon="wrench"
            title="No Sub-Tasks"
            body="This Task does not have any Sub-Tasks added."
            className="mb-5 pb-5 pt-5"
          />
        </EmptyStateCenterContainer>
      </div>
    );

  const props = { task, controller, canUpdateTask };

  return (
    <Container className="my-4">
      {regularSubTasks?.map((subTask, index) => (
        <SubTaskItem key={`child-${index}`} subTask={subTask} {...props} />
      ))}
      <div className={cn('mt-5', { 'd-none': !customSubTasks?.size })}>
        <span className={cn('font-weight-bold font-size-16')}>Custom Sub-Tasks</span>
      </div>
      {customSubTasks?.map((subTask, index) => (
        <SubTaskItem key={`custom-${index}`} subTask={subTask} {...props} />
      ))}
    </Container>
  );
};

const SubTaskItem = ({ task, subTask, canUpdateTask, controller }) => {
  const status = subTask.get('status');
  const isCompleted = status === 'completed';
  const isCustom = !!subTask.get('accountId');
  const label = canUpdateTask || isCompleted ? CHILDREN_STATUS_MAP[status].label : 'Not Started';
  return (
    <div
      className="d-flex justify-content-between align-items-end pb-3 pt-2 align-items-center"
      style={{ borderBottom: '1px solid #ededed' }}
    >
      <span className="font-size-14 text-gray-800 pt-2">
        {subTask.get('name')}
        {isCustom && <FontAwesomeIcon icon="house-chimney-user" className="ml-3 text-muted" />}
      </span>
      <ChipStatusToggle
        disabled={!canUpdateTask}
        showIcon={false}
        status={CHILDREN_STATUS_MAP[status].status}
        onClick={() => controller.updateSubTaskStatus(task, subTask)}
      >
        {label}
      </ChipStatusToggle>
    </div>
  );
};

export const Container = styled.div`
  max-width: 640px;
  border-bottom: 1px solid #ededed;
  margin-left: 2.5rem;

  .mini-chipstatus.trx-chipstatus {
    margin-top: 1rem;
  }
`;

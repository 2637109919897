import React from 'react';
import { SystemNotification } from '../SystemNotification';

const reg = /^(.*)((?:\s(?:is\srequesting)\s.*)(?:\s(?:date\sof)\s))(.*)(\stasks)?$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, null, message, null, null];
};

export default ({ notification, controller }) => {
  const [, builderAccountName, message, taskName, plural] = parseNotification(notification.get('message'));
  const url = plural ? '/to-do' : new URL(notification.getIn(['data', 'tasksLink'])).pathname;

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={url}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {builderAccountName && <span className="text-dark">{builderAccountName}</span>}
      {message}
      {taskName && (
        <span className="text-dark">
          {taskName}
          {plural && ` ${plural}`}
        </span>
      )}
    </SystemNotification>
  );
};

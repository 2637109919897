import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useDuration } from './useDuration';

export const NoticeWindowInput = ({ value, disabled, updateDuration, children, size = 'sm' }) => {
  const { duration, errors, Feedback, ...handlers } = useDuration(updateDuration, value);

  return (
    <>
      <StyledInput
        className="field-editable edit-durationDays"
        name="duration"
        type="number"
        size={size}
        value={duration}
        disabled={disabled}
        // isInvalid={errors.duration}
        {...handlers}
      />
      {!!children && children({ duration })}
      {/* <Feedback /> */}
    </>
  );
};

const StyledInput = styled(Form.Control)`
  max-width: 4rem;
  padding-right: 0.5rem !important;
`;

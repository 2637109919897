import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from '../theme';

const DEPENDENCIES = {
  FS: 'Finish to Start',
  SS: 'Start to Start',
  FF: 'Finish to Finish',
  SF: 'Start to Finish',
};

export const ChipStatusToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    className="form-control form-control-lg d-flex flex-row justify-content-between align-items-center pr-2"
  >
    {children}
    <FontAwesomeIcon icon="chevron-down" className="mr-1 text-gray-400" />
  </button>
));

export const DependencySelect = ({ value, ...props }) => (
  <Dropdown {...props}>
    <Dropdown.Toggle as={ChipStatusToggle}>{DEPENDENCIES[value]}</Dropdown.Toggle>
    <DependencySelectMenu>
      <Dropdown.Item eventKey="FS" active={value === 'FS'}>
        Finish to Start
      </Dropdown.Item>
      <Dropdown.Item eventKey="SS" active={value === 'SS'}>
        Start to Start
      </Dropdown.Item>
      <Dropdown.Item eventKey="FF" active={value === 'FF'}>
        Finish to Finish
      </Dropdown.Item>
      <Dropdown.Item eventKey="SF" active={value === 'SF'}>
        Start to Finish
      </Dropdown.Item>
    </DependencySelectMenu>
  </Dropdown>
);

const DependencySelectMenu = styled(Dropdown.Menu)`
  .dropdown-item {
    color: ${colors.gray800};

    &.active {
      background: ${colors.blue100};
      color: ${colors.blue400};
    }
    &:hover,
    &:active,
    &:focus {
      background: ${colors.blue100};
    }
  }
`;

import React from 'react';
import { Map, Stack } from 'immutable';
import { useController } from '../useController';
import * as actions from './DialogActions';

export const emptyValue = Map({
  show: false,
  resolve: value => Promise.resolve(value),
  reject: value => Promise.reject(value),
});

export const emptyState = new Stack([emptyValue]);

export const DialogController = () => {
  const [state, controller] = useController(actions, emptyState);

  return React.useMemo(() => {
    const current = state.peek();
    const show = current.get('show');
    const Component = current.get('Component');
    const props = current.get('props');

    return { Component, props, show, ...controller };
  }, [state, controller]);
};

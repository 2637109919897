import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';

export const STATUS = ['normal', 'green', 'green-outline', 'red', 'blue', 'incomplete'];

export const STATUS_MAP = {
  'not-started': {
    label: 'Not Started',
    status: 'normal',
  },
  'checked-in': {
    label: 'Checked-In',
    status: 'blue',
  },
  'in-progress': {
    label: 'In Progress',
    status: 'green-outline',
  },
  completed: {
    label: 'Completed',
    status: 'green',
  },
  'not-ready-to-start': {
    label: 'Not Ready',
    status: 'red',
  },
};

export const CHILDREN_STATUS_MAP = {
  'not-started': {
    label: 'Mark As Completed',
    status: 'normal-outline',
  },
  completed: {
    label: 'Completed',
    status: 'green',
  },
};

export const defaultProps = {
  status: 'normal',
};

export const propTypes = {
  status: PropTypes.oneOf(STATUS),
};

export const ChipStatusClassName = (className = '', status = '') =>
  cn(`${className} trx-chipstatus`, {
    normal: status === 'normal',
    'normal outline': status === 'normal-outline',
    green: status === 'green',
    'green outline': status === 'green-outline',
    red: status === 'red',
    blue: status === 'blue',
    yellow: status === 'incomplete',
  });

export const ChipStatus = ({ className, children, status = 'normal', label = '', innerRef, ...props }) => (
  <span className={ChipStatusClassName(className, status)} ref={innerRef} {...props}>
    {label}
    {children}
  </span>
);

export const ChipStatusWithOverlay = React.forwardRef((props, innerRef) => (
  <ChipStatus innerRef={innerRef} {...props} />
));

ChipStatus.defaultProps = defaultProps;
ChipStatus.propTypes = propTypes;

export const ChipStatusToggle = React.forwardRef(({ status, children, onClick, disabled, showIcon = true }, ref) => {
  const className = showIcon ? '' : 'mini-chipstatus';
  if (disabled)
    return (
      <span ref={ref} className={ChipStatusClassName(className, status)} data-testid="status-disabled" disabled>
        {children}
      </span>
    );
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={ChipStatusClassName(`dropdown ${className}`, status)}
      data-testid="status-button"
    >
      {children}
      {showIcon && <FontAwesomeIcon icon="chevron-down" className="ml-1" />}
    </button>
  );
});

ChipStatusToggle.propTypes = propTypes;
ChipStatusToggle.defaultProps = defaultProps;

export const ChipStatusMenu = styled(Dropdown.Menu)`
  ${props => `
  a.dropdown-item{
    color: ${colors.gray400};
    text-align: ${props.textalign || 'center'};

    &:hover,
    &:active,
    &:focus {
      color: ${colors.blue300} !important;
      background: ${colors.blue100} !important;
    }
  }
  `}
`;

export const ChipStatusDropdown = ({
  label,
  status,
  children,
  progress = false,
  completed = false,
  notStarted = false,
  disabled = false,
  ...props
}) => (
  <Dropdown {...props}>
    <Dropdown.Toggle as={ChipStatusToggle} status={status} disabled={disabled}>
      {label}
    </Dropdown.Toggle>
    <ChipStatusMenu className="text-uppercase">
      {notStarted && <Dropdown.Item eventKey="not-started">Not Started</Dropdown.Item>}
      {progress && <Dropdown.Item eventKey="in-progress">In Progress</Dropdown.Item>}
      {completed && <Dropdown.Item eventKey="completed">Completed</Dropdown.Item>}
      {children}
    </ChipStatusMenu>
  </Dropdown>
);

ChipStatusDropdown.Item = Dropdown.Item;

export const TargetDate = ({ title, date }) => (
  <div className="d-flex flex-column mr-5 w-8rem">
    <small className="text-muted">{title}</small>
    <span>{date}</span>
  </div>
);

import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { plural, preventCharsInNumbers } from '@tradetrax/web-common/lib/utils';

export { NoticeWindowInput } from './NoticeWindowInput';

const DurationField = styled(Form.Control)`
  width: 115px;
  background: none !important;
  padding-right: 0 !important;
`;

const DurationInput = ({ duration, register = () => {}, errors, handleChangeDuration, ...props }) => {
  const durationRef = React.useRef();
  const [showHelpText, setShowHelpText] = React.useState(false);
  React.useEffect(() => {
    const durationInput = durationRef.current;
    const onKeyPress = e => preventCharsInNumbers(e);

    durationInput.addEventListener('keydown', onKeyPress);

    return () => {
      durationInput.removeEventListener('keydown', onKeyPress);
    };
  }, [durationRef]);

  const handleOnChange = (value, maxLength) => {
    const newValue = maxLengthAllowed(value, maxLength);
    handleChangeDuration && handleChangeDuration(newValue);
    return newValue;
  };

  const maxLengthAllowed = (value, maxLength) => (value.length < maxLength ? value : value.slice(0, maxLength));

  return (
    <div className="position-relative d-flex flex-column">
      <div className="position-relative d-flex flex-row align-items-center">
        <DurationField
          size="lg"
          name="duration"
          type="number"
          ref={e => {
            register(e);
            durationRef.current = e;
          }}
          placeholder="0"
          min="0"
          max="999"
          isInvalid={errors.duration}
          {...props}
          onChange={e => (e.target.value = handleOnChange(e.target.value, 3))}
          onFocus={() => setShowHelpText(!showHelpText)}
          onBlur={() => setShowHelpText(!showHelpText)}
        />
        <span className="font-size-14 ml-1">{plural(parseInt(duration), 'Day', 'Days')}</span>
      </div>
      {showHelpText && !errors.duration && <span className=" font-size-14 text-muted">Max. 999</span>}
    </div>
  );
};

export default DurationInput;

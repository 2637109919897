export const OPEN_TASKS_FILTER = 'openTasks';
export const UPCOMING_TASKS_FILTER = 'upcomingTasks';
export const TO_DO_TASKS_FILTER = 'todoTasks';
export const UPDATE_REQUESTS_AND_OVERDUE_TASKS_FILTER = 'updateRequestsAndOverdueTasks';
export const RECENT_ACTIVITY_FILTER = 'recentActivity';
export const TEMPLATES_FILTER = 'templates';
export const USERS_FILTER = 'users';
export const HOME_OWNERS_FILTER = 'homeowners';
export const JOB_FILTER = 'jobs';
export const INTAKE_BY_USER_FILTER = 'intake';
export const INTAKE_BY_INSTALLER_FILTER = 'intake';
export const OUTTAKE_BY_ACCOUNT_FILTER = 'outtakeByAccount';
export const OUTTAKE_BY_USER_FILTER = 'outtakeByUser';

export const FILTER_NAME = {
  OPEN_TASKS: OPEN_TASKS_FILTER,
  TO_DO: TO_DO_TASKS_FILTER,
  UR_OVERDUE: UPDATE_REQUESTS_AND_OVERDUE_TASKS_FILTER,
  UPCOMING: UPCOMING_TASKS_FILTER,
  RECENT_ACTIVITY: RECENT_ACTIVITY_FILTER,
  TEMPLATES: TEMPLATES_FILTER,
  USERS: USERS_FILTER,
  HOME_OWNERS: HOME_OWNERS_FILTER,
  INTAKE_BY_USER: INTAKE_BY_USER_FILTER,
  INTAKE_BY_INSTALLER: INTAKE_BY_INSTALLER_FILTER,
  OUTTAKE_BY_ACCOUNT: OUTTAKE_BY_ACCOUNT_FILTER,
  OUTTAKE_BY_USER: OUTTAKE_BY_USER_FILTER,
  JOB: JOB_FILTER,
};

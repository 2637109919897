import { fromJS } from 'immutable';
import moment from 'moment';
import { FILTER_NAME } from './filter.name';

// changes
// isMyTaskOnly => myTasks
// taskTypes => taskNames
// community => communityIds
// accountAssignee => assigneeAccounts

const commonTaskEmptyFilterValues = {
  myTasks: false,
  taskNames: [],
  filterSetId: null, // current selected filter set Id.
};

const { hostname } = global.location;
const result = /^([^\.]+)((?:.*)?.tradetrax\.com)$/.exec(hostname);
if (result && result[1] === 'builder') {
  commonTaskEmptyFilterValues.assigneeAccounts = [];
}

export const taskEmptyFilterValues = fromJS({
  ...commonTaskEmptyFilterValues,
  communityIds: [],
  inProgressStatus: false,
  completedStatus: false,
  notStartedStatus: false,
  notReadyStatus: false,
});

export const recentActivityEmptyFilterValues = fromJS({
  'task-assigned': true,
  attachments: true,
  'update-request': true,
  notes: true,
  'filter-sets': true,
});

export const templatesEmptyFilterValues = fromJS({
  status: 'all',
});

export const usersEmptyFilterValues = fromJS({
  status: 'all',
});

export const homeOwnerEmptyFilterValues = fromJS({
  assigneeAccounts: [],
  communityIds: [],
  status: 'all',
});

export const emptyDate = {
  type: 'any',
  dateEqual: null,
  dateFrom: null,
  dateTo: null,
};

export const emptyDuration = {
  type: 'any',
  value: null,
};

export const jobEmptyFilterValues = fromJS({
  ...commonTaskEmptyFilterValues,
  // status
  inProgressStatus: false,
  completedStatus: false,
  notStartedStatus: false,
  notReadyStatus: false,
  // advanced filter options
  dateConfirmation: 'all',
  criticalTasks: 'all',
  startDate: emptyDate,
  finishDate: emptyDate,
  duration: emptyDuration,
});

export const intakeByUserEmptyFilterValues = fromJS({
  assigneeUserIds: [],
});

export const intakeByInstallerEmptyFilterValues = fromJS({
  assigneeUserIds: [],
});

export const outtakeByAccountEmptyFilterValues = fromJS({
  assigneeAccountIds: [],
});

export const outtakeByUserEmptyFilterValues = fromJS({
  assigneeUserIds: [],
});

const emptyFilter = fromJS({
  isInitialized: false, // when loading the first time it will need to restore from localstorage and fetch filter sets from BE. //deprecated
  isFiltering: false, // either a filter set is seleced or some criteria from the popup.
  hasChanges: false, // very convenient when having a filterSet selected to know if it has been edited.
  values: null, // current applied filter [user selected values|selected filter set values|both when editing over a selected filter set]
  filterSetType: null, // [jobSchedule|taskList|null]
  filterSets: [], // list of available filter sets. //deprecate.
  collapsed: {},
});

const todoSessionValues = fromJS({
  selectedDate: moment().format('YYYY-MM-DD'), // today'
});

export const taskEmptyFilter = emptyFilter.set('values', taskEmptyFilterValues).set('filterSetType', 'taskList');
export const todoEmptyFilter = taskEmptyFilter.set('sessionValues', todoSessionValues);
export const jobEmptyFilter = emptyFilter.set('values', jobEmptyFilterValues).set('filterSetType', 'jobSchedule');
export const recentActivityEmptyFilter = emptyFilter.set('values', recentActivityEmptyFilterValues);
export const templatesEmptyFilter = emptyFilter.set('values', templatesEmptyFilterValues);
export const usersEmptyFilter = emptyFilter.set('values', usersEmptyFilterValues);
export const homeOwnerEmptyFilter = emptyFilter.set('values', homeOwnerEmptyFilterValues);
export const intakeEmptyFilter = emptyFilter.set('values', intakeByUserEmptyFilterValues);
export const intakeByInstallerEmptyFilter = emptyFilter.set('values', intakeByInstallerEmptyFilterValues);
export const outtakeByAccountEmptyFilter = emptyFilter.set('values', outtakeByAccountEmptyFilterValues);
export const outtakeByUserEmptyFilter = emptyFilter.set('values', outtakeByUserEmptyFilterValues);

export const EMPTY_FILTER = {
  OPEN_TASKS: taskEmptyFilter,
  TO_DO: todoEmptyFilter,
  UR_OVERDUE: taskEmptyFilter,
  UPCOMING: taskEmptyFilter,
  JOB: jobEmptyFilter,
  RECENT_ACTIVITY: recentActivityEmptyFilter,
  TEMPLATES: templatesEmptyFilter,
  USERS: usersEmptyFilter,
  HOME_OWNERS: homeOwnerEmptyFilter,
  INTAKE_BY_USER: intakeEmptyFilter,
  INTAKE_BY_INSTALLER: intakeByInstallerEmptyFilter,
  OUTTAKE_BY_ACCOUNT: outtakeByAccountEmptyFilter,
  OUTTAKE_BY_USER: outtakeByUserEmptyFilter,
};

export const FILTER_NAME_BY_KEY = {
  [FILTER_NAME.OPEN_TASKS]: taskEmptyFilter,
  [FILTER_NAME.TO_DO]: todoEmptyFilter,
  [FILTER_NAME.UR_OVERDUE]: taskEmptyFilter,
  [FILTER_NAME.UPCOMING]: taskEmptyFilter,
  [FILTER_NAME.JOB]: jobEmptyFilter,
  [FILTER_NAME.RECENT_ACTIVITY]: recentActivityEmptyFilter,
  [FILTER_NAME.TEMPLATES]: templatesEmptyFilter,
  [FILTER_NAME.USERS]: usersEmptyFilter,
  [FILTER_NAME.HOME_OWNERS]: homeOwnerEmptyFilter,
  [FILTER_NAME.INTAKE_BY_USER]: intakeEmptyFilter,
  [FILTER_NAME.INTAKE_BY_INSTALLER]: intakeByInstallerEmptyFilter,
  [FILTER_NAME.OUTTAKE_BY_ACCOUNT]: outtakeByAccountEmptyFilter,
  [FILTER_NAME.OUTTAKE_BY_USER]: outtakeByUserEmptyFilter,
};

import React from 'react';
import { AlbumController, getCanRemoveAndDownload } from '../AlbumController';
import { ListView } from './ListView';
import { GridView } from './GridView';
import { IconButton } from '../../Button';
import { ToggleButtons, ToggleButton } from '../../ToggleButtons';
import { CarouselOverlay } from '../Carousel/CarouselOverlay';
import { EmptyState, EmptyStateCenterContainer } from '../../EmptyState';
import { getUserDomain } from '../../utils';

const icons = {
  grid: 'list',
  list: 'grid',
};

export const Attachments = React.forwardRef(({ service, setAttachmentsCount, task, job, ...rest }, ref) => {
  const { visible, showAddAttachment, appContext } = rest;
  const user = appContext.appState.get('user');
  const context = AlbumController(task, job, service, appContext);
  const { controller, state } = context;
  const { attachments, isCarouselOpen, currentPhotoIndex, view, activeFilter } = state.toObject();
  const showAdd = showAddAttachment && activeFilter !== 'on-job'; // works for builder because Add button is not on <AttachmentsView />
  const hasAttachments = !!attachments.size;
  const hasPermission = getUserPermission(user, task, showAdd);
  const newView = rest.view || view;
  const isListView = newView === 'list';
  const AttachmentsView = isListView ? ListView : GridView;
  const isTask = !!task && !job;
  const message = getEmptyStateMessage(activeFilter, isTask);
  const emptyStateMessage = hasPermission
    ? `When you add an Attachment${message}, it will appear here.`
    : `When an Attachment is added${message}, it will appear here.`;

  if (ref) {
    ref.current = React.useMemo(
      () => ({
        addAttachment: controller.addAttachment,
        toggleView: controller.toggleView,
      }),
      [controller]
    );
  }
  React.useEffect(() => {
    const isLoading = attachments.get('isLoading');
    setAttachmentsCount(isLoading ? 0 : attachments.size);
  }, [setAttachmentsCount, attachments]);

  if (!visible) return null;
  if (!hasAttachments)
    return (
      <div className="position-relative">
        {isTask && (
          <FilterOptions controller={controller} activeFilter={activeFilter} view={icons[view]} showAdd={showAdd} />
        )}
        <EmptyStateCenterContainer top="230px">
          <EmptyState icon="paperclip" title="No Attachments Added" body={emptyStateMessage} className="" />
        </EmptyStateCenterContainer>
      </div>
    );

  const props = { user, isTask, task, getCanRemoveAndDownload: getCanRemoveAndDownload(user, task) };
  return (
    <>
      {isTask && (
        <FilterOptions controller={controller} activeFilter={activeFilter} view={icons[view]} showAdd={showAdd} />
      )}
      <AttachmentsView {...props} context={context} appContext={appContext} />
      <CarouselOverlay
        {...props}
        photos={attachments}
        defaultIndex={currentPhotoIndex}
        show={isCarouselOpen}
        onHide={controller.hideCarousel}
        onRemove={controller.removeAttachment}
        onDownload={controller.downloadAttachment}
      />
    </>
  );
});

const getEmptyStateMessage = (activeFilter, isTask) => {
  if (isTask) {
    if (activeFilter === 'all') return '';
    if (activeFilter === 'on-task') return ' to this Task';
  }
  return ' to this Job';
};

const getUserPermission = (user, task, showAdd) => {
  const { isBuilder, isTrade } = getUserDomain(user);
  const isSelfAccountAssigned = user.get('accountId') === task?.getIn(['assigneeAccount', 'companyId']);
  return isBuilder || (showAdd && (isSelfAccountAssigned || (isTrade && !!task)));
};

const FilterOptions = ({ controller, activeFilter, view, showAdd }) => {
  const showCaption = activeFilter !== 'on-job';
  return (
    <div data-testid="panel-filter-attachments" className="d-flex flex-row justify-content-between mr-4">
      <ToggleButtons
        type="radio"
        name="option"
        value={activeFilter}
        onChange={controller.setActiveFilter}
        className="sub-section my-3 ml-4 pl-3"
      >
        <ToggleButton value="all">All</ToggleButton>
        <ToggleButton value="on-task">On this Task</ToggleButton>
        <ToggleButton value="on-job">On the Job</ToggleButton>
      </ToggleButtons>
      <div className="d-flex flex-row">
        {showAdd ? (
          <IconButton
            data-testid="btn-add-attachment"
            icon="plus"
            className="active"
            onClick={() => controller.addAttachment(showCaption)}
          />
        ) : null}
        <IconButton
          icon={view}
          className="ml-2 mr-2"
          onClick={() => {
            controller.toggleView('attachments');
          }}
        />
      </div>
    </div>
  );
};

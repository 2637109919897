import React from 'react';
import PDFObject from 'pdfobject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';

const PDF_OPTIONS = {
  width: '100%',
  height: '100%',
  pdfOpenParams: { view: 'FitW' },
};

// const dateRegex = /^([a-zA-z]{3})(?:.*\s)(\d)+$/;

export function DocViewer({ doc, setOpenDoc }) {
  const previewRef = React.useRef();
  const documentUrl = doc.get('url');
  React.useEffect(() => {
    if (documentUrl) {
      PDFObject.embed(documentUrl, previewRef.current, PDF_OPTIONS);
    }
  }, [previewRef, documentUrl]);

  return (
    <>
      <DocHeader doc={doc} setOpenDoc={setOpenDoc} />
      <div className="h-100 d-flex justify-content-center" ref={previewRef} />
    </>
  );
}

const DocHeader = ({ doc, setOpenDoc }) => (
  <div className="d-flex flex-row justify-content-between mb-3 w-100">
    <button className="btn btn-link text-muted mr-3 mt-n3" onClick={() => setOpenDoc(null)}>
      <FontAwesomeIcon icon="arrow-left" />
    </button>
    <div className="d-flex flex-column ml-1 w-100">
      <div className="font-weight-bold text-truncate w-75">{doc.get('name')}</div>
      <span className="font-size-14" style={{ color: colors.gray400 }}>
        {`By ${doc.get('createdBy')} `}
        <span>{`· ${doc.get('title')}`}</span>
      </span>
    </div>
  </div>
);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from '../lib';

const StyledEmptyState = styled.span`
  ${props => `
    background-color: ${props.backgroundcolor || '#cddcf3'};
    border-radius: 50%;
    height: 69px;
    width: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `}
`;

const StyledIcon = styled(FontAwesomeIcon)`
  background-color: #cddcf3;
  color: #fff;
  font-size: 40px;
`;

export const EmptyState = ({
  icon,
  title,
  body,
  mt = 5,
  pt = 0,
  className = '',
  backgroundColor,
  visible = true,
  ...props
}) => {
  const testid = props['data-testid'] || 'empty-state';
  return visible ? (
    <div data-testid={testid} className={`d-flex flex-row justify-content-around mt-${mt} pt-${pt} ${className}`}>
      <div className="d-flex flex-column align-items-center " style={{ width: '415px' }}>
        <StyledEmptyState className="mb-2" backgroundcolor={backgroundColor}>
          {icon ? <StyledIcon icon={icon} /> : <CommentAltExclamationIcon />}
        </StyledEmptyState>
        <h4 className="font-weight-bold mb-3 mt-3 text-center" style={{ color: colors.gray400 }}>
          {title}
        </h4>
        <div className="text-muted mt-1 px-5 text-center" style={{ color: colors.gray400 }}>
          {body}
        </div>
      </div>
    </div>
  ) : null;
};

export const NotFound = () => (
  <EmptyStateCenterContainer>
    <EmptyState title="Page Not Found" body="The page you are looking for cannot be found." backgroundColor="#FC5C4F" />
  </EmptyStateCenterContainer>
);

export const NotPermission = () => (
  <EmptyState
    icon="lock"
    title="Permission Required"
    body="Contact your administrator to ask for the necessary permission."
    pt={5}
  />
);

const CommentAltExclamationIcon = () => (
  <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M51.025 15.775H19.975C17.0641 15.775 14.8 18.1199 14.8 20.95V44.2375C14.8 47.1484 17.0641 49.4125 19.975 49.4125H27.7375V56.2047C27.7375 57.0133 28.627 57.4984 29.2738 57.0133L39.3813 49.4125H51.025C53.8551 49.4125 56.2 47.1484 56.2 44.2375V20.95C56.2 18.1199 53.8551 15.775 51.025 15.775ZM35.5 42.9437C34.0445 42.9437 32.9125 41.8117 32.9125 40.3562C32.9125 38.9816 34.0445 37.7687 35.5 37.7687C36.8746 37.7687 38.0875 38.9816 38.0875 40.3562C38.0875 41.8117 36.8746 42.9437 35.5 42.9437ZM37.5215 34.0492C37.4406 34.6961 36.8746 35.1812 36.2277 35.1812H34.6914C34.0445 35.1812 33.4785 34.6961 33.3977 34.0492L32.3465 23.6992C32.2656 22.9715 32.9125 22.2437 33.6402 22.2437H37.2789C38.0066 22.2437 38.6535 22.9715 38.5727 23.6992L37.5215 34.0492Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="69" height="69" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EmptyStateCenterContainer = styled.div`
  ${props => `
  top: calc(50vh - ${props.top || '70px'} );
 `}
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;

import { fromJS, isList } from 'immutable';
import { isBoolean, isArray } from 'lodash';
import moment from 'moment';

export const resetFilter = (values, isFiltering = true, hasChanges = false) => {
  return filterState => filterState.merge({ isFiltering, hasChanges, values });
};

export const getActiveFilterset = ({ filterState, filterSets }) => {
  if (!filterSets) return null;

  const activeFilterSetId = filterState.getIn(['values', 'filterSetId']);
  if (!activeFilterSetId) return null; // there is no active filter

  return filterSets.find(fs => fs.get('_id') === activeFilterSetId);
};

const STATUS_FIELDS = ['status', 'dateConfirmation', 'criticalTasks'];
const TYPE_FIELDS = ['startDate', 'finishDate', 'duration'];

const isFieldFiltering = emptyValues => (value, field) => {
  // there is a selected filter set, but it might not have any filter criteria (be empty)
  if (field === 'filterSetId') return false;
  // if the field is a collection (like communities/tasks/etc)
  if (isList(value)) return value.size > 0;
  // if the field is a collection (like communities/tasks/etc)
  if (isArray(value)) return value.length > 0;
  // We compare boolean fields with original values.
  if (isBoolean(value)) return value !== emptyValues.get(field);
  // We compare status fields with original values.
  if (STATUS_FIELDS.includes(field)) return value !== emptyValues.get(field);
  // We compare type fields with original values.
  if (TYPE_FIELDS.includes(field)) return value.get('type') !== emptyValues.getIn([field, 'type']);

  // TODO: check for objects/arrays/etc, at this point not needed.
  // TODO: check por advanced filter options.
  return !!value;
};

export const isfiltering = (emptyValues, values) => values.some(isFieldFiltering(emptyValues));

// @param {fields} is an array of field names that should be found on values Map.
export const countFilteringFields = (emptyValues, values, fields) => {
  const test = isFieldFiltering(emptyValues);
  return fields.map(field => test(values.get(field), field)).filter(value => value).length;
};

const defaultOmissions = ['filterSetId', 'type'];

const fieldComparers = {
  myTasks: booleanComparer,
  taskNames: arrayComparer,
  assigneeAccounts: arrayComparer,
  communityIds: arrayComparer,
  startDate: dateComparer,
  finishDate: dateComparer,
  duration: durationComparer,
};

// TODO: we need to standarize filter values to be always immutable instances.
export const hasChanges = filterSets => filterState => {
  const active = getActiveFilterset({ filterState, filterSets });
  if (!active) return filterState; // if there is no selected/applied filer set, then return the same state.

  const filterValues = filterState.get('values');
  const filterSetValues = active.get('filter');
  const keys = filterValues
    .keySeq()
    .filter(key => !defaultOmissions.includes(key))
    .toArray();

  for (var i = 0; i < keys.length; i += 1) {
    const field = keys[i];
    const filterValue = filterValues.get(field);
    const filterSetValue = filterSetValues.get(field);
    const comparer = fieldComparers[field] || defaultComparer;

    // TODO: prefer the use of  immutable over plain JS objects.
    if (!comparer(filterSetValue, fromJS(filterValue))) {
      return filterState.set('hasChanges', true);
    }
  }

  // current applied filter and filter set are equals.
  return filterState.set('hasChanges', false);
};

// in case the field doesn't have a registered comparer
function defaultComparer(filterSetValue, filterValue) {
  if (typeof filterSetValue === typeof filterValue) return filterSetValue === filterValue;

  return true;
}

function durationComparer(filterSetValue, filterValue) {
  const type = filterSetValue.get('type');
  if (type !== filterValue.get('type')) return false;

  if (type === 'any') return true;

  return filterSetValue.get('value') === filterValue.get('value');
}

function dateComparer(filterSetValue, filterValue) {
  const type = filterSetValue.get('type');
  if (type !== filterValue.get('type')) return false;

  if (type === 'any') return true;
  if (type === 'missed') return true;

  if (type === 'equal') return moment(filterSetValue.get('dateEqual')).isSame(filterValue.get('dateEqual'), 'day');
  if (type === 'from')
    return (
      moment(filterSetValue.get('dateFrom')).isSame(filterValue.get('dateFrom'), 'day') &&
      moment(filterSetValue.get('dateTo')).isSame(filterValue.get('dateTo'), 'day')
    );

  return true; // code should never get here, but, who knows :)
}

function booleanComparer(filterSetValue, filterValue) {
  return filterSetValue === filterValue;
}

// TODO: normalize to use always immutable
// at this point
// - filterSetValue is an immutable Lits instance.
// - filterValue is a native array instance.
function arrayFieldComparer(field) {
  return function(filterSetValue, filterValue) {
    if (filterSetValue.size !== filterValue.size) return false;

    for (var i = 0; i < filterSetValue.size; i += 1) {
      if (i >= filterValue.size) return false; // no more values to compare.
      if (filterSetValue.getIn([i, field]) !== filterValue.getIn([i, field])) return false; // arrays are different
    }

    return true; // arrays are the same
  };
}

function arrayComparer(filterSetValue, filterValue) {
  if (filterSetValue.size !== filterValue.size) return false;

  for (var i = 0; i < filterSetValue.size; i += 1) {
    if (i >= filterValue.size) return false; // no more values to compare.
    if (filterSetValue.get(i) !== filterValue.get(i)) return false; // arrays are different
  }

  return true; // arrays are the same
}

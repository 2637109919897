import React from 'react';
import ReactDOM from 'react-dom';
import { LandingPage } from '@tradetrax/web-common';
import { getDeviceType } from '@tradetrax/web-common/lib/utils';
import * as serviceWorker from './serviceWorker';
import '@tradetrax/web-common/scss/_tradetrax.scss';

const device = getDeviceType({ navigator });
document.cookie = 'userType=installer;domain=tradetrax.com';
ReactDOM.render(
  <React.StrictMode>
    <LandingPage device={device} isInstaller />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

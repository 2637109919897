import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import { Form } from 'react-bootstrap';
import { colors } from '../../theme';
import { FilterTypeahead } from './FilterTypeahead';
import { emptyFilter } from './index';

const TextButton = styled.p`
  color: hsla(209, 99%, 47%, 1);
  cursor: pointer;
`;

const Wrapper = styled.div`
  ${props => `
    position: absolute;
    right: 0;
    top: 44px;
    transition: right 600ms ease;
    mix-blend-mode: normal;
    z-index:1000;
    &.hidden {
      pointer-events: none;
    }

    .settings-container {
      background: ${colors.pearl};
      border-radius: 10px;
      padding: 1.75rem;
      width: 450px;
      border-top: solid 10px ${colors.blue300};
      position: absolute;
      top: 5px;
      right:0;
      @media (max-width: 1670px) {
        right:${props.floatToRight ? '-410px' : '0px'};
      }
      box-shadow: 0px 1px 2px rgb(51 51 51 / 20%);
      height: auto;

      &.hidden {
        display: none;
      }

      .rbt ul.dropdown-menu {
        max-height: 175px !important;
      }
    }

    .secondary-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  `}
`;

const FilterSwitch = ({ text, children }) => (
  <div className="d-flex justify-content-between py-4 border-top">
    <span>{text}</span>
    {children}
  </div>
);

export const Filter = ({
  formContext,
  className,
  controller,
  communities,
  taskTypes,
  assignees = [],
  id = '',
  isFilterSet,
  top,
  title = 'Task Filter',
  applyFilters,
  floatToRight,
  children,
  filterSetsContext,
}) => {
  const onApplyFilters = applyFilters || controller.applyFilters;
  const { register, handleSubmit, reset } = formContext;
  const filterSetId = filterSetsContext?.activeFilterSet?.get('_id');
  const onChange = () => handleSubmit(onApplyFilters)();
  const clearFilter = () => {
    reset(emptyFilter.toJS());
    onApplyFilters(emptyFilter.toJS(), filterSetId);
    filterSetsContext?.controller.clearFilterSet(null);
  };
  const hasAssignees = fromJS(assignees).length > 0;

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isFilterSet });
    }
    return child;
  });

  return (
    <Wrapper className={cn(className)} id={id} top={top} floatToRight={floatToRight}>
      <Form onSubmit={handleSubmit(onApplyFilters)}>
        <div className="d-flex settings-container flex-column justify-content-start">
          <div className="d-flex justify-content-between">
            <h5 className="mb-4 font-weight-bold" style={{ width: '250px' }}>
              {title}
            </h5>
            {isFilterSet ? (
              <TextButton id="clear-filters" onClick={clearFilter}>
                Clear all filters
              </TextButton>
            ) : null}
          </div>
          {childrenWithProps}
          <FilterSwitch text="My Tasks Only">
            <Form.Check
              className="mr-1"
              id="my-tasks-only"
              type="switch"
              label=""
              name="isMyTasksOnly"
              ref={register}
              onChange={onChange}
            />
          </FilterSwitch>
          <FilterTypeahead
            formContext={formContext}
            name="taskTypes"
            options={taskTypes}
            text="Task Type"
            onChange={onChange}
          />
          {hasAssignees && (
            <FilterTypeahead
              formContext={formContext}
              id="account-assignee"
              name="accountAssignee"
              options={fromJS(assignees)}
              text="Account Assignee"
              onChange={onChange}
            />
          )}
          <FilterTypeahead
            formContext={formContext}
            name="community"
            options={communities}
            text="Community"
            onChange={onChange}
          />
        </div>
      </Form>
    </Wrapper>
  );
};

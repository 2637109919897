import { useState } from 'react';
import { fromJS } from 'immutable';

const emptyState = fromJS([]);
const K = x => x;

export const Bind = (loader, transform = K) => {
  const [state, setState] = useState(emptyState);
  const getState = () => {
    if (state === emptyState) {
      setTimeout(() => {
        loader()
          .then(fromJS)
          .then(transform)
          .then(setState)
          .catch(err => console.error(err));
      });
    }

    return state;
  };

  return [getState, setState];
};

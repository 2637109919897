import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrxTooltip as Tooltip } from '../../../Tooltip';
import { PrimaryButton } from '../../../Button';
import { colors } from '../../../theme';
import { FilterNameEditor } from '../FilterNameEditor';
import { trimSpaces } from '../../../utils';
import { PopoverOnButton } from '../../../Popover';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';

const filterNameSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(2, 'Min. 2 characters')
    .max(30, 'Max. 30 characters'),
});

export const FilterListView = ({ accept, state, isDisabled, isTrade, controller }) => {
  const { filterSets, lastSharedIndex, isManager } = state.toObject();
  const location = useLocation();
  const currentPath = location.pathname;
  const tasksListRoutes = ['/tasks', '/upcoming', '/to-do', '/task/open'];
  const isTasksListRoute = tasksListRoutes.some(route => currentPath.includes(route));

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    controller.reorderFilterSets(result.source.index, result.destination.index);
  }
  const updateFilterName = (filter, value) => controller.updateFilterName(filter, value);
  const removeFilter = filter => controller.removeFilter(filter);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Manage Filter Sets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={cn('d-flex justify-content-center flex-column mb-4 pb-3')}>
          {`Rename or remove Filter Sets and drag and drop each row to change the display order. These changes will be
          applied across all your ${isTasksListRoute ? 'Tasks lists' : 'Job Schedules'}.`}
        </div>
        <DragDropContext onDragEnd={onDragEnd} isDisabled>
          <Droppable droppableId="list">
            {provided => (
              <FilterList ref={provided.innerRef} {...provided.droppableProps}>
                {filterSets.map((filter, index) => (
                  <FilterItem
                    filter={filter}
                    remove={removeFilter}
                    updateFilterName={updateFilterName}
                    index={index}
                    key={filter.get('_id')}
                    filterSets={filterSets}
                    isLastShared={index === lastSharedIndex}
                    isManager={isManager}
                    isTrade={isTrade}
                    controller={controller}
                  />
                ))}
                {provided.placeholder}
              </FilterList>
            )}
          </Droppable>
        </DragDropContext>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton disabled={isDisabled} onClick={() => accept({ ordered: filterSets })} className="mr-n2">
          Save
        </PrimaryButton>
      </Modal.Footer>
    </>
  );
};

const UnstyledFilterItem = ({
  filter,
  index,
  updateFilterName,
  remove,
  className,
  filterSets,
  controller,
  isLastShared = false,
  isManager,
  isTrade,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const isShared = filter.get('isShared');
  const sharedBy = filter.get('sharedBy');
  const toBeRemoved = !!filter.get('toBeRemoved');
  const removeRef = React.useRef();
  const renameRef = React.useRef();

  const RegularRow = ({ dragHandleProps }) => (
    <>
      <span className="item-drag" {...dragHandleProps}>
        <FontAwesomeIcon icon="grip-lines-vertical" className="mr-2" />
      </span>
      <span className="item-number">{index + 1}</span>
      <span className="mr-auto item-name">{filter.get('name')}</span>
      {isManager ? (
        <>
          {isShared && (
            <button
              onClick={() => controller.setEditView(filter)}
              className={cn('font-size-12 mr-3 btn btn-lnk text-secondary action')}
            >
              {isLastShared && <FontAwesomeIcon icon="circle-check" className="mr-3 text-success" />}
              <FontAwesomeIcon icon="user-group" className="mr-2" />
              Shared
            </button>
          )}
          <Tooltip text="Share" id="share-filter">
            <button onClick={() => controller.setShareView(filter)} className="btn btn-lnk text-secondary action ml-1">
              <FontAwesomeIcon icon="link-simple" />
            </button>
          </Tooltip>
        </>
      ) : (
        <>
          {sharedBy && (
            <span className="font-size-12 mr-3 text-secondary">
              <FontAwesomeIcon icon="user-group" className="mr-2" />
              Shared with me
            </span>
          )}
        </>
      )}
      <PopoverOnButton
        message={`By renaming this shared Filter Set, Users ${
          isTrade ? 'and/or Custom Roles' : ''
        } will see the new name.`}
        onConfirm={() => setIsEditing(true)}
        ignorePopover={!isShared}
        btnRef={renameRef}
        confirmText="Rename"
      >
        <Tooltip text="Rename" id="rename-filter">
          <button ref={renameRef} className="btn btn-lnk text-secondary action ml-1" disabled={!isManager && sharedBy}>
            <FontAwesomeIcon icon="pen" />
          </button>
        </Tooltip>
      </PopoverOnButton>
      <PopoverOnButton
        confirmText="Remove"
        message={'Once removed, all users will lose access to this Filter Set.'}
        onConfirm={() => remove(filter)}
        ignorePopover={!isShared}
        btnRef={removeRef}
      >
        <Tooltip text="Remove" id="remove-filter">
          <button ref={removeRef} className="btn btn-lnk text-secondary action ml-1" disabled={!isManager && sharedBy}>
            <FontAwesomeIcon icon="trash" />
          </button>
        </Tooltip>
      </PopoverOnButton>
    </>
  );

  const ToBeDeltedRow = ({ dragHandleProps }) => {
    return (
      <>
        <span className="item-span item-drag" {...dragHandleProps}></span>
        <span className="mr-auto item-name">{filter.get('name')}</span>
        <span>Click on "Save" to confirm removal</span>
      </>
    );
  };

  const props = {
    setIsEditing,
    index,
    value: filter.get('name'),
    field: 'name',
    schema: filterNameSchema,
    update: (field, value) => updateFilterName(filter, value),
    filterSets,
  };

  const Row = toBeRemoved ? ToBeDeltedRow : RegularRow;

  return (
    <Draggable draggableId={filter.get('_id')} index={index} isDragDisabled={toBeRemoved}>
      {provided => (
        <li ref={provided.innerRef} {...provided.draggableProps} className={className}>
          {isEditing ? <FilterNameEditor {...props} {...provided} /> : <Row {...provided} />}
        </li>
      )}
    </Draggable>
  );
};

const FilterList = styled.ul`
  color: ${colors.gray400};
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  margin: 0px;
  max-height: 380px;
  overflow: auto;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px 16px 0;
    border-bottom: solid 1px ${colors.gray100};
  }

  button.action:hover:not([disabled]) {
    background-color: ${colors.activeState};
    color: ${colors.blue300} !important;
  }
`;

const FilterItem = styled(UnstyledFilterItem)`
  span.item-drag {
    width: 24px;
    padding: 4px;
  }

  span.item-number {
    width: 24px;
  }

  span.item-span {
    width: 52px;
  }

  .btn.text-secondary:disabled {
    color: ${colors.gray200} !important;
  }
`;

import React from 'react';
import { Notification } from '../Notification';
import { isUserInactive } from '../../utils';

const reg = /^(.*)((?:\s(?:has|is)\s.*)(?:\s(?:of|on)\s))(.*)$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, 'Trade Trax', message, null];
};

export default ({ notification, controller, taskUrl, accountUsers }) => {
  const [, name, message, task] = parseNotification(notification.get('message'));
  const userId = notification.getIn(['data', 'createdById']);
  const isInactive = isUserInactive(userId, accountUsers);
  const userInitials = notification.getIn(['data', 'installerInitials']);

  return (
    <Notification
      key={notification.get('_id')}
      name={name}
      linkTo={taskUrl({
        jobId: notification.getIn(['data', 'jobId']),
        taskId: notification.getIn(['data', 'taskId']),
      })}
      // linkTo={`/jobs/${notification.getIn(['data', 'jobId'])}/task/${notification.getIn(['data', 'taskId'])}`}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
      isInactive={isInactive}
      userInitials={userInitials}
    >
      {message}
      {task && <span className="text-dark">{task}</span>}
    </Notification>
  );
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '../Button';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  ${props => `
    .modal-dialog {
      max-width: ${props.maxwidth} !important;
    }
  `}
`;

export const Dialog = ({ controller }) => {
  const { Component, open, props, ...rest } = controller;

  return Component ? <Component {...rest} {...props} /> : null;
};

const AcceptCancel = props => {
  const { title, message, cancel, accept, acceptProps, cancelProps, maxWidth = '' } = props;
  const { centered = false, dialogClassName = '', overlayClassName = '' } = props;

  React.useEffect(() => {
    if (overlayClassName) {
      var elems = [...global.document.getElementsByClassName('modal')];
      const [overlay] = elems.filter(el => el.getAttribute('aria-modal') === 'true');
      // we need to defer the class addition
      setTimeout(() => {
        overlay.classList.add(overlayClassName);
        console.log(overlay.classList);
      }, 100);
    }
  }, [overlayClassName]);

  return (
    <StyledModal show={true} onHide={cancel} maxwidth={maxWidth} centered={centered} dialogClassName={dialogClassName}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {cancelProps.hidden ? null : (
          <PrimaryButton variant={cancelProps.variant} onClick={() => cancel()} className="mr-3 px-4">
            {cancelProps.title}
          </PrimaryButton>
        )}
        <PrimaryButton variant={acceptProps.variant} onClick={() => accept()} className="ml-1 px-4">
          {acceptProps.title}
        </PrimaryButton>
      </Modal.Footer>
    </StyledModal>
  );
};

export const DeleteDialog = props => (
  <AcceptCancel
    {...props}
    acceptProps={{ title: 'Delete', variant: 'danger' }}
    cancelProps={{ title: 'Cancel', variant: 'secondary' }}
  />
);

export const ConfirmDialog = props => (
  <AcceptCancel
    {...props}
    acceptProps={{ title: 'Accept', variant: 'primary' }}
    cancelProps={{ title: 'Cancel', variant: 'secondary' }}
  />
);

export const OKDialog = props => (
  <AcceptCancel
    {...props}
    acceptProps={{ title: 'OK', variant: 'primary' }}
    cancelProps={{ title: 'Cancel', variant: 'secondary', hidden: true }}
  />
);

export const CustomDialog = props => (
  <AcceptCancel
    {...props}
    acceptProps={{ title: props.titleAccept, variant: 'primary' }}
    cancelProps={{ title: props.titleCancel, variant: 'secondary' }}
  />
);

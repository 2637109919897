import React from 'react';
import styled from 'styled-components';
import { Portal } from 'react-overlays';
import { Carousel, Image } from 'react-bootstrap';
import { OverlayHeader } from './OverlayHeader';
import { Thumbnails } from './Thumbnails';
import { colors } from '../../theme';
import { PrimaryButton } from '../../Button';
import { DocViewer } from './DocViewer';

export const CarouselOverlay = ({ show, photos, ...props }) => {
  if (!show || !photos.size) return null;

  return <AlbumCarouselContent photos={photos} {...props} />;
};

export const AlbumCarouselContent = ({
  photos,
  defaultIndex,
  onHide,
  onRemove,
  onDownload,
  setAttachmentInfo = null,
  ...rest
}) => {
  const photosRef = React.useRef();
  const [index, setIndex] = React.useState(defaultIndex);
  const [openDoc, setOpenDoc] = React.useState(null);
  const photo = photos.get(index) || photos.last();
  const isDocument = photo.get('contentType') === 'application/pdf';
  const previewUrl = isDocument ? 'imageUrl' : 'url';

  const handleSelect = (selectedIndex, e) => {
    e.preventDefault();
    setIndex(selectedIndex);
  };

  React.useEffect(() => {
    const photosPortal = document.createElement('div');
    document.body.appendChild(photosPortal);
    photosPortal.id = 'photos-portal';
    photosRef.current = photosPortal;

    return () => {
      document.body.removeChild(photosPortal);
      setAttachmentInfo && setAttachmentInfo({});
    };
  }, [photosRef]);

  React.useEffect(() => {
    const onKeyPress = e => {
      if (e.key === 'Escape') {
        onHide();
      } else if (e.key === 'ArrowLeft') {
        setIndex(index === 0 ? photos.size - 1 : index - 1);
      } else if (e.key === 'ArrowRight') {
        setIndex(index === photos.size - 1 ? 0 : index + 1);
      }
    };

    global.document.body.addEventListener('keydown', onKeyPress);

    // immediately close the carousel to prevent furter errors.
    if (photos.size === 0) onHide();

    return () => {
      global.document.body.removeEventListener('keydown', onKeyPress);
    };
  }, [index, photos, onHide, setIndex]);

  if (photos.size === 0) return null;

  return (
    <Portal container={photosRef.current}>
      <Overlay isCarousel={!openDoc} data-testid="carousel-overlay">
        {openDoc ? (
          <DocViewer doc={openDoc} setOpenDoc={setOpenDoc} />
        ) : (
          <ModalDataContainer>
            <OverlayHeader
              {...rest}
              onHide={onHide}
              photo={photo}
              remove={() => onRemove(photo)}
              downloadFile={onDownload}
            />
            <AlbumCarousel
              activeIndex={Math.min(index, photos.size - 1)}
              onSelect={handleSelect}
              interval={null}
              indicators={false}
              fade={true}
              slide={false}
            >
              {photos.map(photo => (
                <Carousel.Item key={photo.get('_id')}>
                  <ImagePreview
                    url={photo.get(previewUrl)}
                    isDocument={isDocument}
                    previewDoc={() => setOpenDoc(photo)}
                  />
                </Carousel.Item>
              ))}
            </AlbumCarousel>
            <Thumbnails
              className="mx-3"
              photos={photos}
              max={6}
              index={Math.min(index, photos.size - 1)}
              setIndex={setIndex}
            />
          </ModalDataContainer>
        )}
      </Overlay>
    </Portal>
  );
};

const ImagePreview = ({ url, isDocument, previewDoc }) => {
  if (!isDocument) return <Img src={url} className="d-block" />;

  return (
    <div className="position-relative d-flex justify-content-center">
      <PreviewContainer>
        <DocPreview src={url} className="d-block" />
      </PreviewContainer>
      <DocumentFooter>
        See the full Document by opening the PDF
        <PrimaryButton onClick={previewDoc}>Open PDF</PrimaryButton>
      </DocumentFooter>
    </div>
  );
};

const DocPreview = styled(Image)`
  max-width: 100%;
  width: 760px;
`;

const Img = styled(Image)`
  max-height: 500px;
  margin: auto;
`;

const PreviewContainer = styled.div`
  min-height: 100px;
  max-height: 500px;
  margin: auto;
  width: 760px;
  overflow: hidden;
`;

const Overlay = styled.div`
  ${props => `
    background-color: ${props.isCarousel ? 'rgba(0, 0, 0, 0.95)' : colors.pearl};
  `}
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050; // bootstrap's $zindex-modal
  margin-left: auto;
`;

const AlbumCarousel = styled(Carousel)`
  height: 94%;

  .carousel-inner {
    height: 94%;
    display: flex;
    align-items: center;
  }

  .carousel-control-prev {
    justify-content: flex-start;
    width: 10%;
  }
  .carousel-control-next {
    justify-content: flex-end;
    width: 10%;
  }

  .carousel-indicators {
    align-items: center;

    li {
      height: 30px;

      &.active: {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

const DocumentFooter = styled.div`
  width: 700px;
  height: 80px;
  color: ${colors.white};
  background-color: ${colors.gray800};
  opacity: 0.95;
  border-radius: 6px;
  bottom: 15px;
  padding-left: 4rem;
  padding-right: 4rem;

  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ModalDataContainer = styled.div`
  max-width: 80%;
  height: 80vh;
  margin-left: auto;
  margin-right: auto;
`;

import React from 'react';
import { fromJS } from 'immutable';

export const useCommunities = userService => {
  const [communities, setCommunities] = React.useState(fromJS([]));

  React.useEffect(() => {
    userService
      .readCommunities()
      .then(fromJS)
      .then(setCommunities);
  }, []);

  return communities;
};

import React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trimSpaces } from '@tradetrax/web-common/lib/utils';
import { useAutoFocus } from '../../Inputs/useAutofocus';
import { PrimaryButton } from '../../Button';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';

const filterNameSchema = yup.object().shape({
  filterSetName: yup
    .string()
    .required('Name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(2, 'Min. 2 characters')
    .max(30, 'Max. 30 characters'),
});

export const NewFilterSetNameModal = ({ accept, cancel, filterSets }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const tasksListRoutes = ['/tasks', '/upcoming', '/to-do', '/task/open'];
  const isTasksListRoute = tasksListRoutes.some(route => currentPath.includes(route));

  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(filterNameSchema),
    defaultValues: { filterSetName: '' },
  });
  const { dirtyFields } = formState;
  const isValid = dirtyFields.filterSetName && !errors.filterSetName;

  const onSubmit = form => accept(form);
  const autoFocusRef = useAutoFocus();

  return (
    <Modal show={true} onHide={() => cancel()} size="md">
      <Form
        id="new-filter-set"
        noValidate
        validated={formState.isValid && formState.submitCount > 0}
        onSubmit={handleSubmit(onSubmit)}
        className="d-inline-block w-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Filter Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Label className="ml-2">Name</Form.Label>
            <Form.Control
              ref={elem => register((autoFocusRef.current = elem))}
              type={'text'}
              name="filterSetName"
              isInvalid={!!errors.filterSetName}
              isValid={isValid}
              autoComplete="off"
              size="sm"
              className="ml-2"
              placeholder="Ex. My Tasks"
              onBlur={({ target }) =>
                validateIfFilterNameExist(target.value, setError, filterSets, 'filterSetName', isTasksListRoute)
              }
            />
            <div className="d-block w-100">
              <Form.Control.Feedback className={classNames({ 'd-block': isValid })} type="valid">
                <FontAwesomeIcon icon="circle-check" className="mx-2" />
                {'Filter Set is valid'}
              </Form.Control.Feedback>
              <Form.Control.Feedback className={classNames({ 'd-block': !!errors.filterSetName })} type="invalid">
                <FontAwesomeIcon icon="circle-exclamation" className="mx-2" />
                {` `}
                {errors.filterSetName?.message}
              </Form.Control.Feedback>
            </div>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <PrimaryButton variant="secondary" onClick={() => cancel()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton disabled={errors.filterSetName} type="submit" className="mr-n2">
            Create Filter Set
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export function validateIfFilterNameExist(
  name,
  setError,
  filterSets,
  inputField = 'filterSetName',
  isTasksListRoute = false
) {
  const nameExisis = filterSets?.filter(set => set.get('name').toLowerCase() === name.toLowerCase());
  if (nameExisis?.size > 0)
    setError(inputField, { type: 'notMatch', message: `Name already exists ${isTasksListRoute ? '' : 'in this job'}` });
  return !nameExisis?.size;
}

import React from 'react';
import { NotificationsPanel } from './';
import cs from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyState } from '../EmptyState';
import { emptyState, mapDate } from './NotificationsController';
import { colors } from '../theme';

const EVENTS = {
  'echo-test': require('./Events/echo').default,
  overdue: require('./Events/overdue').default,
  'change-request': require('./Events/change-request').default,
  'check-in': require('./Events/check-in-out').default,
  'check-out': require('./Events/check-in-out').default,
  'tagged-in-note': require('./Events/tagged-in-note').default,
  task_assignment: require('./Events/task-assignment').default,
  'task-not-ready-to-start': require('./Events/task-not-ready-to-start').default,
  'status-update': require('./Events/status-update').default,
  'date-change': require('./Events/date-change').default,
  user_invitiation_accepted: require('./Events/user_invitiation_accepted').default,
  user_profile_active: require('./Events/user_profile_active').default,
  'task-missed-check-in': require('./Events/task-missed-check-in').default,
  'task-missed-check-out': require('./Events/task-missed-check-in').default,
  'check-in-reminder': require('./Events/check-in-reminder').default,
  'update-request-reminder': require('./Events/update-request-reminder').default,
  'start-date-confirmed-task': require('./Events/start-date-confirmed-task').default,
  'start-date-confirmation-request': require('./Events/start-date-confirmation-request').default,
  'date-change-relevant-task': require('./Events/date-change-relevant-task').default,
  'lead-time-task': require('./Events/lead-time-task').default,
  'new-task-note': require('./Events/new-task-attachment-or-note').default,
  'new-task-attachment': require('./Events/new-task-attachment-or-note').default,
  'overdue-commitment-request': require('./Events/overdue-commitment-request').default,
  'commitment-request': require('./Events/new-commitment-requests').default,
  'new-commitment-requests': require('./Events/new-commitment-requests').default,
  'filter-shared': require('./Events/shared-filter-set').default,
  'day-wrap-up': require('./Events/day-wrap-up').default,
};

const INSIDE_PANEL = ['notifications-header', 'notifications-panel', 'mark-all'];
const NOTIFICATION_GROUP = ['Today', 'Yesterday', 'This Week', 'Last Week', 'Earlier'];

export const Notifications = ({ context, taskUrl, ...props }) => {
  const [state, controller] = context.notifications;
  const { notifications, visible, tab } = state.toObject();
  const isLoading = notifications === emptyState.get('notifications');
  const isEmpty = !isLoading && notifications.size === 0;

  const isUnreadTab = tab === 'unread';
  const emptyTitle = isUnreadTab ? 'No Unread Notifications' : 'No Notifications';
  const emptyBody = isUnreadTab
    ? 'When you have an unread notification, it will appear here.'
    : 'When you have a notification, it will appear here.';
  const showMarkAllAsRead =
    (isUnreadTab && notifications.size) || (tab === 'all' && notifications.some(item => !item.get('read')));

  React.useEffect(() => {
    const isInsidePanel = node => {
      if (!node) return false;
      if (INSIDE_PANEL.includes(node.id)) return true;
      if (node.classList.contains('tab-all')) return true;
      if (node.classList.contains('tab-unread')) return true;
      if (node.classList.contains('remove-notification')) return true;
      if (node.classList.contains('notification-redirect')) return false;
      return isInsidePanel(node.parentElement);
    };

    const onClick = e => {
      if (visible) {
        if (isInsidePanel(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        controller.toggleNotification();
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [visible, controller]);

  return (
    <NotificationsPanel
      tab={tab}
      controller={controller}
      className={cs({ hide: !visible })}
      isEmpty={isEmpty}
      showMarkAllAsRead={showMarkAllAsRead}
    >
      {isLoading && (
        <div className="spinner-border text-secondary align-self-center mt-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {isEmpty && (
        <EmptyState
          data-testid="notifications-empty-state"
          icon="bell"
          title={emptyTitle}
          body={emptyBody}
          top="10rem"
        />
      )}
      {NOTIFICATION_GROUP.map((groupName, index) => {
        const group = mapDate(notifications).get(groupName);
        if (!group) return null;
        return (
          <div key={`notification-group-${index}`}>
            <span className="ml-4 pl-3 mt-2 font-weight-bold font-size-14">{groupName}</span>
            {group
              .map((notification, index) => {
                const type = notification.get('type') || notification.getIn(['data', 'type']);
                const Component = EVENTS[type];

                try {
                  if (Component) {
                    return (
                      <NotificationWrapper key={`notification-item-${index}`}>
                        <button
                          data-testid="btn-remove"
                          className="btn btn-link p-1 text-secondary btn-remove d-none remove-notification"
                          onClick={() => controller.dismissNotification(notification)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </button>
                        <Component controller={controller} notification={notification} taskUrl={taskUrl} {...props} />
                      </NotificationWrapper>
                    );
                  }

                  console.log(`Notification not implemented ${type}`);
                } catch (err) {
                  console.error(err, notification.toJS());
                }

                return null;
              })
              .toArray()}
          </div>
        );
      })}
    </NotificationsPanel>
  );
};

const NotificationWrapper = styled.div`
  position: relative;
  .btn-remove {
    position: absolute;
    bottom: 5px;
    right: 20px;
    z-index: 9999;
  }
  &:hover .btn-remove {
    display: inline-block !important;
    &:hover {
      color: ${colors.blue300} !important;
    }
  }
`;

Notifications.defaultProps = {
  taskUrl: ({ jobId, taskId }) => `/jobs/${jobId}/task/${taskId}`,
};

import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssigneesTypeahead } from '../Typeahead/AssigneesTypeahead';
import { Initials, UserNameLabel } from '../Tags';
import { isUserInactive } from '../utils';
import { colors } from '../theme';

export { MultipleAssigneeSelect } from './MultipleAssigneeSelect';

const getSelected = user => {
  if (!user || !user._id) return null;
  const name = user.name?.split(' ') || [];
  return {
    _id: user._id,
    firstName: name[0] || '',
    lastName: name[1] || '',
  };
};

const DEFAULT_LABEL_KEY = option => `${option.firstName} ${option.lastName}`;

export const AssigneeSelect = ({
  title,
  currentUser,
  loggedUserId,
  options,
  users,
  onChange,
  placeholder = 'Needs Assignment',
  displayInitials = false,
  hasPermission = true,
  labelKey = DEFAULT_LABEL_KEY,
  index = 0,
  inputSize,
}) => {
  const [selectedUser, setSelectedUser] = React.useState();
  const [isEdit, setEditManager] = React.useState(false);
  const [name, setName] = React.useState();
  const isInactive = isUserInactive(currentUser?.get('_id'), users);
  const isInitialsShow = name && displayInitials;
  const isSelfAssigned = loggedUserId === currentUser?.get('_id');

  React.useEffect(() => {
    setSelectedUser(getSelected(currentUser?.toJS()));
    setName(currentUser?.get('name'));
  }, [currentUser, getSelected]);

  const onChangeAssignee = (assignee = null) => {
    if (assignee) {
      const { _id, subAccountId } = assignee;
      const assigneeId = _id || subAccountId;
      if (currentUser?.get('_id') !== assigneeId) {
        onChange(assignee);
      } else {
        setName(currentUser?.get('name'));
      }
    } else if (assignee !== currentUser && currentUser?.get('_id') !== null) {
      onChange(assignee);
    }
  };
  return (
    <>
      {isEdit ? (
        <AssigneeSelectWrapper
          className="d-flex flex-column mr-4"
          isSelected={!!name}
          displayInitials={displayInitials}
          width={inputSize}
        >
          {title && <small className="text-muted">{title}</small>}
          <div className="d-flex flex-row align-items-center typeahead-wrapper">
            {isInitialsShow && <Initials name={currentUser.get('initials') || name} size={35} />}
            <AssigneesTypeahead
              id={`user-${title ? title.replace(' ', '') : index}`}
              size="lg"
              labelKey={labelKey}
              placeholder={`  ${placeholder}`}
              isMyId={option => loggedUserId === option._id}
              filterSelected={(option, selected) => selected._id !== option._id}
              selected={selectedUser ? [selectedUser] : []}
              options={options.toJS()}
              clearButton
              autoFocus
              onClear={() => {
                setName(null);
                setSelectedUser(null);
              }}
              onChange={([user]) => {
                onChangeAssignee(user);
                setEditManager(false);
              }}
              onBlur={({ target }) => {
                const currentName = currentUser?.get('name');
                const isNameValid = currentName && options.filter(assignee => assignee.name === target.value).size;
                if (!isNameValid) {
                  setName(currentName);
                  setSelectedUser(getSelected(currentUser?.toJS()));
                }
                setEditManager(false);
              }}
            />
          </div>
        </AssigneeSelectWrapper>
      ) : (
        <HeaderUser className="d-flex flex-column mr-4" disabled={!hasPermission}>
          {title && <small className="text-muted">{title}</small>}
          <div className="d-flex flex-row align-items-center" onClick={() => hasPermission && setEditManager(true)}>
            {isInitialsShow && <Initials name={currentUser.get('initials') || name} size={35} />}
            <UserNameLabel
              className={cn('text-truncate', { 'not-assigned': !name, 'self-assigned': isSelfAssigned })}
              isInactive={isInactive}
              hasPermission={hasPermission}
            >
              <div className="d-flex align-items-center">
                <UserName className="text-truncate d-inline-block">
                  {name || (hasPermission && 'Needs Assignment') || 'Not Assigned'}
                </UserName>
                {hasPermission && <FontAwesomeIcon icon="chevron-down" className="ml-1" />}
              </div>
            </UserNameLabel>
          </div>
        </HeaderUser>
      )}
    </>
  );
};

export const HeaderUser = styled.div`
  ${({ disabled }) => `
    cursor: ${disabled ? 'default' : 'pointer'};

    span:first-of-type {
      cursor: ${disabled ? 'default' : 'pointer'};
    }

    span.hover-active:hover {
      color: ${colors.blue400};
      text-decoration: underline;
    }
  `}
`;

const AssigneeSelectWrapper = styled.div`
  ${({ isSelected, displayInitials, width }) => `
    .typeahead-wrapper {
      border: 1px solid ${colors.blue300};
      border-radius: 5px;
      box-shadow: 0 0 0 0.2rem rgba(1, 124, 238, 0.25);
      width: ${width || '250px'};
    }
    .form-control {
      border: none;
      padding-left: 0;
      &:focus {
        box-shadow: none;
      }
    }

    div.rbt {
      width: 100%;
    }

    .dropdown-menu {
      width: 250px !important;
      left: ${isSelected && displayInitials ? '-45px' : '0px'} !important;
    }

    input.rbt-input-main  {
      ${!displayInitials && 'padding-left: 1rem;'}
    }
  `}
`;

const UserName = styled.span`
  max-width: 145px;
  line-height: normal;
  color: inherit !important;
`;

import React from 'react';
import { fromJS } from 'immutable';
import { useController, markAsSync } from '../useController';

const emptyState = fromJS({
  tab: 'attachments',
  attachmentsCount: 0,
  notesCount: 0,
});

const actions = { setTab, setAttachmentsCount, setNotesCount };

export function TaskFooterContext(appContext, service) {
  const containerRef = React.useRef();
  const [state, controller] = useController(actions, emptyState, { ...appContext, service });

  return { state, controller, containerRef };
}

markAsSync(setTab);
function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSync(setAttachmentsCount);
function setAttachmentsCount(state, count) {
  return state.set('attachmentsCount', count);
}

markAsSync(setNotesCount);
function setNotesCount(state, count) {
  return state.set('notesCount', count);
}

import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import { colors } from '../theme';
import { Loader } from '@googlemaps/js-api-loader';

const { REACT_APP_WEB_GOOGLE_MAPS_API_KEY = '' } = process.env;

const loader = new Loader({
  apiKey: REACT_APP_WEB_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

// zoom levels
// 1: World
// 5: Landmass/continent
// 10: City
// 15: Streets
// 20: Buildings

// geoLocation { lat, lng }
export const GeoLocationModal = ({ geoLocation, cancel, title, subTitle }) => {
  const mapRef = React.useRef();
  const mapOptions = {
    center: geoLocation,
    zoom: 18,
  };

  React.useEffect(() => {
    loader
      .load()
      .then(google => {
        const map = new google.maps.Map(mapRef.current, mapOptions);
        new google.maps.Marker({
          position: geoLocation,
          map: map,
        });
      })
      .catch(e => {
        // do something
      });
  });

  return (
    <Modal show={true} onHide={cancel} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="font-weight-bold">{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4 mt-n3 font-size-14">{subTitle}</div>
        <div className={cn('d-flex justify-content-center flex-column')}>
          <MapViewer className="mb-3" ref={mapRef}>
            Something went wrong. Please try again.
          </MapViewer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MapViewer = styled.div`
  width: 715px;
  height: 480px;
  background-color: ${colors.activeState};
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';
import cn from 'classnames';
import { fromJS } from 'immutable';
import { Modal, Form } from 'react-bootstrap';
import { Controller as FormController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../Button';
import { AssigneesTypeahead } from '../Typeahead/AssigneesTypeahead';
import { TaskBulkAssignmentModalController } from './TaskBulkAssignmentModalController';

export function TaskBulkAssignmentModal({
  accept,
  cancel,
  tab,
  community,
  taskAssignees,
  isAnyNotAssigned,
  context,
  assigneeOptions,
  loggedAccountId,
  isSelectAll,
}) {
  const { appState } = context;
  const { state, formContext } = TaskBulkAssignmentModalController(
    taskAssignees,
    context,
    assigneeOptions,
    isSelectAll
  );
  const { control, errors, register, handleSubmit } = formContext;
  const isAccountAssigned = tab === 'account';

  const isValidationEnabled = !state.get('isUnassign') && !taskAssignees.length;
  const assignmentType = `${tab.charAt(0).toUpperCase()}${tab.slice(1)}`;

  const onSubmit = ({ assignees }) => {
    const isCancelAssignment = taskAssignees.length && !state.get('isUnassign');
    const assignee =
      !state.get('isUnassign') && !assignees && isAnyNotAssigned && taskAssignees.length === 1
        ? state.get('defaultSelected')
        : assignees && assignees[0];
    if (isCancelAssignment && !assignee) {
      cancel();
    } else {
      accept({ assignee });
    }
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Assign {assignmentType}
            <br />
            <h6>{community.get('name')}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="d-flex flex-row justify-content-between">
            <span className="form-label">{`Assign to ${assignmentType}`}</span>
          </div>
          <Form.Group controlId="assignees" className="mb-3">
            <FormController
              name="assignees"
              control={control}
              render={({ value, onChange }) => (
                <TypeaheadModal
                  defaultSelected={state.get('defaultSelected')}
                  isAccountAssigned={isAccountAssigned}
                  isInvalid={!!errors.assignees}
                  loggedId={loggedAccountId || appState.getIn(['user', '_id'])}
                  onChange={onChange}
                  assigneeOptions={assigneeOptions}
                  placeholder={`Choose ${assignmentType}`}
                  value={value}
                />
              )}
            />
            <Form.Control.Feedback type="invalid" className={cn({ 'd-block': !!errors.assignees })}>
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.assignees?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <input checked={isValidationEnabled} name="isValidationEnabled" hidden ref={register} type="checkbox" />
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton variant="secondary" onClick={() => cancel()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton className="mr-n2" type="submit">
            {state.get('isUnassign') ? 'Unassign' : 'Assign'}
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const TypeaheadModal = ({ ...props }) => {
  if (props.isAccountAssigned) {
    return <AccountTypeahead {...props} />;
  } else return <UsersTypeahead {...props} />;
};

const UsersTypeahead = props => {
  const { assigneeOptions, defaultSelected, loggedId } = props;
  const selected = defaultSelected ? [fromJS(defaultSelected)] : null;
  return (
    <AssigneesTypeahead
      {...props}
      id="assignee-user"
      selected={props.value || selected}
      options={assigneeOptions.toArray()}
      labelKey={option => `${option.get('firstName')} ${option.get('lastName')}`}
      isMyId={option => loggedId === option.get('_id')}
      filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
    />
  );
};

const AccountTypeahead = props => {
  const { loggedId, defaultSelected, assigneeOptions } = props;
  const selected = defaultSelected ? [fromJS(defaultSelected)] : null;
  return (
    <AssigneesTypeahead
      {...props}
      id="assignee-account"
      options={assigneeOptions.toArray()}
      selected={props.value || selected}
      labelKey={option => option.get('name')}
      isMyId={option => loggedId === option.get('subAccountId')}
      filterSelected={(option, selected) => selected.get('subAccountId') !== option.get('subAccountId')}
    />
  );
};

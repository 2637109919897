import React from 'react';
import { SystemNotification } from '../SystemNotification';

const reg = /^(.*)((?:\s(?:has|is)\s.*)(?:\s(?:of|on|for)\s))(.*)$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, 'Trade Trax', message, null];
};

export default ({ notification, controller, jobUrl }) => {
  const [, taskName, message, jobName] = parseNotification(notification.get('message'));
  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={jobUrl({
        jobId: notification.getIn(['data', 'jobId']),
      })}
      linkHash="#feed"
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {taskName && <span className="text-dark">{taskName}</span>}
      {message}
      {jobName && <span className="text-dark">{jobName}</span>}
    </SystemNotification>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Modal, Form } from 'react-bootstrap';
import { colors } from '../theme';
import { PrimaryButton } from '../Button';

export function LegalAgreement({ close, accept, legalAgreements }) {
  const [isChecked, setIsChecked] = React.useState(false);
  const isEulaAccepted = legalAgreements.get('EULA');

  const onClick = () => {
    if (isChecked) accept();
  };

  const props = {
    backdrop: 'static',
    keyboard: false,
    size: 'md',
  };

  return (
    <StyledModal show={true} onHide={close} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Legal Agreements Updated</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          We have updated our Terms of Use and{' '}
          {isEulaAccepted
            ? 'End-User License Agreement, and have added our Privacy Policy.'
            : 'have added our Privacy Policy and End-User License Agreement.'}
        </div>
        <div className="d-flex mt-3">
          <CheckBox className="mb-4" type="checkbox" onChange={() => setIsChecked(!isChecked)} />
          <div className="ml-2 mt-0 mb-2 font-size-24">
            <span>By checking the box, I accept the TradeTrax </span>
            <ButtonLink href="https://tradetrax.com/privacy-policy" target="_blank">
              Privacy Policy
            </ButtonLink>
            ,{' '}
            <ButtonLink href="https://tradetrax.com/terms-of-use" target="_blank">
              Terms of Use
            </ButtonLink>{' '}
            and{' '}
            <ButtonLink href="https://tradetrax.com/end-user-license-agreement" target="_blank">
              End-User License Agreement.
            </ButtonLink>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton className="px-5" disabled={!isChecked} onClick={onClick}>
          Save
        </PrimaryButton>
      </Modal.Footer>
    </StyledModal>
  );
}

const CheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;
  .form-check-label {
    margin-top: 4px;
  }
`;

const ButtonLink = styled.a`
  cursor: pointer;
  color: ${colors.blue300};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog.modal-md {
    max-width: 624px;
  }
`;

import React from 'react';

export const UserProfileLoading = () => (
  <div className="mt-4">
    <svg width="549" height="748" viewBox="0 0 549 748" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="148" width="549" height="600" fill="#F9F9FA" />
      <rect x="34" y="120" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="219" width="197" height="12" rx="5" fill="#E0E2E7" />
      <rect x="269" y="219" width="197" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="299" width="432" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="197" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="269" y="197" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="277" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="371" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="458" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="550" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="627" width="204" height="40" rx="18" fill="#E0E2E7" />
      <rect x="147" y="47" width="171" height="12" rx="5" fill="#E0E2E7" />
      <rect x="147" y="19" width="243" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="393" width="431" height="12" rx="5" fill="#E0E2E7" />
      <rect x="35" y="478" width="431" height="12" rx="5" fill="#E0E2E7" />
      <path
        d="M35 694C35 691.239 37.2386 689 40 689H120C122.761 689 125 691.239 125 694V696C125 698.761 122.761 701 120 701H40C37.2386 701 35 698.761 35 696V694Z"
        fill="#E0E2E7"
      />
      <path
        d="M35 575C35 572.239 37.2386 570 40 570H461C463.761 570 466 572.239 466 575V577C466 579.761 463.761 582 461 582H40C37.2386 582 35 579.761 35 577V575Z"
        fill="#E0E2E7"
      />
      <rect x="205" y="119" width="126" height="12" rx="5" fill="#E0E2E7" />
      <circle cx="68" cy="39" r="39" fill="#E0E2E7" />
    </svg>
  </div>
);

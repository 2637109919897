import React from 'react';
import { List, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useController, markAsSync } from '../useController';
import { BannerButton } from '../Button';

const emptyState = List();
const actions = { add, close, remove, reveal, pop, success, warning, error, closeByKey, showAcceptCookiesBanner };

export const MiniAlertsContext = () => {
  const [state, controller] = useController(actions, emptyState);

  return { state, controller };
};

let key = 0;
markAsSync(add);
function add(
  state,
  {
    message,
    content,
    variant = 'success',
    size = 'l',
    autoClose = true,
    timeout = 7,
    isDismissible = true,
    keyCode = null,
    purge = false, //`if (purge) => overwerite all existing mini alerts with this one.
  }
) {
  const item = Map({
    key: ++key,
    isClosed: true,
    isDismissible,
    variant,
    message,
    content,
    size,
    keyCode,
  });

  setTimeout(() => this.controller.reveal(item, autoClose, timeout), 50);

  if (keyCode) {
    const index = state.findIndex(msg => msg.get('keyCode') === keyCode);
    if (index >= 0) {
      return state.set(index, item);
    }
  }

  if (purge) {
    // step 2. Wait 1 second and then make the items dissapear
    setTimeout(() => {
      this.controller.dispatch([state => state.map(item => item.set('isClosed', item.get('key') !== key))]);
    }, 1000);

    // step 3. Wait 1.5 seconds and then remove the other items from the list
    setTimeout(() => {
      this.controller.dispatch([state => state.filter(item => item.get('key') === key)]);
    }, 1500);

    // step 1 put the item on the bottom of the list.
    return state.push(item);
  }

  return state.unshift(item);
}

markAsSync(close);
function close(state, item) {
  const index = state.indexOf(item);
  if (index === -1) return state;

  const closed = item.set('isClosed', true);

  setTimeout(() => {
    this.controller.remove(closed);
  }, 500);

  return state.set(index, closed);
}

markAsSync(closeByKey);
function closeByKey(state, key) {
  const alert = state.find(item => item.get('key') === key);
  return close.call(this, state, alert);
}

markAsSync(remove);
function remove(state, item) {
  const index = state.indexOf(item);
  if (index === -1) return state;
  return state.remove(index);
}

markAsSync(reveal);
function reveal(state, item, autoClose, timeout) {
  const index = state.indexOf(item);
  if (index === -1) return state;

  const revealed = item.set('isClosed', false);

  if (autoClose) setTimeout(() => this.controller.close(revealed), timeout * 1000);

  return state.set(index, revealed);
}

markAsSync(pop);
function pop(state) {
  return state.pop();
}

markAsSync(success);
function success(state, props) {
  return add.call(this, state, { variant: 'success', ...props });
}

markAsSync(error);
function error(state, props) {
  console.log('error proprs', { props });
  return add.call(this, state, { variant: 'danger', ...props });
}

markAsSync(warning);
function warning(state, props) {
  return add.call(this, state, { variant: 'warning', ...props });
}

markAsSync(showAcceptCookiesBanner);
function showAcceptCookiesBanner(state, acceptCookies = () => {}) {
  let alertKey;
  const onAcceptCookies = () => {
    this.controller.closeByKey(alertKey);
    acceptCookies();
  };

  const result = add.call(this, state, {
    variant: 'primary',
    autoClose: false,
    isDismissible: false,
    important: true,
    size: 'xl',
    message: (
      <span className="cookie-alert">
        <FontAwesomeIcon icon="cookie-bite" className="mr-1" />
        <strong className="mr-1">We use cookies</strong>
        <span>to ensure that you have the best experience on our site.</span>
        <BannerButton className="ml-auto font-size-12" onClick={onAcceptCookies}>
          ACCEPT
        </BannerButton>
      </span>
    ),
  });

  alertKey = result.first().get('key');
  return result;
}

import React from 'react';
import io from 'socket.io-client';

export const CHANNELS = {
  JOB: 'job',
  TASK: 'task',
  SELF: 'self',
  SELF_ACCOUNT: 'self-account',
  ACCOUNT_TASK: 'accountTask',
  COMMUNITY: 'community',
};

const getServiceUrl = () => {
  const { hostname } = location;
  const name = 'real-time';

  if (hostname === 'localhost') {
    return `ws://${name}.local.tradetrax.com`;
  }

  const result = hostname.replace(
    /^(dev-)?([^\.]+)((?:.*)?.tradetrax\.com)$/,
    (m, prefix = '', sub, host) => `wss://${name}${host}`
  );

  return result;
};

const socket = io(getServiceUrl(), {
  autoConnect: false,
  withCredentials: true,
  reconnection: true,
  transports: ['websocket'],
  //   origin:[ ]
});

export function useSocket(user) {
  React.useEffect(() => {
    // socket.on('connect', () => {
    //   console.log('is connected from app controller');
    // });

    // socket.onAny((event, ...args) => {
    //   console.log(event, args);
    // });

    const { _id: userId, accountId, domain } = user;
    socket.auth = { userId, accountId, domain };
    socket.connect();
  }, [socket, user]);

  return socket;
}

export function useChannel(channel, id, callback) {
  React.useEffect(() => {
    const room = getRoom(channel, id);

    // you might have several listeners for the same channel
    // but only need to suscribe once.
    if (socket.listeners(room).length === 0) {
      socket.emit('suscribe', { channel, id });
    }

    socket.on(room, callback);

    return () => {
      socket.off(room, callback);

      // if there is no listeners for this channel then unsubscribe.
      if (socket.listeners(room).length === 0) {
        socket.emit('unsuscribe', { channel, id });
      }
    };
  }, [channel, id, callback]);
}

const getRoom = (channel, id) => (id ? `${channel}:${id}` : channel);

import React from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import cn from 'classnames';
import { gray100 } from '../theme';

export { AssigneesTypeahead } from './AssigneesTypeahead';

export const renderMenu = (props, menuProps, results, menuItemLabel) => (
  <Menu {...menuProps} maxHeight="310px">
    {results.map((result, index) => (
      <StyledMenuItem option={result} position={index} key={index}>
        {menuItemLabel(result, props)}
      </StyledMenuItem>
    ))}
  </Menu>
);

export const isValidOption = (ref, { options, labelKey }) => {
  const text = ref.current.getInput().value;
  if (!text) return true;
  return !!options.find(option => labelKey(option) === text);
};

export const TrxTypeahead = ({ addOption, isKeppClear = false, ...props }) => {
  const { renderMenu, selected, emptyLabel = 'No Results Found', ...rest } = props;
  const [currentValue, setCurrentValue] = React.useState(selected || []);
  const typeaheadRef = React.useRef();
  const onChange = option => {
    if (option.length) props.onChange && props.onChange(option);
    else {
      typeaheadRef.current.focus();
      props.onClear && props.onClear();
    }
    if (isKeppClear) {
      addOption(option);
      typeaheadRef.current.blur();
    } else setCurrentValue(option);
  };
  const onBlur = event => {
    const isValid = isValidOption(typeaheadRef, props);
    if (isValid && !currentValue?.length) props.onChange && props.onChange([]);
    else if (!isValid) setCurrentValue(selected || []);

    if (!currentValue?.length && typeaheadRef.current) typeaheadRef.current.clear();
    props.onBlur && props.onBlur(event);
  };
  const showIcon = !currentValue?.length;
  React.useEffect(() => {
    setCurrentValue(selected);
  }, [selected, setCurrentValue]);
  return (
    <Typeahead
      {...rest}
      ref={typeaheadRef}
      bsSize="lg"
      selected={currentValue}
      emptyLabel={emptyLabel}
      multiple={false}
      onChange={onChange}
      onBlur={onBlur}
      renderMenu={renderMenu(props)}
      paginationText="See more..."
      clearButton
    >
      {showIcon && (
        <FontAwesomeIcon
          icon={props.icon || 'chevron-down'}
          className={cn({ 'mr-3': ['is-valid', 'is-invalid'].some(tag => props.className?.includes(tag)) })}
        />
      )}
    </Typeahead>
  );
};

const StyledMenuItem = styled(MenuItem)`
  height: 60px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${gray100};

  a.dropdown-item {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

import React from 'react';
import { OverlayTrigger, Popover, Button, Overlay } from 'react-bootstrap';
import styled from 'styled-components';
import { SecondaryButton } from '../Button';
import { colors } from '../theme';

export { PopoverSMS } from './PopoverSMS';

const DEFAULT_DELAY = 400;

export function PopoverStickOnHover({
  popover,
  children,
  className = '',
  placement = 'bottom',
  popoverId = 'popover-trx',
  trigger = ['hover', 'focus'],
  delay = DEFAULT_DELAY,
  width = 324,
  ...rest
}) {
  const [showPopover, setShowPopover] = React.useState(false);
  let setTimeoutConst = null;
  React.useEffect(() => () => {
    if (setTimeoutConst) clearTimeout(setTimeoutConst);
  });
  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const Pop = (
    <StyledPopover
      id={popoverId}
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
      width={width}
    >
      {popover}
    </StyledPopover>
  );

  return (
    <OverlayTrigger show={showPopover} trigger={trigger} placement={placement} overlay={Pop} delay={delay} {...rest}>
      <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </OverlayTrigger>
  );
}

export function PopoverOnButton({
  children,
  message,
  onConfirm,
  ignorePopover = false,
  btnRef,
  confirmText = 'OK',
  placement = 'bottom-end',
}) {
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setShow(false);
    onConfirm();
  };

  const popover = (
    <StyledPopover id="popover-on-button" content={true} width={350}>
      <Popover.Content>
        <p>{message}</p>
        <div className="d-flex flex-row  justify-content-end">
          <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <SecondaryButton variant="outline-primary" onClick={handleClick}>
            {confirmText}
          </SecondaryButton>
        </div>
      </Popover.Content>
    </StyledPopover>
  );

  React.useEffect(() => {
    const onClick = e => {
      if (ignorePopover) onConfirm();
      else {
        e.preventDefault();
        setShow(show => !show);
      }
    };

    btnRef.current?.addEventListener('click', onClick);
    return () => {
      btnRef.current?.removeEventListener('click', onClick);
    };
  }, [setShow]);

  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node === btnRef.current) return true;
      if (node.id === 'popover-on-button') return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [show, setShow]);

  if (ignorePopover) return <>{children}</>;

  return (
    <OverlayTrigger trigger="click" placement={placement} show={show} overlay={popover}>
      {children}
    </OverlayTrigger>
  );
}

export const ConfirmCancelPopover = ({
  message,
  confirmText = 'OK',
  show,
  onCancel,
  onConfirm,
  target,
  containerRef,
}) => {
  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.id === 'popover-on-button') return true;
      if (node.classList.contains('popover-trigger')) return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        onCancel();
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [show]);

  return (
    <Overlay show={show} target={target} placement="bottom" container={containerRef.current} containerPadding={20}>
      <StyledPopover id="popover-on-button" content={true} width={350}>
        <Popover.Content>
          <p>{message}</p>
          <div className="d-flex flex-row  justify-content-end">
            <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={onCancel}>
              Cancel
            </Button>
            <SecondaryButton variant="outline-primary" onClick={onConfirm}>
              {confirmText}
            </SecondaryButton>
          </div>
        </Popover.Content>
      </StyledPopover>
    </Overlay>
  );
};

const StyledPopover = styled(Popover)`
  ${({ width }) => `
      width: ${width}px;
      min-width: ${width}px;
      border-radius: 8px;
      font-size: 14px;
      padding: 0px 10px 0px 0px;
      background-color: ${colors.white};
  `}
`;

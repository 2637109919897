import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import { useForm } from 'react-hook-form';
import { Filter } from './Filter';
import { IconButton } from '../../Button';

export const UPCOMING_TASKS_FILTER = 'upcomingTasksFilter';
export const OPEN_TASKS_FILTER = 'openTasksFilter';
export const UR_OVERDUE_FILTER = 'UR_OVERDUE_FILTER';
export const TO_DO_FILTER = 'TO_DO_FILTER';

export const emptyFilter = fromJS({
  isMyTasksOnly: false,
  taskTypes: [],
  community: [],
  accountAssignee: [],
  filterSetId: '',
});

export const ActiveFilterIcon = styled.small`
  width: 10px;
  height: 10px;
  top: -2px;
  left: 28px;
  position: absolute;
`;

export const TasksFilter = ({
  toggleFilter,
  isFilterActive,
  filter,
  iconCustomClass,
  controller,
  className = '',
  disabled = false,
  ...props
}) => {
  const onToggle = toggleFilter || controller.toggleFilter;
  const formContext = useForm({ defaultValues: filter?.toJS() });
  const isFilterSet = React.useMemo(() => {
    if (!filter) return false;
    const { isMyTasksOnly, taskTypes, community, accountAssignee } = filter?.toJS();
    return isMyTasksOnly || taskTypes.length || community.length || accountAssignee?.length;
  }, [filter]);

  React.useEffect(() => {
    formContext.reset(filter?.toJS());
  }, [filter]);

  React.useEffect(() => {
    // TODO: this could be better as array and with includes function
    const isInsideFilter = node => {
      if (!node) return false;
      if (node.id === 'tasks-filter') return true;
      if (node.id === 'Task-Type') return true;
      if (node.id === 'Community') return true;
      if (node.id === 'Account-Assignee') return true;
      if (node.id === 'icon-filter') return true;
      if (node.id === 'clear-filters') return true;
      if (node.classList.contains('do-not-close-popup')) return true;
      if (node.classList.contains('rbt-close')) return true;
      if (node.classList.contains('rbt-menu-pagination-option')) return true;
      if (node.classList.contains('modal')) return true;
      return isInsideFilter(node.parentElement);
    };

    const onClick = e => {
      if (isFilterActive) {
        if (isInsideFilter(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        onToggle();
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [isFilterActive, controller]);

  return (
    <div className="position-relative">
      {isFilterActive ? (
        <Filter
          id="tasks-filter"
          isFilterSet={isFilterSet}
          formContext={formContext}
          controller={controller}
          filter={filter}
          {...props}
        />
      ) : null}
      <IconButton
        id="icon-filter"
        icon="filter"
        disabled={disabled}
        onClick={() => onToggle()}
        className={cn(`position-relative ${className}`, {
          active: isFilterActive,
          filter_white: props.backGroundWhite,
          filterSet: isFilterSet,
          ...iconCustomClass,
        })}
      >
        {isFilterSet ? <ActiveFilterIcon className="badge-danger rounded-pill" /> : null}
      </IconButton>
    </div>
  );
};

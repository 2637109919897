import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TextInputMention } from './TextInputMention/TextInputMention';
import { PrimaryButton } from '../Button';
import { colors } from '../theme';
import mentionsStyles from './TextInputMention/mentions.module.css';
import { EditorState } from 'draft-js';
import { MAX_CHARS } from './NotesController';

export const AddNote = ({ state, controller, user }) => {
  const { isUploadNote, usersToTag, charLeft, isEditing, textValue, inputData } = state.toObject();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const textAreaRef = React.useRef(null);
  const { handleSubmit } = useForm();

  const reset = () => {
    setEditorState(EditorState.createEmpty());
    controller.reset();
  };

  const onSubmit = () => {
    const textTyped = inputData.blocks.map(block => block.text).join('');

    if (/^\s*$/.test(textTyped)) {
      textAreaRef.current.focus();
    } else {
      controller.addNote(textValue, inputData).then(reset);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column mt-3">
        {usersToTag.length && (
          <TextInputMention
            usersToTag={usersToTag}
            onChangeText={(text, inputDataEditor) => controller.changeText(text, inputDataEditor)}
            onFocus={() => controller.statusEdit(true)}
            className={charLeft < 0 && mentionsStyles.invalid}
            editorState={editorState}
            setEditorState={setEditorState}
            ref={textAreaRef}
            user={user}
          />
        )}
        <div className="mb-3">
          <CharactersLeft data-testid="note-characters-left" className={cn({ 'text-danger': charLeft < 0 })}>
            {charLeft === MAX_CHARS ? `Max. 1,000 characters` : `${charLeft} characters left`}
          </CharactersLeft>
        </div>

        <div className={cn('d-flex flex-row mb-3', { invisible: !isEditing && !isUploadNote })}>
          <PrimaryButton onClick={reset} className="ml-auto mr-2" variant="secondary" disabled={isUploadNote}>
            Cancel
          </PrimaryButton>
          <PrimaryButton disabled={isUploadNote || charLeft < 0} type="submit">
            {isUploadNote ? <Loader /> : 'Add Note'}
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

const Loader = () => (
  <div style={{ width: '75px' }}>
    <Spinner animation="border" variant="light" size="sm" />
  </div>
);

const CharactersLeft = styled.span`
  position: relative;
  display: block;
  float: right;
  margin-top: 4px;
  padding-right: 4px;
  font-size: 12px;
  color: ${colors.gray200};
`;

import styled from 'styled-components';
import { colors } from '../theme';
// export const UnstyledImpact = ({ className, children }) => <ul className={className}>{children}</ul>;

export const Impact = styled.ul`
  list-style: none;
  padding: 0;
  background: ${colors.pearl};
  margin: 12px 0;
  border-radius: 6px;

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: solid 1px #d3ddf0;

    label {
      font-weight: bold;
      font-size: 16px;
    }

    > :first-child {
      margin-left: 1.25rem;
    }

    > :last-child {
      margin-right: 1.25rem;
    }

    :last-child {
      border: none;
    }

    &.clickable:hover {
      background-color: ${colors.blue100};
      cursor: pointer;
    }

    &.small-li {
      height: 40px;
      border-bottom: 0px;
    }
  }
`;

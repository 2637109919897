import moment from 'moment';
import EventEmitter from 'events';
import { fromJS, List } from 'immutable';
import { K, markAsSync, markAsSideEffect } from '../useController';
import { DEVICE_TOKEN } from './NotificationsController';
import { SERVER_KEY, firebaseConfig } from './firebase.config';

const emitter = new EventEmitter();

function emitEvent(payload) {
  const { jobId, taskId } = payload.data || {};
  const eventName = `${jobId}_${taskId}`;
  emitter.emit(eventName, payload);
}

export const onTaskChange = (taskId, listener) => emitter.on(taskId, listener);
export const offTaskChange = (taskId, listener) => emitter.removeListener(taskId, listener);
markAsSideEffect(onTaskChange, offTaskChange);

export async function initializeMessaging() {
  await waitFor(() => window.firebase && window.firebase.messaging);
  try {
    window.firebase.initializeApp(firebaseConfig);

    const messaging = window.firebase.messaging();
    messaging.usePublicVapidKey(SERVER_KEY);
    messaging.onMessage(payload => {
      // console.log('action on message => ', payload);
      this.controller.addMessage(payload);
      emitEvent(payload);
    });
    messaging.onTokenRefresh(() => this.controller.onTokenRefresh(messaging));

    const token = await messaging.getToken();
    this.controller.registerToken(token);
    return state => state.merge({ messaging, token });
  } catch (err) {
    console.log('error initializing', err);
    return K;
  }
}

markAsSideEffect(toggleTab);
export function toggleTab(tab) {
  const isAll = tab === 'all';
  const read = isAll ? 'all' : false;
  this.controller.readNotifications(read).then(() => this.controller.dispatch([state => state.set('tab', tab)]));
}

export async function readNotifications(read = 'all') {
  return this.service
    .readNotifications({}, { query: { read } })
    .then(fromJS)
    .then(items => state => state.set('notifications', items));
}

markAsSync(toggleNotification, addMessage);
export function toggleNotification(state) {
  return state.update('visible', visible => !visible);
}

export function addMessage(state, payload) {
  const { _id, message, type, ...data } = payload.data;
  const notification = fromJS({
    _id,
    type,
    message,
    data,
    read: false,
    createdAt: moment.utc().format(),
  });
  return state.update('notifications', notifications => notifications.unshift(notification));
}

export async function markAsSeen(notification) {
  const notificationId = notification.get('_id');

  const tab = this.state.get('tab');
  return this.service.updateNotification({ read: true }, { params: { notificationId } }).then(() => state =>
    state.update('notifications', notifications => {
      const index = notifications.findIndex(n => n.get('_id') === notificationId);
      if (index < 0) return notifications;
      const isRemove = tab === 'unread' && index >= 0;
      return isRemove ? notifications.remove(index) : notifications.setIn([index, 'read'], true);
    })
  );
}

markAsSync(markAllAsSeen);
export function markAllAsSeen(state) {
  const tab = this.state.get('tab');
  const notifications = state.get('notifications');
  this.service.updateMultipleNotification({ read: true }, { query: { read: false } }).catch(() => {
    this.controller.dispatch([state => state.set('notifications', notifications)]);
  });
  return state.update('notifications', notifications => {
    const isRemove = tab === 'unread';
    return isRemove ? List() : notifications.map(notification => notification.set('read', true));
  });
}

markAsSync(dismissNotification);
export function dismissNotification(state, notification) {
  const notificationId = notification.get('_id');
  const notifications = state.get('notifications');
  this.service
    .updateNotification({ dismissed: true }, { params: { notificationId } })
    .then(() => {
      this.alert.add({ message: 'Notification successfully removed.', variant: 'success' });
    })
    .catch(() => {
      this.alert.add({
        message: 'There was a problem removing this notification. Please try again.',
        variant: 'danger',
      });
      this.controller.dispatch([state => state.set('notifications', notifications)]);
    });

  return state.update('notifications', notifications => {
    const index = notifications.findIndex(n => n.get('_id') === notificationId);
    return index >= 0 ? notifications.remove(index) : notifications;
  });
}

export async function onTokenRefresh(messaging) {
  try {
    const token = await messaging.getToken();
    this.controller.registerToken(token);

    return state => state.merge({ token });
  } catch (err) {
    console.log('Unable to retrieve refreshed token ', err);
    setTokenSentToServer(false);
    return state => state.set('token', null);
  }
}

markAsSideEffect(registerToken);
export function registerToken(token) {
  const tokens = this.user.get('tokenDevices');
  if (!tokens || tokens.indexOf(token) === -1) {
    this.usersService.addDeviceToken({ token, deviceType: 'web' }, { query: { validateToken: true } }).catch(err => {
      if (err.type === 'device-token-already-registered') {
        const messaging = window.firebase.messaging();
        this.controller
          .deleteToken(messaging)
          .then(messaging.onTokenRefresh(() => this.controller.onTokenRefresh(messaging)));
      }
    });
  }
  if (!localStorage.getItem(DEVICE_TOKEN)) {
    localStorage.setItem(DEVICE_TOKEN, token);
    this.events.emit('notifications:save-token', token);
  }
}

export async function requestPermission() {
  return Notification.requestPermission().then(async permission => {
    if (permission !== 'granted') return K;
    const token = await messaging.getToken();
    return state => state.merge({ permission, token });
  });
}

export async function deleteToken(messaging) {
  try {
    const token = await messaging.getToken();
    await messaging.deleteToken(token);
    setTokenSentToServer(false);
  } catch (err) {
    console.log('Error retrieving Instance ID token. ', err);
  }
  return state => state.set('token', null);
}

function isTokenSentToServer() {
  console.log('local storage sent to server => ', window.localStorage.getItem('sentToServer') === '1');
  return global.localStorage.getItem('sentToServer') === '1';
}

function setTokenSentToServer(sent) {
  global.localStorage.setItem('sentToServer', sent ? '1' : '0');
}

async function waitFor(fn) {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (fn()) {
        clearInterval(interval);
        resolve();
      }
    }, 1000);
  });
}

import { Map, fromJS } from 'immutable';
import { FILTER_NAME } from './filter.name';
import * as yup from 'yup';
import moment from 'moment';

const dateSchema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf(['any', 'equal', 'from', 'missed'])
    .required(),
  dateEqual: yup
    .date()
    .transform((value, originaValue, context) => {
      // TODO: this method is neer triggering!
      // check to see if the previous transform already parsed the date
      if (context.isType(value)) return value;

      return moment(originaValue).toDate();
    })
    .typeError('Select a date')
    .when('type', {
      is: 'equal',
      then: schema => schema.required('Select a date'),
      otherwise: schema => schema.nullable(),
    }),
  dateFrom: yup
    .date()
    .typeError('Select a date')
    .when('type', {
      is: 'from',
      then: schema => schema.required('Select a date'),
      otherwise: schema => schema.nullable(),
    }),
  dateTo: yup
    .date()
    .typeError('Select a date')
    .when('type', {
      is: 'from',
      then: schema => schema.required('Select a date').min(yup.ref('dateFrom'), 'Invalid date'),
      otherwise: schema => schema.nullable(),
    }),
});

const jobFilterSchema = yup.object().shape({
  startDate: dateSchema,
  finishDate: dateSchema,
  duration: yup.object().shape({
    type: yup
      .mixed()
      .oneOf(['any', 'equal', 'more', 'less'])
      .required(),
    value: yup
      .number()
      // TODO: this transform is not working!!!
      // .transform((value, originalValue, context) => {
      //   // check to see if the previous transform already parsed the date
      //   if (context.isType(value)) return value;

      //   const parsed = Number.parseInt(originalValue, 10);

      //   return isNaN(parsed) ? null : parsed;
      // })
      .when('type', {
        is: 'any',
        then: schema => schema.nullable(),
        otherwise: schema =>
          schema
            // .nullable()
            .typeError('#')
            .min(1, 'min vaule 1')
            .max(999, 'max value 999'),
      }),
  }),
});

const VALIDATIONS = {
  [FILTER_NAME.JOB]: jobFilterSchema,
};

export const validate = filterName => filterState => {
  const schema = VALIDATIONS[filterName];
  if (!schema) return filterState; // nothing to do, there is no registered validation schema.

  try {
    const values = filterState.get('values').toJS();
    schema.validateSync(values, { strict: true, abortEarly: false });
    return filterState.remove('errors');
  } catch (err) {
    console.log(err);
    const errors = err.inner.reduce((memo, { path, type, message }) => memo.set(path, { type, message }), Map({}));
    return filterState.set('errors', fromJS(errors));
  }
};

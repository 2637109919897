import React from 'react';
import { SystemNotification } from '../SystemNotification';

const reg = /^(.*)(?:\s(?:added))(?:\s(?:to)\s)(.*).$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, null, message, null, null];
};

const builderLinkReg = /^(.*)(?:(?:jobs\/))(.*)(?:(?:\/task\/))(.*)$/;
const tradeLinkReg = /^(.*)(?:(?:job\/))(.*)(?:(?:\/))(.*)$/;
const parseLink = link => {
  const linkReg = link.includes('//builder.') ? builderLinkReg : tradeLinkReg;
  const result = (link || '').match(linkReg);
  return result || [null, null, '', ''];
};

export default ({ notification, controller, taskUrl }) => {
  const [, message, taskName] = parseNotification(notification.get('message'));
  const [, , jobId, taskId] = parseLink(notification.getIn(['data', 'link']));
  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={taskUrl({
        jobId,
        taskId,
      })}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
    >
      {`${message} added to `}
      {taskName ? <span className="text-dark">{taskName}</span> : null}
    </SystemNotification>
  );
};

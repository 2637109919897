import React from 'react';

export const TaskLoading = () => (
  <div className="mt-4">
    <svg width="549" height="696" viewBox="0 0 549 696" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="199" width="549" height="497" fill="#F9F9FA" />
      <rect x="34" y="144" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="263" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="343" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="122" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="241" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="321" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="400" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="497" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="594" width="73" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="51" width="297" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" width="137" height="12" rx="5" fill="#E0E2E7" />
      <rect x="34" y="422" width="426" height="12" rx="5" fill="#E0E2E7" />
      <rect x="35" y="517" width="426" height="12" rx="5" fill="#E0E2E7" />
      <rect x="35" y="614" width="426" height="12" rx="5" fill="#E0E2E7" />
      <rect x="205" y="143" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="205" y="262" width="126" height="12" rx="5" fill="#E0E2E7" />
      <rect x="205" y="121" width="72" height="12" rx="5" fill="#E0E2E7" />
      <rect x="205" y="240" width="72" height="12" rx="5" fill="#E0E2E7" />
      <rect x="375" y="239" width="145" height="12" rx="5" fill="#E0E2E7" />
      <rect x="375" y="143" width="145" height="12" rx="5" fill="#E0E2E7" />
      <rect x="375" y="121" width="84" height="12" rx="5" fill="#E0E2E7" />
      <rect x="375" y="51" width="145" height="12" rx="5" fill="#E0E2E7" />
    </svg>
  </div>
);

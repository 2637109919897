import React from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from '@tradetrax/web-common';

export function ShowRulesModal({ cancel, taskType, communityName, tab, ruleMap, isTrade }) {
  const path = getPath(tab, isTrade, ruleMap);
  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Rule for Future Jobs</Modal.Title>
          <span className="font-size-16">{communityName}</span>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="d-flex flex-column mb-4">
          <span className="font-weight-bold font-size-16 mb-2">Task Type</span>
          <span className="font-size-16">{taskType.get('name')}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="font-weight-bold font-size-16">{`${tab.charAt(0).toUpperCase() +
            tab.slice(1)} Assignee`}</span>
          <span className="font-size-16">{taskType.getIn(path)}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton onClick={() => cancel()}>OK</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

const getPath = (tab, isTrade, ruleMap) => {
  if (tab === 'account') {
    return ['rule', 'assigneeAccountName'];
  }
  if (isTrade) {
    return ['rule', ruleMap[tab].dataField, 'name'];
  }
  return ['rule', ruleMap[tab], 'name'];
};

// System Colors

export const red = '#fc5c4f';
export const red_30 = '#FFB8B2';
export const red400 = '#E34538';
export const green200 = '#ABF0DC';
export const green300 = '#06CA92';
export const green400 = '#18B186';
export const green_30 = '#BFE4DA';
export const blue50 = '#f0f1f7';
export const blue100 = '#ECF0FA';
export const blue300 = '#017CEE';
export const blue400 = '#0065C2';
export const blue_30 = '#CDDCF3'; //30%
export const blue_50 = '#F4F6FB'; //50%
export const pearl = '#F9F9FA';
export const yellow = '#F5F1C4';
export const yellow100 = '#FAD200';

export const gray = '#EDEDED';
export const gray50 = '#E0E2E7';
export const gray100 = '#EDEDED';
export const gray200 = '#C1C6CF';
export const gray300 = '#F9F9F9';
export const gray400 = '#6C6E7A';
export const gray500 = '#e0e2e7';
export const gray700 = '#495057';
export const gray800 = '#333333';
export const black = '#000000';
export const white = '#FFFFFF';
export const activeState = '#ECF0FA';
export const deactivated = '#CECDD2';
export const tableHover = '#F4F6FB';

export const colors = {
  green300,
  green400,
  green_30,
  blue50,
  blue100,
  blue300,
  blue400,
  blue_30,
  blue_50,
  red,
  red_30,
  red400,
  pearl,
  yellow,
  yellow100,
  gray,
  gray50,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray700,
  gray800,
  black,
  white,
  activeState,
  deactivated,
  tableHover,
};

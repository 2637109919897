import React from 'react';
import { calculateTimeLapse, ONE_MINUTE, mongoToText } from './utils';

export function useTimeAgo(value, formatFn = mongoToText) {
  const [timeAgo, setTimeAgo] = React.useState(value ? calculateTimeLapse(value) : '');

  React.useEffect(() => {
    setTimeAgo(value ? calculateTimeLapse(value, formatFn) : '');
    const id = setInterval(() => {
      setTimeAgo(value ? calculateTimeLapse(value, formatFn) : '');
    }, ONE_MINUTE);

    return () => clearInterval(id);
  }, [value, formatFn]);

  return timeAgo;
}

import { useController } from '../../useController';
import { serviceUrl } from '../../redirectToLogin';
import { Map } from 'immutable';

const filesServiceUrl = serviceUrl('files');
const actions = { uploadPhoto };
const emptyState = Map({
  isUploading: false,
  lastEventType: '',
});

export const PreviewModalContext = modalProps => {
  const [state, controller] = useController(actions, emptyState, modalProps);

  return { state, controller };
};

async function uploadPhoto(file) {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open('POST', `${filesServiceUrl}/files/${file.name}`, true);

  const handleEvent = e => {
    const isUploading = e.type !== 'abort' && e.type !== 'error';
    this.controller.dispatch([
      state =>
        state.merge({
          isUploading,
          lastEventType: e.type,
        }),
    ]);
  };

  xhr.addEventListener('loadstart', handleEvent);
  xhr.addEventListener('load', handleEvent);
  xhr.addEventListener('loadend', handleEvent);
  xhr.addEventListener('progress', handleEvent);
  xhr.addEventListener('error', handleEvent);
  xhr.addEventListener('abort', handleEvent);
  xhr.addEventListener('readystatechange', () => {
    const { status } = xhr;
    if (xhr.readyState === XMLHttpRequest.DONE && status > 199 && status < 300) {
      const attachment = JSON.parse(xhr.responseText);
      this.controller.dispatch([state => state.merge({ attachment })]);
    }
  });

  try {
    xhr.send(file);
  } catch (ex) {
    console.log({ ex });
  }

  return state =>
    state.merge({
      xhr,
      isUploading: true,
    });
}

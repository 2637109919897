import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { colors } from '../theme';
import { NotesController } from './NotesController';
import { AddNote } from './AddNote';
import { Note } from './Note';
import { NoteRemover } from './NoteRemover';
import { EmptyState } from '../EmptyState';
import { getUserDomain } from '../utils';

const EMPTY_STATE_MESSAGE = {
  true: 'When you add a Note it will appear here.',
  false: 'When a note is added to this Task it will appear here.',
};

export const Notes = ({ task, service, appContext, setNotesCount, visible }) => {
  const { controller, state } = NotesController(task, service, appContext);
  const [overlay, setOverlay] = React.useState({ show: false, target: null });
  const [showAll, setShowAll] = React.useState(false);
  const containerRef = React.useRef();
  const notes = state.get('notes');
  const isLoading = notes.get('isLoading');
  const user = appContext.appState.get('user');
  const canAddNote = React.useMemo(() => {
    const { isBuilder } = getUserDomain(user);
    return isBuilder || user.get('accountId') === task.getIn(['assigneeAccount', 'companyId']);
  }, [user, task]);

  const remove = note => ({ currentTarget }) => {
    setOverlay({
      target: currentTarget,
      show: currentTarget === overlay.target ? !overlay.show : true,
      note,
    });
  };

  const onRemove = () => {
    setOverlay({ ...overlay, show: false });
    setTimeout(() => {
      // we need to defer the removal to allow mini-modal to close.
      controller.removeNote(overlay.note);
    }, 500);
  };

  React.useEffect(() => {
    setNotesCount(isLoading ? 0 : notes.size);
  }, [setNotesCount, isLoading, notes.size]);

  const notesToRender = showAll ? notes : notes.takeLast(3);

  if (!visible) return null;

  if (isLoading) {
    return (
      <div className="d-flex ml-5 my-3">
        <Spinner animation="border" variant="secondary" size="lg" />
      </div>
    );
  }

  return (
    <NotesWrapper data-testid="section-task-notes">
      <StyledNotes ref={containerRef}>
        {notes.size > 3 && (
          <li className="text-center">
            <button onClick={() => setShowAll(!showAll)} className="btn btn-link">
              {showAll ? 'Hide Previous Notes' : `Show Previous Notes (${notes.size - 3})`}
            </button>
          </li>
        )}
        {!isLoading &&
          notesToRender.map(note => (
            <Note
              key={note.get('_id')}
              note={note}
              user={user}
              remove={remove(note)}
              isActive={overlay.show && overlay.note === note}
              companyId={task.getIn(['assigneeAccount', 'companyId'])}
            />
          ))}
        {notes.size === 0 && !isLoading && (
          <li className="pt-0">
            <EmptyState icon="note-sticky" title="No Notes Added" body={EMPTY_STATE_MESSAGE[canAddNote]} />
          </li>
        )}
      </StyledNotes>
      <NoteRemover {...{ overlay, setOverlay, onRemove, containerRef }} />
      {canAddNote && <AddNote state={state} controller={controller} user={user} />}
    </NotesWrapper>
  );
};

const StyledNotes = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 0.5rem;

    &:last-child {
      border-bottom: solid 1px ${colors.gray100};
    }

    > p {
      color: ${colors.gray800};
      font-size: 12px;
      letter-spacing: 0.1px;
      margin-top: 0.5rem;
    }
  }
`;

const NotesWrapper = styled.div`
  max-width: 640px;
  padding-left: 2rem;
`;

import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const UnstyledThumbnails = ({ className, index, setIndex, photos }) => {
  const [left, setLeft] = React.useState(0);
  const [prevIndex, setPrevIndex] = React.useState(-1);
  const thumbnailsRef = React.useRef();

  React.useEffect(() => {
    const thumbs = thumbnailsRef.current;
    const width = thumbs.offsetWidth;
    const thumbLeft = index * 100 + index * 40;
    const thumbRight = thumbLeft + 120;
    const minLeft = width - (photos.size * 140 + 20);
    const offset = (() => {
      switch (Math.floor(width / 120)) {
        case 0:
        case 1:
          return 0;
        case 2:
        case 3:
          return 140;
        default:
          return 280;
      }
    })();

    // when removing last photos
    if (width > photos.size * 140 + 20 + left) {
      setLeft(minLeft);
      return;
    }

    if (index === prevIndex) return;
    setPrevIndex(index);

    // is currently visible
    if (thumbLeft + left >= 0 && thumbRight + left <= width) return;

    // is hidden left
    if (thumbLeft + left < 0) {
      setLeft(Math.min(-thumbLeft + offset, 0));
      return;
    }

    // is last item selected
    if (index === photos.size - 1) {
      return;
    }

    // is hidden right
    if (thumbRight + left > width) {
      setLeft(Math.max(width - thumbRight - offset, minLeft));
      return;
    }
  }, [photos, thumbnailsRef, index, left, prevIndex, setLeft, setPrevIndex]);

  const scroll = direction => e => {
    e.preventDefault();
    const thumbs = thumbnailsRef.current;
    const width = thumbs.offsetWidth;
    const offset = (() => {
      switch (Math.floor(width / 120)) {
        case 0:
        case 1:
        case 2:
          return 140;
        case 3:
        case 4:
          return 280;
        default:
          return 420;
      }
    })();

    setPrevIndex(index);

    if (direction === 'left') {
      setLeft(Math.min(left + offset, 0));
    } else if (direction === 'right') {
      const minLeft = width - (photos.size * 140 + 20);
      setLeft(Math.max(left - offset, minLeft));
    }
  };

  return (
    <div className={`${className} d-flex flex-row justify-content-center`}>
      <button className="btn btn-link" onClick={scroll('left')}>
        <span aria-hidden="true" className="carousel-control-prev-icon"></span>
        <span className="sr-only">Previous</span>
      </button>
      <div ref={thumbnailsRef}>
        <ThumbnailsCarousel left={left}>
          {photos.map((photo, i) => (
            <Thumbnail
              data-testid="carousel-thumb-img"
              onClick={() => setIndex(i)}
              src={photo.get('thumbnailUrl')}
              className={cn({ active: i === index })}
              key={photo.get('_id')}
            />
          ))}
        </ThumbnailsCarousel>
      </div>
      <button className="btn btn-link" onClick={scroll('right')}>
        <span aria-hidden="true" className="carousel-control-next-icon"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export const Thumbnails = styled(UnstyledThumbnails)`
  & > div {
    // width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    height: 120px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;

    &:hover {
      text-decoration: none;
      outline: 0;
      opacity: 0.9;
    }
  }
`;

const ThumbnailsCarousel = styled.div`
  ${props => `
  left: ${props.left}px;
  display: flex;
  flex-direction: row;
  position: relative;
  transition: left 0.5s linear;
`}
`;

const Thumbnail = styled.img`
  height: 100px;
  border-radius: 8px;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.5;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    height: 120px;
    opacity: 1;
  }
`;

import React from 'react';
import { Initials } from './initials';

export const ProfileTag = ({ user }) => (
  <div className="d-flex flex-row justify-content-center align-items-center mb-4">
    <Initials name={user.initials || `${user.firstName} ${user.lastName}`} />
    <div className="d-flex flex-column justify-content-start w-75">
      <strong className="text-truncate">
        {user.firstName} {user.lastName}
      </strong>
      <span className="text-truncate font-size-14">{user.email}</span>
    </div>
  </div>
);

import React from 'react';
import { fromJS, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BannerButton } from '../Button';
import { useController, markAsSync } from '../useController';

export const emptyState = fromJS([]);

const actions = { add, close, pop, success, warning, error };

export const AlertsController = () => {
  const [alerts, controller] = useController(actions, emptyState);

  return React.useMemo(() => ({ alerts, ...controller }), [alerts, controller]);
};

let key = 0;
markAsSync(add, close, pop, success, warning, error);

function add(
  state,
  {
    variant = 'success',
    message,
    autoClose = true,
    isDismissible = true,
    timeout = 7,
    render = null,
    important = false,
  }
) {
  const { controller } = this;

  const item = Map({
    variant,
    message,
    isDismissible,
    key: key++,
    render,
    important,
  });

  if (state.size) {
    const current = state.get(0);
    if (!current.get('important')) state = state.remove(0);
  }
  if (autoClose) setTimeout(() => controller.close(item), timeout * 1000);
  return state.push(item);
}

function close(state, item) {
  const index = state.indexOf(item);
  if (index === -1) return state;
  return state.remove(index);
}

function pop(state) {
  return state.pop();
}

function success(state, props) {
  return add.call(this, state, { variant: 'success', ...props });
}

function error(state, props) {
  return add.call(this, state, { variant: 'danger', ...props });
}

function warning(state, props) {
  return add.call(this, state, { variant: 'warning', ...props });
}

export function showAcceptCookiesBanner(alert, { acceptCookies }) {
  alert.add({
    variant: 'primary',
    autoClose: false,
    isDismissible: false,
    important: true,
    message: (
      <div className="d-flex flex-row align-items-center">
        <FontAwesomeIcon icon="cookie-bite" className="mr-1" />
        <b className="mr-1">We use cookies</b>to ensure that you have the best experience on our site.
        <BannerButton className="ml-1 font-size-12" onClick={acceptCookies}>
          ACCEPT
        </BannerButton>
      </div>
    ),
  });
}

import React from 'react';
import styled from 'styled-components';

export const defaultProps = {
  width: 176,
  height: 29,
  color: 'white',
  backgroundColor: 'none',
};

export const TradeTrax = ({ width, height, color, backgroundColor }) => (
  <svg width={width} height={height} viewBox="0 0 176 29" fill={backgroundColor} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1047 5.70839V0.638184H0.791992V5.70839H9.00748L9.01377 28.2035H14.9206V5.70839H23.1047Z"
      fill={color}
    />
    <path
      d="M34.3335 7.5641C34.3146 7.55781 33.8868 7.44458 32.9307 7.44458C30.5151 7.44458 29.106 8.15541 27.8919 10.0237V7.86605H22.6267V28.1972H28.1939V20.6359C28.1939 18.214 28.3134 16.3269 28.8984 14.9807C29.5463 13.4772 30.7353 12.779 32.6224 12.779C33.6604 12.779 34.2643 12.8922 34.2706 12.8922L34.4341 12.9237V7.58926L34.3335 7.5641Z"
      fill={color}
    />
    <path
      d="M52.142 8.67758C50.7518 7.78432 48.7199 7.36914 45.7445 7.36914C40.6806 7.36914 37.2522 8.51403 36.9691 14.0875L36.9628 14.2322H42.2155L42.2343 14.1126C42.4734 12.5777 42.8571 11.9109 45.5243 11.9109C48.0091 11.9109 48.808 12.4582 48.808 14.1692V15.2701L42.3035 16.2829C39.5923 16.7044 36.3652 18.2141 36.3652 23.0075C36.3652 24.8632 36.8559 26.2283 37.8624 27.1719C38.9632 28.2035 40.6491 28.7005 43.0081 28.7005C46.3798 28.7005 48.1789 27.6437 49.0344 26.8699V28.2035H54.3751V13.3766C54.3626 11.1749 53.6391 9.64004 52.142 8.67758ZM44.9015 24.4984C42.7816 24.4984 41.9198 23.8567 41.9198 22.2778C41.9198 20.2271 43.3037 19.8559 45.2664 19.5603L48.7954 18.9752V20.4095C48.8017 23.2717 47.6316 24.4984 44.9015 24.4984Z"
      fill={color}
    />
    <path
      d="M72.1272 0.631897V9.3695C70.932 8.04219 69.007 7.3691 66.4027 7.3691C60.5965 7.3691 57.8916 10.7409 57.8916 17.975C57.8916 21.4537 58.5207 24.0014 59.8165 25.7753C61.2382 27.7191 63.421 28.6753 66.4782 28.6941C69.0825 28.6941 70.9949 28.3293 72.3159 26.5931V28.1972H77.6943V0.631897H72.1272ZM67.9691 24.0769C65.0188 24.0769 63.616 23.1522 63.616 17.8995C63.616 13.4206 64.698 11.9864 68.0823 11.9864C69.6864 11.9864 70.6363 12.3387 71.2716 13.1564C71.9636 14.056 72.2844 15.5972 72.2844 18.0128C72.2844 23.1396 70.8313 24.0769 67.9691 24.0769Z"
      fill={color}
    />
    <path
      d="M91.1372 7.36914C87.2307 7.36914 84.5698 8.36934 83.0035 10.4264C81.7391 12.0871 81.1792 14.3643 81.1792 17.8241C81.1792 22.0576 81.9781 24.8192 83.6891 26.5114C85.2115 28.0211 87.4383 28.6942 90.9044 28.6942C93.7666 28.6942 95.874 28.2287 97.346 27.2788C98.9249 26.2534 99.8496 24.6368 100.177 22.3344L100.202 22.1771H94.5404L94.5089 22.2715C94.0497 23.5925 93.0621 24.0832 90.873 24.0832C87.9416 24.0832 87.1049 23.1648 86.9099 19.642H100.416V19.5037C100.409 10.4264 98.0694 7.36914 91.1372 7.36914ZM90.873 11.9801C93.2445 11.9801 94.5907 12.6281 94.836 16.0061H86.9477C87.1553 12.8545 88.0171 11.9801 90.873 11.9801Z"
      fill={color}
    />
    <path
      d="M121.068 5.7021V0.62561L98.7551 0.631901V5.7021H106.977V28.1909H112.89L112.884 5.7021H121.068Z"
      fill={color}
    />
    <path
      d="M132.303 7.55781C132.284 7.55152 131.856 7.43829 130.9 7.43829C128.485 7.43829 127.075 8.14913 125.861 10.0174V7.85976H120.596V28.1909H126.163V20.6296C126.163 18.2078 126.283 16.3206 126.868 14.9744C127.516 13.471 128.705 12.7727 130.592 12.7727C131.63 12.7727 132.234 12.8859 132.24 12.8859L132.404 12.9174V7.58298L132.303 7.55781Z"
      fill={color}
    />
    <path
      d="M150.105 8.67123C148.715 7.77797 146.683 7.36279 143.707 7.36279C138.643 7.36279 135.215 8.50768 134.932 14.0811L134.926 14.2258H140.178L140.197 14.1063C140.436 12.5714 140.82 11.9046 143.487 11.9046C145.972 11.9046 146.771 12.4519 146.771 14.1629V15.2638L140.266 16.2765C137.555 16.698 134.328 18.2077 134.328 23.0012C134.328 24.8569 134.819 26.2219 135.825 27.1655C136.926 28.1972 138.612 28.6941 140.971 28.6941C144.343 28.6941 146.142 27.6373 146.997 26.8636V28.1972H152.338V13.3703C152.332 11.1686 151.602 9.63369 150.105 8.67123ZM142.871 24.4857C140.751 24.4857 139.889 23.8441 139.889 22.2652C139.889 20.2144 141.273 19.8433 143.236 19.5476L146.765 18.9626V20.3969C146.765 23.2654 145.601 24.4857 142.871 24.4857Z"
      fill={color}
    />
    <path
      d="M168.41 17.7359L175.204 7.85345H168.624L165.24 13.4709L161.887 7.85345H155.081L161.874 17.8114L154.816 28.1909H161.472L165.083 22.2274L168.7 28.1909H175.55L168.41 17.7359Z"
      fill={color}
    />
  </svg>
);

TradeTrax.defaultProps = defaultProps;

import React from 'react';
import { useController } from '../useController';
import { Map } from 'immutable';
import * as actions from './AlbumActions';
import { getUserDomain } from '../utils';

export const ATTACHMENTS_VIEW = 'ATTACHMENTS_VIEW';
export const ATTACHMENTS_JOB_VIEW = 'ATTACHMENTS_JOB_VIEW';
import { useChannel, CHANNELS } from '../useRealTime';

const emptyState = Map({
  attachments: Map({ isLoading: true }),
  documents: Map({ isLoading: true }),
  isCarouselOpen: false,
  currentPhotoIndex: -1,
  previewDocument: null,
  view: 'grid',
  activeFilter: 'all',
});

export const AlbumController = (task, job, service, appContext, isDashboard = false) => {
  const [state, controller] = useController(actions, emptyState, { service, task, job, info, ...appContext });
  const taskId = task?.get('id');
  const prevJobRef = React.useRef();

  React.useEffect(() => {
    const prevJob = prevJobRef.current;

    controller.loadAttachmentsView();

    if (isDashboard) {
      controller.readAttachments();
    } else {
      if (task) {
        controller.readTaskAndJobAttachments();
      }
      if (job) {
        if (prevJob && prevJob.get('_id') === job?.get('_id')) return;
        prevJobRef.current = job;
        controller.readJobAttachments(job.get('_id'));
      }
    }
  }, [controller, taskId, job]);

  if (task) {
    const taskId = task.get('id');
    const jobId = task.getIn(['job', 'id']);

    const refreshAttachments = React.useMemo(
      () => ({ action }) => action.includes('attachment') && controller.readAttachments(),
      [controller, jobId, taskId]
    );

    useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, refreshAttachments);
  }

  if (job) {
    const jobId = job.get('_id');

    const refreshAttachments = React.useMemo(
      () => ({ action }) => action.includes('attachment') && controller.readAttachments(),
      [controller, jobId]
    );

    useChannel(CHANNELS.JOB, `${jobId}`, refreshAttachments);
  }

  return { state, controller };
};

function info() {
  const taskId = this.task?.get('id');
  const jobId = this.task?.getIn(['job', 'id']);
  const user = this.appState.get('user');
  return { user, taskId, jobId };
}

export const getCanRemoveAndDownload = (user, task) => attachment => {
  const { isBuilder } = getUserDomain(user);
  const uploaderIsTrade = attachment.get('createdByAccountId') === user.get('accountId');
  const isSelfAccountAssigned = user.get('accountId') === task?.getIn(['assigneeAccount', 'companyId']);
  const canRemove = isBuilder || (attachment.get('taskId') && isSelfAccountAssigned && uploaderIsTrade);
  const canDownload = isBuilder || !task || isSelfAccountAssigned;
  return {
    canRemove,
    canDownload,
  };
};

import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { isEqual } from 'lodash';
import { FilterListView } from './FilterListView';
import { FilterShareView } from './FilterShareView';
import { EditAccessView } from './EditAccessView';
import { ShareFilterSetContext, LIST_VIEW, SHARE_VIEW, EDIT_VIEW } from './ShareFilterSetContext';
import { getUserDomain } from '../../../utils';

export const FilterSetsManagementModal = ({ accept, cancel, filterSets: original, ...appContext }) => {
  const { state, controller } = ShareFilterSetContext(appContext, original);
  const { toShare, filterView, filterSets } = state.toObject();
  const { isTrade } = getUserDomain(appContext.appState.get('user'));

  return (
    <StyledModal show={true} onHide={() => cancel()} size="lg" addbottom={toShare ? 1 : 0}>
      {filterView === LIST_VIEW && (
        <FilterListView
          accept={accept}
          state={state}
          isDisabled={isEqual(filterSets, original)}
          controller={controller}
          isTrade={isTrade}
        />
      )}
      {filterView === SHARE_VIEW && <FilterShareView state={state} controller={controller} />}
      {filterView === EDIT_VIEW && <EditAccessView state={state} controller={controller} />}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${({ addbottom }) => `
    .modal-content {
      min-height: ${addbottom ? '30rem' : ''};
    }
  `}
`;

import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Initials } from './initials';
export { ProfileTag } from './ProfileTag';
export { Initials, initials } from './initials';
export { MiniProfile, MiniProfileModal, NullMuted } from './MiniProfile';
export { IconBadge } from './IconBadge';
import { colors } from '../theme';

export const UsersTags = ({ users, title, className = '', maxTags, isClickEnabled = false, onShowMore = () => {} }) => {
  const names = users?.map(user => user.name);
  const numberOfItems = names.length === 1 ? 1 : maxTags;
  const isOnlyOneDeactiveUser = users.length === 1 && users[0].status === 'inactive';

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isClickEnabled) onShowMore(e);
  };

  return (
    <Wrapper className={`d-flex flex-row ${className}`} hasHover={isClickEnabled ? 1 : 0}>
      <Content className="d-flex flex-column">
        <small className="text-muted">{title}</small>
        <div
          className={cn('d-flex flex-row click-area', { 'align-items-center': numberOfItems === 1 })}
          onClick={onClick}
        >
          {isOnlyOneDeactiveUser ? (
            <div className="d-flex align-items-center mt-2" style={{ maxWidth: '11rem' }}>
              <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />
              <span className="text-gray-200 text-truncate">{users[0].name}</span>
            </div>
          ) : (
            <>
              {names.length > 0 && <Tags users={users} size={32} numberOfItems={numberOfItems} />}
              <div className="d-flex flex-column ml-2">
                {names.length === 0 && <span className="text-gray-200 not-set">Not Set</span>}
                {names.map((name, index) => {
                  if (index === 2)
                    return (
                      <Button key={index} variant="link" className="d-flex px-0 py-0" onClick={e => onShowMore(e)}>
                        and more...
                      </Button>
                    );
                  return (
                    <span
                      key={index}
                      className={cn('text-truncate', { 'text-gray-200': users[index].status === 'inactive' })}
                      style={{ maxWidth: '8rem' }}
                    >
                      {name}
                    </span>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  small {
    line-height: 19px;
  }

  span {
    line-height: 22px;
  }
`;

const Wrapper = styled.div`
  ${({ hasHover }) => `
  &.space-between-tags {
      .not-set {
        margin-top: 0.45rem;
      }
    }
    .click-area:hover {
      background-color: ${hasHover ? colors.pearl : ''};
      border-radius: 5px;
    }
 `}
`;

const UnstyledTags = ({ className, users, numberOfItems }) => (
  <div className={`${className}`}>
    {users.slice(0, numberOfItems).map((user, index) => (
      <Tag
        size={32}
        name={user.initials || user.name}
        index={index}
        key={index}
        count={numberOfItems || users.length}
      />
    ))}
  </div>
);

const Tags = styled(UnstyledTags)`
  ${props => `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: ${(props.numberOfItems || props.names.length) + 1 + 'rem'};
  `}
`;

export const Tag = styled(Initials)`
  ${props => `
    transform: translateX(${((props.count - 1 - props.index) * 2) / 2 + 'rem'});
    z-index: ${props.count - props.index};
    margin-right: 0;
  `}
`;

export const UserNameLabel = ({ children, className = '', isInactive, hasPermission = true, maxWidth = '180px' }) => {
  const classes = isInactive ? '' : className;
  return (
    <span
      style={{ maxWidth }}
      className={cn(`${classes} text-nowrap text-truncate `, {
        'deactivated-user px-0': isInactive,
        'not-permission': !hasPermission,
        assignee: !classes.includes('assignee'),
      })}
    >
      {isInactive && <FontAwesomeIcon icon="ban" className="mr-1" />}
      {children || 'Needs Assignment'}
    </span>
  );
};

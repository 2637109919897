import React from 'react';
import { fromJS, List } from 'immutable';
import { isEqual } from 'lodash';
import { markAsSync, useController } from '../../useController';

export const LIST_VIEW = 'LIST_VIEW';
export const EDIT_VIEW = 'EDIT_VIEW';

const emptyState = fromJS({
  view: EDIT_VIEW,
  selected: [],
  isSaveDisabled: true,
  hideBackButton: true,
});

const actions = { setListView, setEditView, addAreaManager, toggleDisableButton };

export function MultipleAssigneeContext(community, { initView, ...props }) {
  const hideBackButton = initView === EDIT_VIEW;
  const areaManagers = community.get('areaManagers').toJS();
  const initState = emptyState
    .set('view', initView)
    .set('selected', List(areaManagers))
    .set('hideBackButton', hideBackButton);
  const [state, controller] = useController(actions, initState, { community, ...props });
  const selected = state.get('selected').toJS();
  const isListUpdated = !isEqual(selected, areaManagers);

  React.useEffect(() => {
    controller.toggleDisableButton(isListUpdated);
  }, [isListUpdated]);

  return { state, controller };
}

markAsSync(setEditView);
function setEditView(state) {
  return state.set('view', EDIT_VIEW);
}

markAsSync(setListView);
function setListView(state) {
  const selected = this.community.get('areaManagers').toJS();
  return state.set('view', LIST_VIEW).set('selected', List(selected));
}

markAsSync(addAreaManager);
function addAreaManager(state, assignees) {
  return state.set('selected', List(assignees));
}

markAsSync(toggleDisableButton);
function toggleDisableButton(state, isListUpdated) {
  return state.set('isSaveDisabled', !isListUpdated);
}

import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '../../useController';
import { filterSetActions } from './FilterSets.actions';
import { hasChanges, resetFilter, isfiltering } from '../Filter.shared.utils';
import { validate } from './Filter.validations';

export function filterCommonActions(filterName, emptyFilter, FilterSetsAdapter) {
  const filterSetsAdapter = FilterSetsAdapter ? new FilterSetsAdapter() : null;
  const filterSetsPath = emptyFilter.get('filterSetType');
  const emptyFilterValues = emptyFilter.get('values');
  let filtersService = null;

  markAsSideEffect(setFiltersService);
  function setFiltersService(service) {
    filtersService = service;
    if (filterSetsAdapter) {
      filterSetsAdapter.filtersService = service;
    }
  }

  markAsSync(initialize);
  function initialize(state) {
    if (state.getIn([filterName, 'initialized'])) return state;
    return state.setIn([filterName, 'initialized'], true);
  }

  markAsSync(setCollapsedSection);
  function setCollapsedSection(state, section, isCollapsed) {
    return state.setIn([filterName, 'collapsed', section], isCollapsed);
  }

  // TODO: deprecate...
  markAsSync(selectFilterSet);
  function selectFilterSet(state, filterSetId) {
    // TODO: copy filter set values
    return state.setIn([filterName, 'filterSetId'], filterSetId);
  }

  // TODO: depreacate this method in favor of updateFieldValue
  // @param isSessionValue. this value will be stored on context.state but it won't be
  // persisted/sent to BE (user/settings/filters).
  markAsSync(onFilterFieldChange);
  function onFilterFieldChange(state, field, value, isSessionValue = false) {
    if (isSessionValue) return state.setIn([filterName, 'sessionValues', field], value);

    const newValues = state.getIn([filterName, 'values']).set(field, value);
    const filterSets = state.get(filterSetsPath);

    this.controller[filterName].defer.saveFilterSettings();

    return state
      .updateIn([filterName, 'values'], values => values.merge(newValues))
      .setIn([filterName, 'isFiltering'], isfiltering(emptyFilterValues, newValues))
      .update(filterName, hasChanges(filterSets));
  }

  // persisted/sent to BE (user/settings/filters).
  markAsSync(updateFieldValue);
  function updateFieldValue(state, field, value, isSessionValue = false) {
    if (isSessionValue) return state.setIn([filterName, 'sessionValues', field], value);

    const newValues = state.getIn([filterName, 'values']).set(field, value);
    const filterSets = state.get(filterSetsPath);

    this.controller[filterName].defer.saveFilterSettings();

    return state
      .setIn([filterName, 'values'], newValues)
      .setIn([filterName, 'isFiltering'], isfiltering(emptyFilterValues, newValues))
      .update(filterName, hasChanges(filterSets))
      .update(filterName, validate(filterName));
  }

  markAsSync(reset);
  function reset(state, values = emptyFilterValues) {
    this.controller[filterName].defer.saveFilterSettings();
    return state.update(filterName, resetFilter(fromJS(values), values !== emptyFilterValues, false));
  }

  markAsSideEffect(saveFilterSettings);
  function saveFilterSettings() {
    if (this.state.hasIn([filterName, 'errors'])) {
      console.log("can't save filter settings if there is a validation error!");
      return;
    }

    const filter = this.state.getIn([filterName, 'values']).toJS();
    return filtersService.updateFilterSettings(filter, { params: { filterName } });
  }

  const actions = {
    initialize,
    setFiltersService,
    onFilterFieldChange,
    updateFieldValue,
    reset,
    setCollapsedSection,
    saveFilterSettings,
  };

  if (filterSetsAdapter) {
    actions.filterSets = filterSetActions(filterName, filterSetsAdapter, emptyFilter);
  }

  return actions;
}

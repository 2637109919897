import { useEffect } from 'react';
import moment from 'moment';
import { fromJS, Map } from 'immutable';
import { useController } from '../useController';
import { useEvents } from '../useEvents';
import * as actions from './push-notifications';

export const DEVICE_TOKEN = 'DEVICE_TOKEN';
const isLocalhost = global.location.hostname === 'localhost' || global.location.hostname.includes('.local.tradetrax');
const isHttps = global.location.protocol === 'https:';

const YESTERDAY = moment().subtract(1, 'days');
const START_OF_WEEK = moment().startOf('week');
const START_OF_LAST_WEEK = moment()
  .startOf('week')
  .subtract(7, 'days');
const isToday = date => date.isSame(moment(), 'day');
const isYesterday = date => date.isSame(YESTERDAY, 'day');
const isThisWeek = date => date.isBetween(START_OF_WEEK, YESTERDAY, 'days', '[)');
const isLastWeek = date => date.isBetween(START_OF_LAST_WEEK, START_OF_WEEK, 'days', '[)');

export const emptyState = fromJS({
  notifications: Map(),
  token: null,
  visible: false,
  tab: 'all',
});

export const NotificationsController = (service, usersService, user, alert) => {
  const events = useEvents();
  const [state, controller] = useController(actions, emptyState, { service, usersService, user, alert, events });

  useEffect(() => {
    controller.readNotifications();
    if (isLocalhost || isHttps) {
      setTimeout(() => {
        controller.initializeMessaging();
      }, 1000);
    }
  }, [controller]);

  return [state, controller];
};

export const mapDate = notifications =>
  notifications
    .map(n => {
      const date = moment(n.get('createdAt'));
      if (isToday(date)) return n.set('group', 'Today');
      if (isYesterday(date)) return n.set('group', 'Yesterday');
      if (isThisWeek(date)) return n.set('group', 'This Week');
      if (isLastWeek(date)) return n.set('group', 'Last Week');
      return n.set('group', 'Earlier');
    })
    .groupBy(n => n.get('group'));

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChipStatusDropdown, STATUS_MAP } from '../ChipStatus';
import { UsersTags } from '../Tags';
import { PrimaryButton, IconButton } from '../Button';
import { fromJS, List } from 'immutable';
import { StatusDataOnTime } from '../OnTime';
import { mongoToTrx, plural, COMPLETED } from '../utils';
import cn from 'classnames';
import styled from 'styled-components';

export const defaultJob = fromJS({
  _id: 1,
  name: '2271 N Cottonwood Basin',
  templateName: 'Template Name',
  lotNumber: 'LOT #2340',
  onTime: -20,
  status: 'in-progress',
  address: '2321 Henry Clower Blvd SW, Snellville, GA 30078, United States',
  schedulers: ['Bruse Fisher', 'Shane Robertson', 'Luis Rodrigo'],
  supers: ['Bruce Wayne', 'Luisa Lane'],
  statusData: {
    // daysDifference: 1,
    // targetStatus: 'on-schedule',
    actualStart: '2020-06-02T00:00:00.000Z',
    actualFinish: null,
    targetStart: '2021-03-17T00:00:00.000Z',
    targetFinish: '2021-03-17T00:00:00.000Z',
    cycleTime: 273,
    targetCycleTime: 0,
    daysDifference: 273,
    targetStatus: 'behind',
  },
});

export const JobHeader = ({ className, job, ...props }) => {
  return (
    <div className={className}>
      <div className="d-flex flex-row align-items-center mr-auto">
        <span className="h4 mb-0 font-weight-bold">{job.get('name')}</span>
        <ChipStatusDropdown
          {...STATUS_MAP[job.get('status')]}
          progress={job.get('status') !== 'in-progress'}
          completed={job.get('status') !== 'completed'}
          className="mx-2"
          onSelect={props.onSelectStatus}
          disabled={!props.canChangeJobStatus}
        />
        <StatusDataOnTime data={job.get('statusData')} />
        <PrimaryButton
          disabled={!props.isReadyForRelease || props.isReleased}
          variant="primary"
          className="rounded-pill ml-auto mt-n5"
          onClick={props.onReleaseClick}
        >
          {props.isReleased ? 'Released' : 'Release'} to construction
        </PrimaryButton>
        <br />
      </div>
      <JobDatesHeader job={job} props={props} />
    </div>
  );
};

const MAPS = {
  'not-started': [
    ['es', 'ef', 'ect'],
    ['ts', 'tf', 'tct'],
    ['as', 'af', 'act'],
  ],
  'in-progress': [
    ['as', 'tf', 'ect'],
    ['es', 'ef', 'tct'],
    ['ts', 'af', 'act'],
  ],
  completed: [
    ['as', 'af', 'act'],
    ['ts', 'tf', 'tct'],
    ['es', 'ef', 'ect'],
  ],
};

const JobDatesHeader = ({ job, props }) => {
  const [collapsed, setCollapsed] = useState(true);
  const date = (...path) => {
    const value = job.getIn(path);
    return value ? mongoToTrx(value) : null;
  };
  const days = (...path) => {
    const value = parseInt(job.getIn(path));
    return isNaN(value) ? null : plural.day(value);
  };
  const templateName = job.get('templateName');
  const supers = job.get('supers') || List();
  const schedulers = job.get('schedulers') || List();
  const jobStatus = job.get('status');
  const map = MAPS[jobStatus || 'not-started'];
  const dates = {
    es: ['Expected Start', date('startDate')],
    ef: ['Expected Finish', date('expectedFinishDate')],
    as: ['Actual Start', date('statusData', 'actualStart')],
    af: ['Actual Finish', date('statusData', 'actualFinish')],
    tf: ['Target Finish', date('statusData', 'targetFinish')],
    ts: ['Target Start', date('statusData', 'targetStart')],
    ect: ['Exp. Cycle Time', days('statusData', 'cycleTime')],
    tct: ['Target Cycle Time', days('statusData', 'targetCycleTime')],
    act: ['Actual Cycle Time', jobStatus === COMPLETED ? days('statusData', 'cycleTime') : null],
  };
  const Dates = ({ row }) =>
    row.map(key => {
      const [title, date] = dates[key];
      return <TargetDate title={title} date={date} key={key} />;
    });

  return (
    <div className="d-flex flex-column mt-2">
      <div className="d-flex flex-row mt-2">
        <Dates row={map[0]} />
        <TargetDate
          title="LOT #"
          date={job.get('lotNumber') || null}
          style={collapsed ? {} : { paddingLeft: `4rem` }}
          className={collapsed ? undefined : 'w-14rem mr-4'}
          truncate
        />
        <TargetDate
          title="Square Ft."
          date={job.get('squareFootage') || null}
          className={collapsed ? undefined : 'pl-5 mr-5 w-8rem'}
        />
        <div className="d-flex flex-row ml-auto">
          <IconButton
            icon={`angles-${collapsed ? 'down' : 'up'}`}
            className={cn({ active: !collapsed })}
            onClick={() => setCollapsed(!collapsed)}
          />
          <IconButton
            icon="gear"
            className={cn('ml-3', { active: props.isSettingsOpen })}
            onClick={() => props.setOpenSettings(!props.isSettingsOpen)}
          />
        </div>
      </div>
      <div className={`${collapsed ? 'd-none' : 'd-flex flex-row'} mt-2`}>
        <Dates row={map[1]} />
        <UsersTags
          className="mr-4 w-14rem"
          maxTags={2}
          title="Super Assigned"
          names={supers.toArray().slice(0, 3)}
          onShowMore={props.onShowMoreUsers}
        />
        <UsersTags className="w-14rem" title="Created by" names={[job.get('createdByUserName')]} />
      </div>
      <div className={`${collapsed ? 'd-none' : 'd-flex flex-row'} mt-2`}>
        <Dates row={map[2]} />
        <UsersTags
          className="mr-4 w-14rem"
          maxTags={2}
          title="Scheduler Assigned"
          names={schedulers.toArray().slice(0, 3)}
          onShowMore={props.onShowMoreUsers}
        />
        <div className="d-flex flex-column w-14rem pl-5">
          <small className="text-muted">Template</small>
          {props.isTemplateActive && (
            <Link className="" to={props.templateLink}>
              {templateName || '- - -'}
            </Link>
          )}
          {!props.isTemplateActive && (
            <span className="text-gray-200 w-auto text-truncate">{templateName || '- - -'}</span>
          )}
        </div>
      </div>
    </div>
  );
};

JobHeader.defaultProps = {
  isReleased: false,
  isReadyForRelease: false,
  onReleaseClick: e => e.stopPropagation(),
  onSelectStatus: () => {},
};

export const TargetDate = ({ title, date, truncate, className = 'mr-5 w-8rem', ...rest }) => (
  <div className={`d-flex flex-column ${className}`} {...rest}>
    <small className="text-muted">{title}</small>
    <span className={cn({ 'text-gray-200': date === null, 'w-auto text-truncate': truncate })}>
      {date === null ? 'Not Set' : date}
    </span>
  </div>
);

export const JobHeaderDataContainer = styled.div`
  height: ${props => (props.collapsed ? '50px' : 'auto')};
  width: auto;
  max-width: 1050px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1256px) {
    height: ${props => {
      if (props.collapsed) {
        return props.isBuilder ? '95px' : '50px';
      }
      return 'auto';
    }};
  }

  & > * {
    max-width: 150px;
    width: 150px;
    margin-bottom: 10px;
    margin-right: 1rem !important;
  }
  @media (min-width: 992px) {
    & > * {
      margin-right: 2rem !important;
    }
  }
`;

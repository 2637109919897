import React from 'react';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { SecondaryButton } from '../Button';

export const NoteRemover = ({ overlay, setOverlay, onRemove, containerRef }) => {
  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.id === 'popover-remove-note') return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (overlay.show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setOverlay({ ...overlay, show: false });
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [overlay, setOverlay]);

  return (
    <Overlay
      show={overlay.show}
      target={overlay.target}
      placement="bottom-end"
      container={containerRef.current}
      containerPadding={20}
    >
      <Popover id="popover-remove-note" style={{ maxWidth: 355, borderRadius: 8 }} className="p-3">
        <Popover.Content className="pb-0">
          <p>Once removed, notes cannot be recovered.</p>
          <div className="d-flex flex-row  justify-content-end">
            <Button
              variant="link"
              className="text-secondary font-weight-bold mr-3"
              onClick={() => setOverlay({ ...overlay, show: false })}
            >
              Cancel
            </Button>
            <SecondaryButton variant="outline-primary" onClick={onRemove}>
              Remove
            </SecondaryButton>
          </div>{' '}
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

import React from 'react';

export const useAutoFocus = () => {
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return inputRef;
};

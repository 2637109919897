import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from './Badge';

export const defaultProps = {};
export const propTypes = {};

const getTotalCount = (section, counter, isTrade = false) => {
  if (!counter) return undefined;

  const todoCount = isTrade ? 'startDateConfirmationRequestCount' : 'toDoUpdateCount';
  if (section === 'Dashboard')
    return (
      counter.get('overdueCount') +
      counter.get('requestCount') +
      counter.get('toDoEndCount') +
      counter.get('toDoStartCount') +
      (counter.get(todoCount) || 0)
    );
  if (section === 'To-Dos') {
    return counter.get('toDoEndCount') + counter.get('toDoStartCount') + counter.get(todoCount);
  }
  return counter;
};

export const SideBarLink = ({ children, icon, badge, isTrade, ...props }) => {
  const totalCount = getTotalCount(children, badge, isTrade);
  return (
    <Nav.Item>
      <Nav.Link {...props} as={NavLink}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <span>{children}</span>
        <Badge badge={totalCount} />
      </Nav.Link>
    </Nav.Item>
  );
};

SideBarLink.propTypes = propTypes;
SideBarLink.defaultProps = defaultProps;
export default SideBarLink;

import { fromJS } from 'immutable';
import { useController } from '../../../useController';
import * as actions from './FilterModalActions';

export const LIST_VIEW = 'LIST_VIEW';
export const SHARE_VIEW = 'SHARE_VIEW';
export const EDIT_VIEW = 'EDIT_VIEW';

const emptyState = fromJS({
  selected: [],
  toShare: null,
  lastSharedIndex: null,
  sharedWith: [],
  filterView: LIST_VIEW,
  backButton: LIST_VIEW,
  userToDelete: {},
  shareOptions: [],
});

export const ShareFilterSetContext = (appContext, filterSets) => {
  const { appState } = appContext;
  const isManager = appState.getIn(['user', 'role']) === 'manager';
  const initialState = emptyState.set('filterSets', filterSets).set('isManager', isManager);

  const [state, controller] = useController(actions, initialState, appContext);
  return { state, controller };
};

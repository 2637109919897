import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import { CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry } from 'react-virtualized';
import { colors } from '../../theme';
import { isUserInactive, scrollbarStyles } from '../../utils';

export const GridItem = ({ style, attachment, showCarousel, accountUsers }) => {
  const { createdByUserId } = attachment.toObject();
  const isInactive = isUserInactive(createdByUserId, accountUsers);
  const { thumbnailUrl, createdBy, name } = attachment.toObject();
  const isDocument = attachment.get('contentType') === 'application/pdf';
  const icon = isDocument ? 'file' : 'image';
  const onClick = e => {
    e.preventDefault();
    showCarousel(attachment);
  };
  return (
    <ItemContainer className="d-flex flex-column" style={style} onClick={onClick} data-testid="thumbnail-attachment">
      <Thumbnail src={thumbnailUrl} />
      <Info className="d-flex flex-column p-2 item-info">
        <span className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={icon} className="text-muted mr-2 font-size-14" />
          <div className="font-weight-bold font-size-14 w-100 text-truncate">{name}</div>
        </span>

        <div className="d-flex justify-content-end">
          {isInactive && <FontAwesomeIcon icon="ban" className="ml-4 mr-1 text-danger muted" />}
          <span
            className={cn(' font-size-12 w-100 text-truncate text-gray-200', { 'ml-4 pr-4 text-muted': !isInactive })}
          >
            {createdBy}
          </span>
        </div>
      </Info>
    </ItemContainer>
  );
};

export function GridView({ context: { controller, state }, appContext }) {
  const accountUsers = appContext.account.get('users');
  const attachments = state.get('attachments');

  const cache = React.useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 250,
        defaultWidth: 180,
        fixedWidth: true,
      }),
    []
  );

  const cellPositioner = React.useMemo(
    () =>
      createMasonryCellPositioner({
        cellMeasurerCache: cache,
        columnCount: 5,
        columnWidth: 180,
        spacer: 24,
      }),
    [cache]
  );

  const cellRenderer = ({ index, key, parent, style }) => {
    const attachment = attachments.get(index);
    if (!attachment) return null;
    return (
      <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
        <GridItem
          style={style}
          attachment={attachment}
          showCarousel={controller.showCarousel}
          accountUsers={accountUsers}
        />
      </CellMeasurer>
    );
  };

  if (attachments.get('isLoading')) {
    return (
      <div className="d-flex ml-5 my-3">
        <Spinner animation="border" variant="secondary" size="lg" />
      </div>
    );
  }

  return (
    <MasonaryContainer style={{ overflowX: 'scroll' }}>
      <Masonry
        data-testid="grid-attachments"
        cellCount={attachments.size}
        cellMeasurerCache={cache}
        cellPositioner={cellPositioner}
        cellRenderer={cellRenderer}
        className="my-4 mx-4 px-3"
        keyMapper={index => attachments.getIn([index, '_id'])}
        height={600}
        width={1030}
      />
    </MasonaryContainer>
  );
}

const MasonaryContainer = styled.div`
  ${scrollbarStyles}
`;

const Thumbnail = styled.img`
  height: 168px;
  width: 180px;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border: 1px solid ${colors.gray100};
`;

const Info = styled.div`
  background-color: ${colors.pearl};
  height: 60px;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  border: 1px solid ${colors.gray100};
  border-top: 0px;
`;

const ItemContainer = styled.div`
  cursor: pointer;

  &:hover .item-info {
    color: ${colors.blue300};
    background-color: ${colors.blue100};
  }
  &:hover .text-muted {
    color: ${colors.blue300} !important;
  }
`;

import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../../Button';
import { Initials, IconBadge } from '../../../Tags';
import { colors } from '../../../theme';

export function EditAccessView({ state, controller }) {
  const filterSet = state.get('toShare');
  const sharedWith = state.get('sharedWith');
  const onClick = () => controller.saveEditAccess();
  const removeSharedWith = item => controller.removeSharedWith(item);
  const isDisabled = sharedWith.every(item => !item.get('toBeRemoved'));

  React.useEffect(() => {
    controller.readFilterSharedWith(filterSet);
  }, [controller, filterSet]);

  return (
    <>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start ">
          <button
            onClick={() => controller.goBack(state.get('backButton'))}
            className="btn text-muted position-relative"
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title className="mt-3">{`Edit access for: ${filterSet.get('name')}`}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={cn('d-flex justify-content-center flex-column mb-4 pb-3')}>
          Stop sharing this Filter Set so users cannot use it anymore. You can share it again later.
        </div>
        <span className="text-gray-400">Shared with</span>
        <div style={{ maxHeight: '14rem', overflow: 'scroll' }}>
          {sharedWith.map(item => (
            <Element key={item.get('_id')} item={item} removeSharedWith={removeSharedWith} />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton disabled={isDisabled} onClick={onClick} className="mr-n2">
          Save
        </PrimaryButton>
      </Modal.Footer>
    </>
  );
}

const Element = ({ item, removeSharedWith }) => {
  const toBeRemoved = item.get('toBeRemoved');
  const isRole = item.get('type') === 'role';
  const isInactiveUser = item.get('status') === 'inactive';

  const ToBeDeletedRow = () => <span className="text-gray-400">Click on “Save” to confirm removal</span>;
  const RegularRow = () => (
    <button onClick={() => removeSharedWith(item)} className="btn btn-link p-0 text-secondary">
      <FontAwesomeIcon icon="trash" />
    </button>
  );

  return (
    <Wrapper className="d-flex flex-row justify-content-between">
      <div
        style={{ height: '3rem' }}
        className={cn('d-flex flex-row align-items-center', {
          'text-gray-200 w-50': toBeRemoved,
          'text-truncate w-100 pr-4': !toBeRemoved,
        })}
      >
        {isRole ? (
          <IconBadge icon="gear" size={40} disabled={toBeRemoved} />
        ) : (
          <Initials name={item.get('initials') || item.get('name')} size={40} disabled={toBeRemoved} />
        )}
        {isInactiveUser && <FontAwesomeIcon icon="ban" className="text-danger muted ml-3" />}
        <span
          className={cn('text-truncate', {
            'w-75': toBeRemoved,
            'text-gray-200 ml-2': isInactiveUser,
            'ml-3 ': !isInactiveUser,
          })}
        >
          {item.get('name')}
        </span>
      </div>
      {toBeRemoved ? <ToBeDeletedRow /> : <RegularRow />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 4rem;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  padding-right: 1rem;
`;

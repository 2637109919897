import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from './theme';

const PasswordToogleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: 10px;

  &:not(:hover) {
    color: ${colors.gray400} !important;
  }

  &:hover {
    color: ${colors.blue400};
    cursor: pointer;
  }
`;

const UnstyledPasswordWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PasswordWrapper = ({ children, onClick, isPlain = true }) => (
  <UnstyledPasswordWrapper>
    {children}
    <PasswordToogleIcon icon={isPlain ? 'eye' : 'eye-slash'} onClick={onClick} />
  </UnstyledPasswordWrapper>
);

import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import * as yup from 'yup';

const EMPTY_ERRORS = {};

const inputValidation = yup
  .number()
  .typeError('#')
  .required()
  .min(1, '1')
  .max(99, '99');

export const useDuration = (updateDuration, value, maxLength = 2) => {
  const [duration, setDuration] = React.useState(value);
  const [errors, setErrors] = React.useState(EMPTY_ERRORS);

  React.useEffect(() => {
    setDuration(value);
  }, [value]);

  const onBlur = () => {
    if (errors.duration) {
      setDuration(value);
      setErrors(EMPTY_ERRORS);
    } else {
      const durationInt = parseInt(duration);
      if (durationInt !== value) {
        updateDuration(durationInt);
      }
    }
  };

  const onChange = ({ target }) => {
    const { value } = target;
    const duration = maxLengthAllowed(value.replace(/[^0-9]/, ''), maxLength);

    setDuration(duration);
    inputValidation
      .validate(value)
      .then(() => setErrors(EMPTY_ERRORS))
      .catch(response => setErrors({ duration: { type: response.type, message: response.message } }));
  };

  const maxLengthAllowed = (value, maxLength) => (value.length < maxLength ? value : value.slice(0, maxLength));

  const Feedback = () => (
    <Form.Control.Feedback type="invalid" className={cn('position-absolute', { 'd-inline': !!errors.duration })}>
      <FontAwesomeIcon icon="circle-exclamation" />
      {errors.duration && errors.duration.type === 'min' && <FontAwesomeIcon icon={faArrowUp} />}
      {errors.duration && errors.duration.type === 'max' && <FontAwesomeIcon icon={faArrowDown} />}
      {errors.duration && ` ${errors.duration.message}`}
    </Form.Control.Feedback>
  );

  return { duration, errors, onBlur, onChange, Feedback };
};

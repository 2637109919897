import React from 'react';
import cn from 'classnames';
import { SystemNotification } from '../SystemNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const reg = /^(.*)((?:\s(?:has\saccepted)\s.*))$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, 'Trade Trax', message];
};

export default ({ notification, controller }) => {
  const [, userName, message] = parseNotification(notification.get('message'));
  const isUserInactive = notification.getIn(['data', 'userStatus']) === 'inactive';
  const userInitials = notification.getIn(['data', 'userInitials']);

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={`/users/profile/${notification.getIn(['data', 'userId'])}`}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
      showInitial={userInitials}
    >
      {isUserInactive && <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />}
      {userName && (
        <span
          className={cn(' text-truncate', {
            'text-gray-200': isUserInactive,
            'text-dark assignee account-assigned d-inline-block': !isUserInactive,
          })}
        >
          {userName}
        </span>
      )}
      {message}
    </SystemNotification>
  );
};

import React from 'react';

// By default the controller API will assume all actions are Async
// if you want to use a regular reducer you need to mark that or those
// as `Synchoronized`
export const markAsSync = (...funcs) => funcs.forEach(fn => (fn.isSync = true));

// if you want to create actions that won't mutate the state, probably beacause
// you know in advance that they will be called while unmounting mark then as SideEffect
// that way they will be called outside the dispatcher (will not trigger a rendering).
export const markAsSideEffect = (...funcs) => funcs.forEach(fn => (fn.isSideEffect = true));

// the `K` constant action won't affect the state, it will bypass the given state.
export const K = state => state;

export function useController(actions, initialState, context = {}, init) {
  const refContext = React.useRef({});
  const reducer = React.useMemo(
    () => (state, [action, resolve, ...params]) => {
      const nextState = action.apply(refContext.current, [state, ...params]);
      resolve(nextState);
      return nextState;
    },
    [refContext]
  );
  const [state, dispatch] = React.useReducer(reducer, initialState, init);

  const controller = React.useMemo(
    () => reduceNamespace(actions, { defer: {}, dispatch: ([fn]) => new Promise(resolve => dispatch([fn, resolve])) }),
    [actions, dispatch]
  );

  function reduceNamespace(actions, initialValue) {
    return Object.keys(actions).reduce((memo, key) => {
      const action = actions[key];

      if (isObject(action)) {
        memo[key] = reduceNamespace(action, { defer: {} });
      }

      if (isFunction(action)) {
        memo[key] = async (...payload) =>
          new Promise((resolve, reject) => {
            try {
              if (action.isSync) {
                dispatch([action, resolve, ...payload]);
              } else if (action.isSideEffect) {
                resolve(action.apply(refContext.current, payload));
              } else {
                action
                  .apply(refContext.current, payload)
                  .then(fn => dispatch([fn, resolve]))
                  .catch(reject);
              }
            } catch (err) {
              reject(err);
            }
          });

        memo.defer[key] = defer(memo[key]);
      }

      return memo;
    }, initialValue);
  }

  refContext.current = { state, controller, ...context };
  return [state, controller, dispatch];
}

function defer(fn) {
  return (...args) =>
    new Promise((resolve, reject) =>
      setTimeout(() => {
        fn(...args)
          .then(resolve)
          .catch(reject);
      }, 1)
    );
}

function isFunction(value) {
  return typeof value === 'function';
}

function isObject(value) {
  const type = typeof value;
  return value != null && type === 'object';
}

import React from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

const AlertsContainer = styled.div`
  position: absolute;
  z-index: 9999;
  right: 0;
  left: 0;
`;

export const Alerts = ({ controller }) => {
  return (
    <AlertsContainer className="mx-4 d-flex flex-column">
      {controller.alerts.map(alert => {
        const render = alert.get('render');
        const close = () => controller.close(alert);
        const key = alert.get('key');
        if (render) return render({ alert, close, key });

        return (
          <Alert
            key={key}
            className="d-flex justify-content-center"
            onClose={close}
            variant={alert.get('variant')}
            dismissible={alert.get('isDismissible')}
          >
            {alert.get('message')}
          </Alert>
        );
      })}
    </AlertsContainer>
  );
};

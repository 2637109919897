import { fromJS } from 'immutable';
import { markAsSync } from '../useController';
import { MAX_CHARS } from './NotesController';

export async function addNote(text, inputData) {
  const usersTagged = inputData.entityMap;
  const totalBlocks = inputData.blocks;
  const entityRangesArray = totalBlocks
    .map(item => {
      return item.entityRanges.map(item => item);
    })
    .flat();

  let totalTextLengthByBlock = 0;
  const offsetsArray = inputData.blocks
    .map(item => {
      const { entityRanges, text } = item;
      const dataOffsets = entityRanges.map(item => {
        return item.offset + totalTextLengthByBlock;
      });
      totalTextLengthByBlock += text.length;
      return dataOffsets;
    })
    .flat();

  const userTaggedArray = Object.keys(usersTagged).map((key, index) => {
    const { type, _id, accountId } = usersTagged[key].data.mention;
    const { length } = entityRangesArray[key];
    const offsetText = offsetsArray[index];

    return {
      type: type,
      startIndex: offsetText,
      endIndex: offsetText + (length - 1),
      _id: type === 'account' ? accountId : _id,
    };
  });

  const { jobId, taskId } = this.info();
  const items = [{ htmlText: text, taggedParties: userTaggedArray }];

  this.controller.dispatch([state => state.set('isUploadNote', true)]);
  return this.notesService
    .createTaskNotes(items, { params: { jobId, taskId } })
    .then(result => state => {
      this.addAlert('Note successfully added to this Task.');
      return state.update('notes', notes => notes.concat(fromJS(result)));
    })
    .catch(err => {
      this.addAlert('There was a problem adding a note to this Task. Please try again.', 'danger');
      throw err;
    })
    .finally(() => {
      this.controller.dispatch([state => state.set('isUploadNote', false)]);
    });
}

export async function readNotes() {
  const { jobId, taskId, user } = this.info();

  return this.notesService
    .getTaskNotes({}, { params: { jobId, taskId } })
    .then(fromJS)
    .then(notes => state => state.set('notes', notes));
}

export async function getUsersToTagInNotes() {
  const { jobId, taskId } = this.info();

  return this.notesService
    .searchRelatedParties({}, { params: { jobId, taskId } })
    .then(data => fromJS(data).toArray())
    .then(users => state => state.set('usersToTag', users));
}

markAsSync(removeNote);
export function removeNote(state, note) {
  const { jobId, taskId } = this.info();
  const noteId = note.get('_id');
  const notes = state.get('notes');
  const index = notes.indexOf(note);

  this.notesService
    .deleteTaskNote({}, { params: { jobId, taskId, noteId } })
    .then(() => {
      this.addAlert('Note successfully removed from this Task.');
    })
    .catch(err => {
      this.controller.dispatch([state => state.set('notes', notes)]);
      this.addAlert('There was a problem removing this note from the Task. Please try again.', 'danger');
      throw err;
    });

  return state.set('notes', notes.splice(index, 1));
}

markAsSync(reset);
export function reset(state) {
  return state.set('isEditing', false).set('charLeft', MAX_CHARS);
}

markAsSync(statusEdit);
export function statusEdit(state, isEditing) {
  return state.set('isEditing', isEditing);
}

markAsSync(changeText);
export function changeText(state, text, inputDataEditor) {
  const textByBlock = inputDataEditor.blocks.map(block => block.text.length);
  const sum = textByBlock.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  return state
    .set('inputData', inputDataEditor)
    .set('textValue', text)
    .set('charLeft', MAX_CHARS - sum);
}

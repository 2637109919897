import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import { SecondaryButton } from '../../Button';
import { colors } from '../../theme';

export const OverlayHeader = ({ onHide, photo, remove, isTask, user, downloadFile, getCanRemoveAndDownload }) => {
  const [show, setShow] = React.useState(false);
  const onRemove = () => {
    setShow(false);
    remove();
  };

  const popover = (
    <Popover id="popover-remove-photo" content={true} style={{ maxWidth: 350, borderRadius: 8 }}>
      <Popover.Content>
        <p>Once removed, attachments cannot be recovered.</p>
        <div className="d-flex flex-row  justify-content-end">
          <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <SecondaryButton variant="outline-primary" onClick={onRemove}>
            Remove
          </SecondaryButton>
        </div>
      </Popover.Content>
    </Popover>
  );

  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.id === 'popover-remove-photo') return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [show, setShow]);

  const { canDownload, canRemove } = getCanRemoveAndDownload(photo);
  const createdBy =
    user.get('accountId') === photo.get('createdByAccountId')
      ? photo.get('createdByUserName')
      : photo.get('createdByAccountName');

  const isSameAccount = photo.get('createdByAccountId') === user.get('accountId');
  const isInactiveUser = photo.get('createdByUserStatus') === 'inactive' && isSameAccount;

  return (
    <div className="d-flex flex-row justify-content-between" data-testid="carousel-header">
      <div className="text-white d-flex flex-column ml-1 w-75 mt-5">
        <div className="h4 text-truncate w-100" data-testid="carousel-photo-name">
          {photo.get('name')}
        </div>
        <span className="font-size-14">
          {`By `}
          {isInactiveUser && <FontAwesomeIcon icon="ban" className="ml-1 mr-1 text-gray-200" />}
          <span className={cn({ 'text-gray-200 mr-1': isInactiveUser })}>{createdBy}</span>
          <span style={{ color: colors.gray200 }}>{`· ${photo.get('title')}`}</span>
        </span>
      </div>
      <div>
        {canRemove && (
          <OverlayTrigger trigger="click" placement="bottom-end" show={show} onToggle={setShow} overlay={popover}>
            <RemoveButton
              className={cn('btn btn-link text-white', { 'mini-modal-open': show })}
              title="Delete attachment"
            >
              <FontAwesomeIcon icon="trash" />
            </RemoveButton>
          </OverlayTrigger>
        )}
        {canDownload && (
          <button className="btn btn-link text-white" onClick={() => downloadFile(photo)}>
            <FontAwesomeIcon icon="arrow-down-to-line" className="mt-2" title="Download attachment" />
          </button>
        )}
        <button className="btn btn-link text-white" onClick={onHide} title="Close Preview">
          <FontAwesomeIcon icon="xmark" />
        </button>
      </div>
    </div>
  );
};

const RemoveButton = styled.button`
  &.mini-modal-open {
    color: ${colors.blue300} !important;
  }
`;

import React from 'react';
import { renderMenu, TrxTypeahead } from './index';

export const AssigneesTypeahead = ({ ...props }) => (
  <TrxTypeahead {...props} renderMenu={customRenderMenu} filterBy={filterBy} />
);

export const filterBy = (option, props) => {
  if (props.selected.length && props.filterSelected) return props.filterSelected(option, props.selected[0]);
  return (
    props
      .labelKey(option)
      .toLowerCase()
      .indexOf(props.text.toLowerCase()) !== -1
  );
};

export const customRenderMenu = props => (results, menuProps) => {
  const { isMyId } = props;
  if (isMyId)
    results.sort((a, b) => {
      if (a.paginationOption) return 1;
      if (b.paginationOption) return -1;
      return isMyId(a) ? -1 : isMyId(b) ? 1 : 0;
    });
  const newMenuProps = { ...menuProps };
  delete newMenuProps.newSelectionPrefix;
  delete newMenuProps.paginationText;
  delete newMenuProps.renderMenuItemChildren;
  return renderMenu(props, newMenuProps, results, menuItemLabel);
};

const menuItemLabel = (option, { isMyId = null, labelKey }) => {
  if (option.paginationOption) return <div className="text-secondary text-center">{option.label || 'See more...'}</div>;
  const isMyself = isMyId && isMyId(option);
  return (
    <div className="d-flex flex-column mw-100">
      <span className="text-truncate d-inline-block w-100">{`${labelKey(option)}`}</span>
      {isMyself && <span className="text-muted font-size-12">(Assign to me)</span>}
    </div>
  );
};

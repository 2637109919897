import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../theme';

export { ToggleButton };

export const ToggleButtons = styled(ToggleButtonGroup)`
  &:not(.sub-section) {
    label.btn,
    a.btn {
      background: ${colors.gray100};
      border: none;
      color: ${colors.gray400};
      min-width: 130px;
      font-weight: 600;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      @media (min-width: 950px) {
        font-size: 16px;
        min-width: 160px;
      }

      &:not(:last-child) {
        margin-right: 3px;
      }

      &:disabled,
      &.disabled {
        background: ${colors.gray100};
      }

      &:hover {
        background: ${colors.activeState};
        color: ${colors.gray800};
      }

      &.active:not(:disabled):not(.disabled),
      &:active:not(:disabled):not(.disabled) {
        color: ${colors.gray800};
        background: ${colors.white};
        border-bottom-style: solid;
        border-width: 3px;
        border-radius: 0px;
        border-color: ${colors.blue300};
      }
    }
  }

  &.sub-section {
    label.btn,
    a.btn {
      color: #6c6e7a;
      font-size: 14px;
      text-transform: capitalize;
      border-radius: 5px !important;
      background-color: transparent;
      border: none;
      padding: 3px 15px 3px 15px;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &:hover {
        text-decoration: underline;
      }
      &.active:not(:disabled):not(.disabled),
      &:active:not(:disabled):not(.disabled) {
        color: ${colors.blue300};
        background: ${colors.blue100};
        box-shadow: none;
      }
    }
  }
  &.bg-white {
    label.btn,
    a.btn {
      background: ${colors.white};

      &:disabled,
      &.disabled {
        background: ${colors.white};

        &:hover {
          color: ${colors.gray400};
        }
      }
    }
  }
`;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButtons, ToggleButton as UnstyledToggleButton } from '../ToggleButtons';
import { colors } from '../theme';

const UnstyledNotificationsPanel = ({ className, children, controller, tab, showMarkAllAsRead }) => {
  useEffect(() => {
    document.getElementById('notifications-panel-list').scrollTop = 0;
  }, [children]);

  return (
    <div id="notifications-panel" className={className}>
      <div
        id="notifications-header"
        className="pt-4 mt-2 mb-4 d-flex flex-row align-items-center justify-content-between align-self-stretch"
      >
        <span className="h5 font-weight-bolder m-0">Notifications</span>
        <button className="close p-0" onClick={controller.toggleNotification}>
          <FontAwesomeIcon icon="xmark" />
        </button>
      </div>
      <ToggleButtons
        className="mb-2 mr-auto"
        type="radio"
        name="options"
        defaultValue="all"
        onChange={controller.toggleTab}
      >
        <ToggleButton className="shadow-none" value="all">
          All
        </ToggleButton>
        <ToggleButton className="shadow-none" value="unread">
          Unread
        </ToggleButton>
        {showMarkAllAsRead && (
          <button key="read-all" id="mark-all" className="btn btn-link ml-5 pl-4" onClick={controller.markAllAsSeen}>
            Mark all as read
          </button>
        )}
      </ToggleButtons>
      <NotificationsList id="notifications-panel-list" className={`tab-${tab}`}>
        {children}
      </NotificationsList>
    </div>
  );
};

const ToggleButton = styled(UnstyledToggleButton)`
  background: transparent !important;
  min-width: 112px !important;
`;

export const NotificationsPanel = styled(UnstyledNotificationsPanel)`
  ${props => `
    transition: right 600ms ease;
    z-index: 1000;
    position: fixed;
    width: 450px;
    height: 90%;
    right: 0px;
    top: 0px;
    bottom: 0px;
    align-items: start;
    background: ${colors.pearl};
    mix-blend-mode: normal;
    box-shadow: 1px 4px 10px rgba(82, 87, 92, 0.5);
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    #empty-state {
      margin-top: 10rem !important;
    }

    #notifications-header {
      position: relative;

      &::before {
        background-color: ${colors.blue300};
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        height: 10px;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        content: '';
      }
    }

    > :first-child {
      padding-left: 2.25rem;
      padding-right: 1.8rem;
    }

    &.hide {
      right: -452px;
    }
  `}
`;

const NotificationsList = styled.ul`
  overflow-y: visible;
  overflow-x: hidden;
  list-style-type: none;
  padding: 0 0;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  position: relative;

  &.tab-unread {
    li.read {
      opacity: 0;
    }
  }

  .btn-remove:hover ~ li:not(.empty) {
    background-color: ${colors.blue100};
  }

  li {
    transition: opacity 0.6s linear;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    letter-spacing: 0.2px;
    position: relative;
    padding-left: 2.25rem;
    padding-right: 2.85rem;

    i {
      width: 8px;
      height: 7px;
      background: ${colors.gray500};
      border-radius: 50%;
      position: absolute;
      top: 1.8rem;
      right: 1.8rem;

      &.red-dot {
        background: ${colors.red};
      }
    }

    svg {
      margin-right: 10px;
    }

    .trx-time {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.gray400};
      padding-bottom: 0.5rem;
      margin-left: 2.6rem;
      align-self: flex-start;
    }

    &:hover:not(.empty) {
      background-color: ${colors.blue100};
    }

    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray100};
    }
  }
`;

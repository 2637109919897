import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';

const BORDER_FACTOR = 0.0625;
const FONT_FACTOR = 2.5;

const UnstyledIconBadge = ({ className, innerRef, ...rest }) => (
  <span className={className} {...rest} ref={innerRef}>
    <FontAwesomeIcon icon="gear" />
  </span>
);

export const IconBadge = styled(UnstyledIconBadge)`
  ${props => `
    min-width: ${props.size}px;
    width: ${props.size}px;
    height: ${props.size}px;
    min-height: ${props.size}px;
    border: solid ${props.size * BORDER_FACTOR}px ${colors.white};
    font-size: ${props.size / FONT_FACTOR}px;
    color: ${props.disabled ? colors.gray200 : colors.gray400};
    background: ${props.disabled ? colors.blue100 : colors.blue_30};
  `}
  border-radius: 50%;
  font-weight: bold;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin-right: 0.625rem;
`;

import React from 'react';
import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '../useController';
import { PreviewModal } from './PreviewModal/PreviewModal';
import { CustomDialog } from '../Dialog/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ATTACHMENTS_VIEW, ATTACHMENTS_JOB_VIEW } from './AlbumController';

const ISO = 'YYYY-MM-DD';

const mapDate = user => {
  const thisYear = moment().year();
  const accountId = user.get('accountId');

  return file => {
    const createdAt = moment(file.get('createdAt'));
    const format = createdAt.year() === thisYear ? 'MMM D' : 'MMM D, YYYY';
    const createdByAccountId = file.get('createdByAccountId');
    const createdBy =
      accountId === createdByAccountId ? file.get('createdByUserName') : file.get('createdByAccountName');
    const date = createdAt.format(ISO);
    const title = createdAt.format(format);

    return file.merge({ date, title, createdBy });
  };
};

const dateLambda = (a, b) => moment(a.get('createdAt')).diff(moment(b.get('createdAt')));

markAsSync(toggleView);
export function toggleView(state, typeView) {
  const newView = state.get('view') === 'list' ? 'grid' : 'list';
  localStorage.setItem(typeView === 'attachments' ? ATTACHMENTS_VIEW : ATTACHMENTS_JOB_VIEW, newView);
  return state.set('view', newView);
}

markAsSideEffect(setActiveFilter);
export function setActiveFilter(option) {
  this.controller.dispatch([state => state.set('activeFilter', option)]);
  if (option === 'all') readTaskAndJobAttachments.call(this);
  if (option === 'on-task') this.controller.readTaskAttachments();
  if (option === 'on-job') this.controller.readJobAttachments(this.task.getIn(['job', 'id']));
}

markAsSideEffect(addAttachment);
export async function addAttachment(showCaption = false) {
  const title = this.task?.get('name') || this.job?.get('name');

  const { isAccept, attachment: file } = await this.modal.open(PreviewModal, { title, showCaption });

  if (!isAccept) return;

  if (this.task) addTaskAttachment.call(this, file);
  if (this.job) addJobAttachment.call(this, file);
}

function addTaskAttachment(file) {
  const { jobId, taskId, user } = this.info();
  const mapper = mapDate(user);
  const fileId = file._id;

  this.service
    .addTaskFile({ fileId }, { params: { jobId, taskId } })
    .then(attachment => {
      if (this.state.get('activeFilter') !== 'on-job')
        this.controller.dispatch([
          state =>
            state.update('attachments', attachments =>
              attachments
                .toList()
                .unshift(mapper(fromJS(attachment)))
                .sort(dateLambda)
                .reverse()
            ),
        ]);
    })
    .catch(ex => {
      this.addAlert(`There was an error. The attachment was not added to the task`, 'danger');
      throw ex;
    });
}

function addJobAttachment(file) {
  const user = this.appState.get('user');
  const mapper = mapDate(user);
  const fileId = file._id;
  const jobId = this.job.get('_id');

  this.service
    .addJobfile({ fileId }, { params: { jobId } })
    .then(attachment => {
      this.controller.dispatch([
        state =>
          state.update('attachments', attachments =>
            attachments
              .toList()
              .unshift(mapper(fromJS(attachment)))
              .sort(dateLambda)
              .reverse()
          ),
      ]);
    })
    .catch(ex => {
      this.addAlert(`There was an error. The attachment was not added to the job`, 'danger');
      throw ex;
    });
}

markAsSideEffect(readTaskAndJobAttachments);
export async function readTaskAndJobAttachments() {
  const { jobId, taskId, user } = this.info();
  return this.service
    .readTaskFiles({}, { params: { jobId, taskId }, query: { includeJobFiles: true } })
    .then(fromJS)
    .then(atts =>
      atts
        .map(mapDate(user))
        .sort(dateLambda)
        .reverse()
    )
    .then(atts => {
      this.controller.dispatch([state => state.set('attachments', atts)]);
    });
}

markAsSideEffect(readAttachments);
export function readAttachments() {
  if (this.task) this.controller.readTaskAttachments();
  if (this.job) this.controller.readJobAttachments(this.job.get('_id'));
}

export async function readTaskAttachments() {
  const { jobId, taskId, user } = this.info();

  return this.service
    .readTaskFiles({}, { params: { jobId, taskId } })
    .then(fromJS)
    .then(attachments =>
      attachments
        .map(mapDate(user))
        .sort(dateLambda)
        .reverse()
    )
    .then(attachments => state => state.set('attachments', attachments));
}

export function readJobAttachments(jobId) {
  const user = this.appState.get('user');

  return this.service
    .getJobfiles({}, { params: { jobId } })
    .then(fromJS)
    .then(attachments =>
      attachments
        .map(mapDate(user))
        .sort(dateLambda)
        .reverse()
    )
    .then(attachments => state => state.set('attachments', attachments));
}

markAsSideEffect(removeAttachment);
export async function removeAttachment(attachment, isConfirmed = true) {
  const isOnTask = this.task && attachment.get('taskId');
  if (!isConfirmed) {
    const { isAccept } = await this.modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger font-size-18" />
          Remove Attachment
        </>
      ),
      message: 'Once removed, attachments cannot be recovered.',
      titleAccept: 'Yes, Remove Attachment',
      titleCancel: 'Cancel',
    });

    if (!isAccept) return;
  }
  if (isOnTask) removeTaskAttachment.call(this, attachment);
  else removeJobAttachment.call(this, attachment);
}

function removeTaskAttachment(attachment) {
  const { jobId, taskId } = this.info();
  const fileId = attachment.get('_id');
  const attachments = this.state.get('attachments');
  const index = attachments.indexOf(attachment);
  const isCarouselOpen = this.state.get('isCarouselOpen') && attachments.size !== 1;

  this.controller.dispatch([
    state => state.set('attachments', attachments.splice(index, 1)).set('isCarouselOpen', isCarouselOpen),
  ]);

  this.service.deleteTaskFile({}, { params: { jobId, taskId, fileId } }).then(() => {
    this.addAlert('Attachment successfully removed from this Task.', 'success');
  });
}

function removeJobAttachment(photo) {
  const fileId = photo.get('_id');
  const jobId = this.job?.get('_id') || this.task.getIn(['job', 'id']);
  const attachments = this.state.get('attachments');
  const index = attachments.indexOf(photo);
  const isCarouselOpen = this.state.get('isCarouselOpen') && attachments.size !== 1;

  this.controller.dispatch([
    state => state.set('attachments', attachments.splice(index, 1)).set('isCarouselOpen', isCarouselOpen),
  ]);

  this.service
    .deleteJobfile({}, { params: { jobId, fileId } })
    .then(() => this.addAlert('Attachment successfully removed from this Job.', 'success'))
    .catch(() => {
      this.controller.dispatch([state => state.set('attachments', attachments)]);
      this.addAlert('There was a problem removing the attachment from this Job. Please try again.', 'danger');
    });
}

markAsSideEffect(downloadAttachment);
export function downloadAttachment(attachment) {
  // hotfix add an extra query param to prevent browser using the cached resource.
  // https://stackoverflow.com/questions/20253472/cors-problems-with-amazon-s3-on-the-latest-chomium-and-google-canary/50840500#50840500
  const url = `${attachment.get('url')}&cacheblock=true`;

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.style = 'display: none';
      a.download = attachment.get('name');
      document.body.appendChild(a);
      a.click();
    })
    .catch(error => {
      this.addAlert('There was a problem downloading this attachment. Please try again.', 'danger');
    });
}

markAsSideEffect(handleAction);
export function handleAction(action, attachment) {
  const isConfirmed = false;
  if (action === 'download') downloadAttachment.call(this, attachment, isConfirmed);
  if (action === 'remove') removeAttachment.call(this, attachment, isConfirmed);
}

export async function showCarousel(currentPhoto) {
  return state => {
    const attachments = state.get('attachments');
    const currentPhotoIndex = attachments.indexOf(currentPhoto) || 0;
    return state.merge({ currentPhotoIndex, isCarouselOpen: true });
  };
}

export async function hideCarousel() {
  return state => state.merge({ currentPhotoIndex: -1, isCarouselOpen: false });
}

markAsSync(openDocument);
export function openDocument(state, document) {
  return state.set('previewDocument', document);
}

markAsSync(closeDocument);
export function closeDocument(state) {
  return state.set('previewDocument', null);
}

markAsSync(removeDocument);
export function removeDocument(state, document) {
  const { jobId, taskId } = this.info();
  const fileId = document.get('_id');
  const documents = state.get('documents');
  const index = documents.indexOf(document);

  this.service
    .deleteTaskFile({}, { params: { jobId, taskId, fileId } })
    .then(() => {
      this.addAlert('Document successfully removed from this Task.', 'success');
    })
    .catch(() => {
      this.controller.dispatch([state => state.set('documents', documents)]);
      this.addAlert('There was a problem removing the document from this Task. Please try again.', 'danger');
    });

  return state.set('documents', documents.splice(index, 1)).set('previewDocument', null);
}

markAsSync(loadAttachmentsView);
export function loadAttachmentsView(state) {
  const defaultView = localStorage.getItem(ATTACHMENTS_VIEW);
  return state.set('view', defaultView || 'grid');
}

import React from 'react';
import cn from 'classnames';
import PDFObject from 'pdfobject';
import styled from 'styled-components';
import { Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../../Button';
import { colors } from '../../theme';
import { PreviewModalContext } from './PreviewModalContext';

const MB20 = 20 * 1000000;

export const PreviewModal = ({ cancel, accept, title, showCaption }) => {
  const [isFileSelected, setIsFileSelected] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { controller, state } = PreviewModalContext({ cancel, accept });
  const { isUploading, lastEventType, attachment } = state.toObject();
  const fileRef = React.useRef();
  const imgRef = React.useRef();
  const docRef = React.useRef();
  const [currentFile, setCurrentFile] = React.useState();

  const selectImage = e => {
    e.preventDefault();
    if (!isUploading) fileRef.current.click();
  };

  const onAddClick = e => {
    e.preventDefault();
    controller.uploadPhoto(currentFile);
  };

  const onHide = () => {
    const xhr = state.get('xhr');
    if (xhr && xhr.readyState !== XMLHttpRequest.DONE && xhr.readyState !== XMLHttpRequest.UNSENT) {
      xhr.abort();
    }
    cancel();
  };

  React.useEffect(() => {
    if (attachment && lastEventType === 'loadend') {
      setTimeout(() => {
        accept({ attachment });
      }, 500);
    }
  }, [accept, attachment, lastEventType]);

  React.useEffect(() => {
    const img = imgRef.current;
    const doc = docRef.current;
    const file = fileRef.current;

    const onChange = () => {
      const [image] = file.files;
      if (!image) return;

      const isDocument = image.type === 'application/pdf';
      if (image.size > MB20) {
        if (!isDocument) img.src = '';
        setIsFileSelected(false);
        setError('Please select an attachment smaller than 20MB');
        return;
      }

      setCurrentFile(image);
      if (isDocument) {
        const options = {
          width: '680px',
          height: '480px',
          pdfOpenParams: { view: 'FitW' },
        };
        PDFObject.embed(URL.createObjectURL(image), doc, options);
        setIsFileSelected(true);
      } else {
        img.src = URL.createObjectURL(image);
      }

      setError(null);
    };

    const onLoad = () => {
      URL.revokeObjectURL(img.src);
      setIsFileSelected(true);
    };

    file.addEventListener('change', onChange);
    img.addEventListener('load', onLoad);

    file.click();

    return () => {
      file.removeEventListener('change', onChange);
      img.removeEventListener('load', onLoad);
    };
  }, [fileRef, imgRef, docRef, setIsFileSelected]);
  const fileType = fileRef.current?.files[0]?.type;
  const isDocument = fileType === 'application/pdf';
  const isPhoto = fileType?.includes('image/');

  return (
    <Modal show={true} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Add Attachment{<br />}
          <h6 className="font-weight-bold">{title}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={cn('d-flex justify-content-center flex-column')}>
          <input type="file" ref={fileRef} className="d-none" accept="image/png,image/jpeg,application/pdf" />
          <DocPreview
            src="#"
            className={cn('mx-auto', { 'd-none': !isDocument })}
            ref={docRef}
            onClick={() => fileRef.current.click()}
          />
          <ImagePreview
            src="#"
            className={cn('mx-auto', { 'd-none': !isPhoto })}
            ref={imgRef}
            onClick={() => fileRef.current.click()}
          />
          <PlaceHolder className={cn({ 'd-none': isFileSelected })}>
            <button onClick={() => fileRef.current.click()} className="btn btn-link text-center text-gray-400">
              Select Attachment from Browser
            </button>
          </PlaceHolder>
          <div className={cn('text-danger mt-2', { 'd-none': !error })}>
            <FontAwesomeIcon icon="circle-exclamation" /> {error}
          </div>
        </div>
        {showCaption && <div>This Attachment will be uploaded to this Task only.</div>}
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton disabled={isUploading} variant="secondary" onClick={selectImage}>
          {isFileSelected ? 'Change Attachment' : 'Select Attachment'}
        </PrimaryButton>
        <PrimaryButton disabled={isUploading || !isFileSelected} onClick={onAddClick} className="mr-n2">
          {isUploading ? (
            <>
              <Spinner animation="grow" variant="secondary" size="sm" />
              <span className="ml-2">Uploading...</span>
            </>
          ) : (
            'Add Attachment'
          )}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const ImagePreview = styled.img`
  width: 100%;
  max-width: 680px;
  max-height: 480px;
  cursor: pointer;
`;

const DocPreview = styled.div`
  width: 100%;
  max-width: 680px;
  max-height: 480px;
  overflow-x: scroll;
  cursor: pointer;
`;

const PlaceHolder = styled.div`
  min-height: 300px;
  background-color: ${colors.activeState};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

import React from 'react';

export const CircleLogo = ({ size = 38 }) => (
  <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="19" cy="18" r="16" fill="#333333" />
      <circle cx="19" cy="18" r="16" stroke="white" strokeWidth="2" />
    </g>
    <path
      d="M14.7196 15.84L24.8764 9H12.8416C11.272 9 10 10.272 10 11.8416V20.754H14.734L14.7196 15.84Z"
      fill="white"
    />
    <path d="M10 21.678V24.1572C10 25.728 11.272 27 12.8416 27H22.4332V21.678H10Z" fill="#FC5C4E" />
    <path
      d="M27.9999 24.1584V11.8416C27.9999 10.638 27.2499 9.61078 26.1927 9.19678L19.9143 13.476L23.3595 15.7932V27H25.1571C26.7279 27 27.9999 25.728 27.9999 24.1584Z"
      fill="#07D99D"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

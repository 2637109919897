import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SystemNotification } from '../SystemNotification';
const builderLinkReg = /^(.*)(?:(?:jobs\/))(.*)(?:(?:\/task\/))(.*)$/;
const tradeLinkReg = /^(.*)(?:(?:job\/))(.*)(?:(?:\/))(.*)$/;

const parseLink = link => {
  const linkReg = link.includes('//builder.') ? builderLinkReg : tradeLinkReg;
  const result = (link || '').match(linkReg);
  return result || [null, null, '', ''];
};

const reg = /^(.*)((?:\s(?:tagged\syou)\s.*))$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, 'Trade Trax', message];
};
const isInactive = user => user && typeof user.get === 'function' && user.get('status') === 'inactive';

export default ({ notification, controller, taskUrl }) => {
  const [, , jobId, taskId] = parseLink(notification.getIn(['data', 'link']));
  const [, userName, message] = parseNotification(notification.get('message'));
  const isUserInactive = isInactive(notification.getIn(['data', 'taggedBy']));
  const { taggingEntity, taggingUserName } = notification.get('data').toObject();
  const userInitials = notification.getIn(['data', 'taggedBy', 'initials']);

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={taskUrl({
        jobId,
        taskId,
      })}
      linkHash="#notes"
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
      showInitial={userInitials || taggingEntity || taggingUserName}
    >
      <div className="d-inline-block">
        {isUserInactive && <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />}
        <span
          className={cn('', {
            'text-gray-200': isUserInactive,
            'text-dark assignee account-assigned d-inline-block text-truncate': !isUserInactive,
          })}
        >
          {userName}
        </span>
      </div>
      {message}
    </SystemNotification>
  );
};

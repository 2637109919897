import React from 'react';
import { GeoLocationModal } from './GeoLocationModal';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// where modal is the API to open popups. `modal.open(...)`
export const GeoLocationButton = ({ modal, geoLocation = null, className = '', ...props }) => {
  if (geoLocation === null) return null;
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (modal) {
      modal.open(GeoLocationModal, { geoLocation, ...props });
    }
  };

  return (
    <button className={cn(className, 'btn btn-link font-size-14 py-0')} onClick={onClick}>
      <FontAwesomeIcon icon="location-dot" /> See Location
    </button>
  );
};

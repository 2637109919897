import React from 'react';
import { fromJS } from 'immutable';

export const useTaskTypes = userService => {
  const [taskTypes, setTaskTypes] = React.useState(fromJS([]));

  React.useEffect(() => {
    userService
      .readTasksTypes({}, { query: { open: 'true' } })
      .then(fromJS)
      .then(setTaskTypes);
  }, []);

  return taskTypes;
};

import React from 'react';
import cn from 'classnames';
import { plural } from './utils';

export const OnTime = ({ className, onTime, hideOnSchedule = false }) => (
  <small
    className={cn(className, 'text-nowrap', {
      'text-danger': onTime > 0,
      'text-success': onTime < 0,
    })}
  >
    {onTime > 0 && `${plural.day(Math.abs(onTime))} behind`}
    {onTime === 0 && !hideOnSchedule ? `On Schedule` : null}
    {onTime < 0 && `${plural.day(Math.abs(onTime))} ahead`}
  </small>
);

export const DaysBehind = ({ daysBehind = 0, ...props }) => <OnTime onTime={daysBehind} {...props} />;

export const StatusDataOnTime = ({ className, data }) => {
  if (!data) return null;
  const numDays = data.get('daysDifference');
  const targetStatus = data.get('targetStatus');
  let onTime;
  switch (targetStatus) {
    case 'on-schedule':
      onTime = 0;
      break;
    case 'ahead':
      onTime = -numDays;
      break;
    case 'behind':
      onTime = numDays;
      break;
    default:
      return null;
  }

  return <OnTime onTime={onTime} className={className} />;
  // let isOnTime = targetStatus === 'on-schedule';
  // let isAhead = targetStatus === 'ahead';
  // let isBehind = targetStatus === 'behind';

  // return (
  //   <small
  //     className={cn(className, {
  //       'text-danger': isBehind,
  //       'text-success': isAhead,
  //     })}
  //   >
  //     {isBehind && `${numDays} days behind`}
  //     {isOnTime === 0 && `On Schedule`}
  //     {isAhead > 0 && `${numDays} days ahead`}
  //   </small>
  // );
};

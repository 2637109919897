import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CircleLogo } from '../Logo';
import { useFade } from './useFade';
import { useTimeAgo } from '../useTimeAgo';
import { mongoToHistory } from '../utils';
import { Initials } from '../Tags';

export const SystemNotification = ({
  children,
  linkTo,
  time,
  redDot,
  markAsSeen,
  linkState,
  linkHash,
  showInitial,
}) => {
  const [className, onClick] = useFade(markAsSeen);
  const to = { pathname: linkTo };
  if (linkState) to.state = linkState;
  if (linkHash) to.hash = linkHash;

  return (
    <li className={className}>
      <i className={cn({ 'red-dot': redDot })} />
      <div className="d-flex flex-row">
        <LogoWrapper>{showInitial ? <Initials name={showInitial} size={32} /> : <CircleLogo size={32} />}</LogoWrapper>
        <p className="mt-1 mb-2">
          <Link
            onClick={onClick}
            to={to}
            className="stretched-link link-name text-secondary text-decoration-none notification-redirect"
          >
            {children}
          </Link>
        </p>
      </div>
      <span className="trx-time">{useTimeAgo(time, mongoToHistory)}</span>
    </li>
  );
};

// Not sure why SVG was not respecting width/height
// I had to wrap it on the span.
const LogoWrapper = styled.span`
  width: 32px;
  height: 32px;
  margin-right: 0.625rem;
`;

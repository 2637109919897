import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SystemNotification } from '../SystemNotification';

const parseNotification = data => {
  return [data.get('userName'), data.get('filterName')];
};

const isInactive = user => user && user.get('status') === 'inactive';

export default ({ notification, controller, dashboardUrl, jobsUrl }) => {
  const [userName, filterName] = parseNotification(notification.get('data'));
  const isUserInactive = isInactive(notification.getIn(['data', 'sharedBy']));
  const isSharedWithRole = !!notification.getIn(['data', 'sharedByRole']);
  const isJobFilterSet = notification.getIn(['data', 'filterType']) === 'jobSchedule';
  return (
    <SystemNotification
      key={notification.get('_id')}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
      linkTo={isJobFilterSet ? jobsUrl() : dashboardUrl()}
    >
      <div className="d-inline-block">
        {isUserInactive && <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />}
        <span
          className={cn({
            'text-gray-200': isUserInactive,
            'text-dark assignee account-assigned d-inline-block text-truncate': !isUserInactive,
          })}
        >
          {userName}
        </span>
      </div>
      <div className="d-inline">
        {` shared with you ${isSharedWithRole ? 'via Custom Role ' : ''} the `}
        <span className="text-dark  text-truncate">{filterName}</span>
        <span className="text-secondary">{` Filter Set for ${isJobFilterSet ? 'jobs' : 'tasks lists'}`}</span>
      </div>
    </SystemNotification>
  );
};

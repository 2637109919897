import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MiniAlertsContext } from './MiniAlertsContext';

export const MiniAlerts = React.forwardRef((props, ref) => {
  const { state, controller } = MiniAlertsContext();
  ref.current = controller;

  const miniAlert = React.useMemo(() => {
    const div = document.createElement('div');
    global.document.body.appendChild(div);
    div.id = 'mini-alert';
    return div;
  }, []);

  React.useEffect(() => {
    return () => {
      global.document.body.removeChild(miniAlert);
    };
  }, [miniAlert]);

  return ReactDOM.createPortal(<Container items={state} close={controller.close} />, miniAlert);
});

const Container = ({ items, close }) => {
  return (
    <>
      {items.map(item => (
        <MiniAlert item={item} key={item.get('key')} close={close} />
      ))}
    </>
  );
};

const ICONS = {
  success: 'circle-check',
  warning: 'triangle-exclamation',
  danger: 'circle-exclamation',
};

const MiniAlert = ({ item, close }) => {
  const variant = item.get('variant');
  const isClosed = !!item.get('isClosed');
  const isDismissible = item.get('isDismissible');
  const content = item.get('content');
  const message = item.get('message');
  const size = `size-${item.get('size')}`;
  const icon = ICONS[variant];

  return (
    <div className={cn('mini-alert mt-2', size, { 'is-open': !isClosed })}>
      {icon && <FontAwesomeIcon icon={icon} className={`text-${variant}`} />}
      {content ? content : <p>{message}</p>}
      {isDismissible && (
        <button className="close" onClick={() => close(item)}>
          <FontAwesomeIcon icon="xmark" className="text-secondary" />
        </button>
      )}
      <span className={`bevel bg-${variant}`} />
    </div>
  );
};

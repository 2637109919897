import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { SideBarLink } from './SideBarLink';
// import { UR_OVERDUE_FILTER, TO_DO_FILTER } from '../Task/TasksFilter';
import cn from 'classnames';
import { FILTER_NAME, useFiltersContext } from '../Filters/Filters.context';

const SideBarLinkWithOverlay = React.forwardRef((props, innerRef) => <SideBarLink innerRef={innerRef} {...props} />);

export const SideBarLinkOverlay = ({ counters, ...props }) => {
  const { requestCount, overdueCount } = counters?.toObject();
  const counterPath = props.isTrade ? 'startDateConfirmationRequestCount' : 'toDoUpdateCount';
  const toDoCounter =
    counters?.get('toDoEndCount') + counters?.get('toDoStartCount') + (counters?.get(counterPath) || 0);

  const { filtersState } = useFiltersContext();
  const isTodoFiltering = filtersState.getIn([FILTER_NAME.TO_DO, 'isFiltering']);
  const isUROverdueFiltering = filtersState.getIn([FILTER_NAME.UR_OVERDUE, 'isFiltering']);
  const isFiltered = isTodoFiltering || isUROverdueFiltering;
  const title = isFiltered ? 'Tasks are filtered.' : 'No Tasks filtered.';

  const popover = (
    <Popover id="popover-counters" className={cn({ 'd-none': !isFiltered })} content={true} style={{ borderRadius: 8 }}>
      <Popover.Content>
        <div className="d-flex flex-column">
          <strong className="mb-2">{title}</strong>
          <span className={cn({ 'd-none': !requestCount })}>
            <strong className="mr-2">{requestCount}</strong>Update Requests
          </span>
          <span className={cn({ 'd-none': !overdueCount })}>
            <strong className="mr-2">{overdueCount}</strong>Overdue Tasks
          </span>
          <span className={cn({ 'd-none': !toDoCounter })}>
            <strong className="mr-2">{toDoCounter}</strong>
            {`To-Dos`}
          </span>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom-end" overlay={popover}>
      {({ ref, ...triggerHandler }) => (
        <SideBarLinkWithOverlay badge={counters} ref={ref} {...props} {...triggerHandler}>
          Dashboard
        </SideBarLinkWithOverlay>
      )}
    </OverlayTrigger>
  );
};

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

const getTypeUserClass = (taggedData, user) => {
  if (!taggedData) return '';
  const userAccountId = user.get('accountId');
  const userId = user.get('_id');
  const { type, active, _id, accountId } = taggedData;

  if (_id === userId) return 'taggedMyself';
  if (type === 'account') return accountId === userAccountId ? 'taggedMyself' : 'otherAccount';
  if (accountId === userAccountId) {
    return active ? 'userSameAccount' : 'userSameAccountDeactivated';
  } else return active ? 'otherAccount' : 'otherAccountDeactivated';
};

const filterElementInComment = item => {
  const { nodeName, className, attributes } = item;

  if (attributes.length > 1) return item;
  if (nodeName === 'BR') return false;
  if (nodeName === 'DIV' && className === 'textWithTag') return false;
  if (nodeName === 'SPAN' && className === 'taggedNote') return false;

  return item;
};

function replaceMultipleStringPortions(string, taggedParties) {
  let modifiedString = string;

  taggedParties.map((data, index) => {
    const { startIndex, endIndex } = data;
    const length = endIndex - startIndex;
    const extraCharactersBySpan = index * 33;
    const newOffset = startIndex - index + extraCharactersBySpan;
    const tag = string.substr(startIndex, length);
    const newString = tag;
    modifiedString =
      modifiedString.substring(0, newOffset) +
      `<span class='taggedNote'>${newString}</span>` +
      modifiedString.substring(newOffset + length);
  });

  return modifiedString;
}

export const CommentWithTags = ({ className, text, user, taggedParties }) => {
  const hasHTMLTags = /<\/?[a-z][\s\S]*>/i.test(text);
  var formattedText = hasHTMLTags ? text : '';
  if (!hasHTMLTags) {
    formattedText = `<div class='textWithTag'> ${replaceMultipleStringPortions(text, taggedParties)} <br> </div>`;
  }

  const ref = useRef(null);

  const comment = formattedText;
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = comment;
  const allHTMLelementsInComment = htmlObject.getElementsByTagName('*');
  const allHTMLelementsInCommentArray = [...allHTMLelementsInComment];
  const hasUnknownElements = allHTMLelementsInCommentArray.filter(item => filterElementInComment(item));

  if (hasUnknownElements.length > 0 && hasHTMLTags) {
    return (
      <CommentContainer className={className} ref={ref}>
        {formattedText}
      </CommentContainer>
    );
  }

  useEffect(() => {
    const elm = ref.current;
    const taggedElements = elm.querySelectorAll('.taggedNote');
    if (taggedElements.length > 0) {
      taggedElements.forEach((item, index) => {
        const classToTag = getTypeUserClass(taggedParties[index], user);
        item.className += ` ${classToTag}`;
        const tagName = item.textContent;
        const tagNameSubtring = `${tagName.substring(0, 60)}${tagName.length > 60 ? '...' : ''}`;
        item.textContent = tagNameSubtring;
      });
    }
  }, [ref]);

  return <CommentContainer className={className} dangerouslySetInnerHTML={{ __html: formattedText }} ref={ref} />;
};

export const CommentContainer = styled.p`
  overflow-wrap: break-word;
  word-break: break-word;

  &.show-from-dashboard {
    margin-left: 3rem;
    border-radius: 8px;
    border: 1px solid ${colors.gray};
    background: ${colors.pearl};
    padding-left: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .taggedNote {
    border-radius: 13px;
    padding: 4px 8px;
    font-size: 12px;
    margin-bottom: 3px;
    display: inline-block;
  }

  .taggedMyself {
    background-color: ${colors.blue400};
    color: ${colors.white};
    font-weight: bold;
  }

  .otherAccount {
    background-color: ${colors.white};
    border: 1px solid ${colors.gray50};
    color: ${colors.gray800};
  }

  .otherAccountDeactivated {
    border: 1px solid ${colors.gray};
    color: ${colors.gray200};
  }

  .userSameAccount {
    background-color: ${colors.blue100};
    color: ${colors.gray800};
  }

  .userSameAccountDeactivated {
    background-color: ${colors.pearl};
    color: ${colors.gray200};
  }
`;

import React from 'react';
import { SystemNotification } from '../SystemNotification';

export default ({ notification, controller }) => (
  <SystemNotification
    key={notification.get('_id')}
    linkTo="/"
    time={notification.get('createdAt')}
    redDot={!notification.get('read')}
    markAsSeen={() => controller.markAsSeen(notification)}
  >
    Echo notification
  </SystemNotification>
);

import React from 'react';

export const useFade = markAsSeen => {
  const [className, setClassName] = React.useState('');
  const onClick = () => {
    setClassName('read');
    setTimeout(() => {
      markAsSeen();
    }, 500);
  };
  return [className, onClick];
};
